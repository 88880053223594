import { useCallback, useEffect, useState } from "react";
import { Program } from "../../model/types";
import { useCreateClientProgramFromTemplateMutation } from "../../model/slices/programsSlice";

export const useCreateProgramFromTemplate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean | undefined>(undefined);
  const [createClientProgramFromTemplateMutation, { error }] = useCreateClientProgramFromTemplateMutation();
  const [errorString, setErrorString] = useState<string>();

  const createProgramFromTemplate = useCallback(
    async ({clientId, templateId}: {clientId: string, templateId: string}): Promise<Program | undefined> => {
      setIsSuccess(undefined);
      setIsLoading(true);
      const result = await createClientProgramFromTemplateMutation({clientId, templateId});
      setIsLoading(false);
      if ('data' in result) {
        setIsSuccess(true);
        return result.data;
      }
      else {
        setIsSuccess(false);
        return undefined;
      }
    },
    [createClientProgramFromTemplateMutation]
  );

  useEffect(
    () => {
      if (error) {
        console.error(error);
        if (typeof error === 'string') {
          setErrorString(error as string);
        }
        else {
          setErrorString("An error occurred");
        }
      }
      else {
        setErrorString(undefined);
      }
    },
    [error]
  );

  return {
    createProgramFromTemplate,
    isLoading,
    isSuccess,
    error: errorString,
  };
}