import ValidateTextField from "../../../../../../components/ValidateTextField"
import { isNumber, maximumNumberValue, minimumNumberValue } from "./fieldValidators"
import InputAdornment from "@mui/material/InputAdornment"

type Props = {
  percentage: number | null | undefined,
  onPercentageChange: (percentage: number | null) => void,
}

const PercentageField = (props: Props) => {
  return (
    <ValidateTextField
      defaultValue={props.percentage === null ? "" : props.percentage}
      type="number"
      label="Percentage"
      size="small"
      validators={[
        isNumber, minimumNumberValue(0), maximumNumberValue(1000),
      ]}
      onValidatedChange={(value) => props.onPercentageChange(value !== "" ? Number(value) : null)}
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }}
    />
  )
}

export default PercentageField