import { useCallback, useState } from "react";
import { auth } from "../../config/firebase";
import { useCurrentUser } from "../users/currentUserHook";
import { useRemoveClientFromCoachMutation } from "../../model/slices/clientsSlice";

export const useRemoveClientFromCoach = () => {
  const {userData} = useCurrentUser(auth);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean | undefined>(undefined);
  const [removeClientFromCoachMutation, { error }] = useRemoveClientFromCoachMutation();

  const removeClientFromCoach = useCallback(
    async (clientId: string): Promise<boolean> => {
      if (!userData) {
        return false;
      }
      setIsSuccess(undefined);
      setIsLoading(true);
      const result = await removeClientFromCoachMutation({user: userData, clientId});
      setIsLoading(false);
      setIsSuccess("data" in result);
      return "data" in result;
    },
    [userData, removeClientFromCoachMutation]
  );

  return {
    removeClientFromCoach,
    isLoading,
    isSuccess,
    error
  };
}