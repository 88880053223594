import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { ExerciseSet, ExerciseWithSets, Program, Workout, WorkoutMetadata } from "../types";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { auth, db, functions } from "../../config/firebase";
import { GetClientProgramsArg, getClientPrograms, programToRawData, workoutToRawData } from "../services/programService";
import { httpsCallable } from "firebase/functions";

export const programsApi = createApi({
  reducerPath: 'programs',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['programs', 'program', 'workout', 'workouts'],
  endpoints: (builder) => ({
    getClientPrograms: builder.query<Program[], GetClientProgramsArg>({
      async queryFn(arg) {
        return await getClientPrograms(arg);
      },
      providesTags: ['programs'],
    }),
    getProgram: builder.query<Program, string | undefined>({
      async queryFn(arg) {
        return await getProgram(arg);
      },
      providesTags: (_, __, arg) => [
        ...arg ? [{type: 'program' as const, id: arg}] : [],
      ],
    }),
    setProgram: builder.mutation<Program, Program>({
      async queryFn(arg) {
        return await setProgram(arg);
      },
      invalidatesTags: (result, __, arg) => [
        ...result ? ['programs' as const] : [],
        ...result ? [{type: 'program' as const, id: arg.id}] : [],
      ],
    }),
    deleteProgram: builder.mutation<null, string>({
      async queryFn(arg) {
        return await deleteProgram(arg);
      },
      invalidatesTags: (result, _, arg) => [
        ...result === null ? ['programs' as const] : [],
        ...result === null ? [{type: 'program' as const, id: arg}] : [],
      ],
    }),
    getWorkout: builder.query<Workout<ExerciseWithSets<ExerciseSet>>, string | undefined>({
      async queryFn(arg) {
        return await getWorkout(arg);
      },
      providesTags: (_, __, arg) => [{type: 'workout', id: arg}],
    }),
    setWorkout: builder.mutation<Workout<ExerciseWithSets<ExerciseSet>>, Workout<ExerciseWithSets<ExerciseSet>>>({
      async queryFn(arg) {
        return await setWorkout(arg);
      },
      invalidatesTags: (_, __, arg) => [
        {type: 'workout', id: arg.id},
        ...arg.program ? [{type: 'program' as const, id: arg.program?.id}] : [],
      ],
    }),
    deleteWorkout: builder.mutation<null, DeleteWorkoutArg>({
      async queryFn(arg) {
        return await deleteWorkout(arg);
      },
      invalidatesTags: (_, __, arg) => [
        {type: 'workout', id: arg.workoutId},
        ...arg.programId ? [{ type: 'programs' as const, id: arg.programId }] : [],
      ],
    }),
    createBlankClientProgram: builder.mutation<Program, CreateBlankClientProgramArg>({
      async queryFn(arg) {
        return await createBlankClientProgram(arg);
      },
      invalidatesTags: (newProgram) => [
        ...newProgram ? [{type: 'program' as const, id: newProgram.id}] : [],
        'programs',
      ],
    }),
    createClientProgramFromTemplate: builder.mutation<Program, CreateClientProgramFromTemplateArg>({
      async queryFn(arg) {
        return await createClientProgramFromTemplate(arg);
      },
      invalidatesTags: (newProgram) => [
        ...newProgram ? [{type: 'program' as const, id: newProgram.id}] : [],
        'programs',
      ],
    }),
    createClientProgramFromProgram: builder.mutation<Program, CreateClientProgramFromProgramArg>({
      async queryFn(arg) {
        return await createClientProgramFromProgram(arg);
      },
      invalidatesTags: (newProgram) => [
        ...newProgram ? [{type: 'program' as const, id: newProgram.id}] : [],
        'programs',
      ],
    }),
    sendProgram: builder.mutation<Program, string>({
      async queryFn(arg) {
        return await sendProgram(arg);
      },
      invalidatesTags: (sentProgram) => [
        ...sentProgram ? [{type: 'program' as const, id: sentProgram.id}] : [],
        'programs',
      ],
    }),
  }),
});

type GetProgramReturnType = { data: Program } | { error: any };
const getProgram = async (programId?: string): Promise<GetProgramReturnType> => {
  if (!programId) {
    return {
      error: "No program ID",
    };
  }

  try {
    const docRef = doc(db, `programs/${programId}`);
    const snapshot = await getDoc(docRef);
    const data = snapshot.data();
    if (!data) {
      return {
        error: `Couldn't find program with ID ${programId}`,
      }
    }

    const workouts: WorkoutMetadata[] = [];
    if (data.workouts && Array.isArray(data.workouts)) {
      for (const metadata of data.workouts) {
        workouts.push({
          id: metadata.id,
          name: metadata.name,
          ...(metadata.description && { description: metadata.description }),
          ...(metadata.exercises && { exercises: metadata.exercises }),
          ...(metadata.started && { started: new Date(Date.parse(metadata.started)) }),
          ...(metadata.modified && { modified: new Date(Date.parse(metadata.modified)) }),
          ...(metadata.finished && { finished: new Date(Date.parse(metadata.finished)) }),
        });
      }
    }

    return {
      data: {
        id: programId,
        name: data.name,
        description: data.description,
        clientId: data.clientId,
        workouts,
        ...(data.maxWorkoutStarted && {maxWorkoutStarted: new Date(Date.parse(data.maxWorkoutStarted))}),
        ...(data.created && {created: new Date(Date.parse(data.created))}),
        ...(data.sent && {sent: new Date(Date.parse(data.sent))}),
        ...(data.started && {started: new Date(Date.parse(data.started))}),
        ...(data.modified && {modified: new Date(Date.parse(data.modified))}),
        ...(data.finished && {finished: new Date(Date.parse(data.finished))}),
      },
    }
  }
  catch (error: any) {
    console.error(error.message);
    return {
      error: error.message,
    }
  }
}

type SetProgramReturnType = { data: Program } | { error: string };
const setProgram = async (program: Program): Promise<SetProgramReturnType> => {
  await new Promise(r => setTimeout(r, 200));

  const uid = auth.currentUser?.uid;
  if (!uid) {
    return {
      error: "Authentication error",
    };
  }

  try {
    const writeProgramCallable = httpsCallable(functions, 'writeProgram');
    const writeProgramResponse = await writeProgramCallable({
      programId: program.id,
      program: programToRawData(
        {
          ...program,
          modified: new Date(),
        },
        uid,
      ),
    });
    return {
      data: (writeProgramResponse.data as any).program as Program,
    }
  }
  catch (error: any) {
    console.error(error.message);
    return {
      error: "Unable to save program",
    }
  }
}

type DeleteProgramReturnType = { data: null } | { error: string };
const deleteProgram = async (programId: string): Promise<DeleteProgramReturnType> => {
  await new Promise(r => setTimeout(r, 200));

  const uid = auth.currentUser?.uid;
  if (!uid) {
    return {
      error: "Authentication error",
    };
  }

  try {
    const deleteProgramCallable = httpsCallable(functions, 'deleteProgram');
    await deleteProgramCallable({
      programId: programId,
    });
    return {
      data: null,
    }
  }
  catch (error: any) {
    console.error(error.message);
    return {
      error: "Unable to delete program",
    }
  }
}

const exercisesFromDataArray = (data: any[]): ExerciseWithSets<ExerciseSet>[] => {
  const exercises: ExerciseWithSets<ExerciseSet>[] = [];
  for (const exerciseData of data) {
    const sets: ExerciseSet[] = [];

    if (exerciseData.sets && Array.isArray(exerciseData.sets)) {
      for (const set of exerciseData.sets) {
        sets.push({
          ...set,
        });
      }
    }

    exercises.push({
      name: exerciseData.name,
      notes: exerciseData.notes,
      fields: new Set(exerciseData.fields),
      weightUnits: exerciseData.weightUnits,
      ...(sets.length > 0 && { sets: sets }),
    });
  }
  return exercises;
}

type GetWorkoutReturnType = { data: Workout<ExerciseWithSets<ExerciseSet>> } | { error: any };
const getWorkout = async (workoutId: string | undefined): Promise<GetWorkoutReturnType> => {
  await new Promise(r => setTimeout(r, 1000));

  if (!workoutId) {
    return {
      error: "No program ID",
    };
  }

  try {
    const docRef = doc(db, `workouts/${workoutId}`);
    const snapshot = await getDoc(docRef);
    const data = snapshot.data();
    if (!data) {
      return {
        error: `Couldn't find workout with ID ${workoutId}`,
      }
    }

    const baseExercises: ExerciseWithSets<ExerciseSet>[] = data.baseExercises && Array.isArray(data.baseExercises)
      ? exercisesFromDataArray(data.baseExercises)
      : [];

    const performedExercises: ExerciseWithSets<ExerciseSet>[] = data.performedExercises && Array.isArray(data.performedExercises)
      ? exercisesFromDataArray(data.performedExercises)
      : [];

    return {
      data: {
        id: workoutId,
        program: data.program,
        name: data.name,
        clientId: data.clientId,
        description: data.description,
        ...(data.started && {started: new Date(Date.parse(data.started))}),
        ...(data.modified && {modified: new Date(Date.parse(data.modified))}),
        ...(data.finished && {finished: new Date(Date.parse(data.finished))}),
        ...(baseExercises.length > 0 && { baseExercises: baseExercises }),
        ...(performedExercises.length > 0 && { performedExercises: performedExercises }),
      },
    }
  }
  catch (error: any) {
    console.error(error.message);
    return {
      error: error.message,
    }
  }
}

type SetWorkoutReturnType = { data: Workout<ExerciseWithSets<ExerciseSet>> } | { error: string };
const setWorkout = async (workout: Workout<ExerciseWithSets<ExerciseSet>>): Promise<SetWorkoutReturnType> => {
  await new Promise(r => setTimeout(r, 200));

  const uid = auth.currentUser?.uid;
  if (!uid) {
    return {
      error: "Authentication error",
    };
  }

  try {
    const writeWorkoutCallable = httpsCallable(functions, 'writeWorkout');
    const writeWorkoutResponse = await writeWorkoutCallable({
      workoutId: workout.id,
      workout: workoutToRawData(workout, uid),
    });
    return {
      data: (writeWorkoutResponse.data as any).workout as Workout<ExerciseWithSets<ExerciseSet>>,
    }
  }
  catch (error: any) {
    console.error(error.message);
    return {
      error: "Unable to save workout",
    }
  }
}

type DeleteWorkoutArg = {
  workoutId: string,
  programId?: string,
}
type DeleteWorkoutReturnType = { data: null } | { error: string };
const deleteWorkout = async (arg: DeleteWorkoutArg): Promise<DeleteWorkoutReturnType> => {
  const uid = auth.currentUser?.uid;
  if (!uid) {
    return {
      error: "Authentication error",
    };
  }

  try {
    const deleteWorkoutCallable = httpsCallable(functions, 'deleteWorkout');
    await deleteWorkoutCallable({
      workoutId: arg.workoutId,
    });
    return {
      data: null,
    }
  }
  catch (error: any) {
    console.error(error.message);
    return {
      error: "Unable to delete workout",
    }
  }
}

type CreateBlankClientProgramArg = {
  clientId: string,
  name: string,
  description?: string,
}
type CreateBlankClientProgramReturnType = { data: Program } | { error: string };
const createBlankClientProgram = async ({ clientId, name, description }: CreateBlankClientProgramArg): Promise<CreateBlankClientProgramReturnType> => {
  const programWithNameExistsForClientAndCoach = async (coachId: string, clientId: string, name: string): Promise<boolean> => {
    const programsRef = collection(db, 'programs');
    var q = query(programsRef, where("name", "==", name));
    q = query(q, where('coachId', '==', coachId));
    q = query(q, where('clientId', '==', clientId));
    const matchingNames = await getDocs(q);
    return matchingNames.size > 0;
  };

  const coachId = auth.currentUser?.uid;
  if (!coachId) {
    return {
      error: "Authentication error",
    }
  }

  await new Promise(r => setTimeout(r, 2000));

  try {
    if (await programWithNameExistsForClientAndCoach(coachId, clientId, name)) {
      return {
        error: `'${name}' already exists, please choose a unique name.`,
      }
    }

    const created = new Date();
    const program: Program = {
      id: doc(collection(db, 'programs')).id,
      name,
      clientId,
      ...description && {description},
      created,
      modified: created,
    };

    const writeProgramCallable = httpsCallable(functions, 'writeProgram');
    const writeProgramResponse = await writeProgramCallable({
      programId: program.id,
      program: programToRawData(program, coachId),
    });
    return {
      data: (writeProgramResponse.data as any).program as Program,
    }
  }
  catch (error: any) {
    console.error(error);
    return {
      error: "Unable to create program",
    }
  }
}

type CreateClientProgramFromTemplateArg = {
  clientId: string,
  templateId: string,
}
type CreateClientProgramFromTemplateReturnType = { data: Program } | { error: any };
const createClientProgramFromTemplate = async ({ clientId, templateId }: CreateClientProgramFromTemplateArg): Promise<CreateClientProgramFromTemplateReturnType> => {
  const coachId = auth.currentUser?.uid;
  if (!coachId) {
    return {
      error: "Authentication error",
    }
  }

  await new Promise(r => setTimeout(r, 1000));

  try {
    const createProgramFromTemplateCallable = httpsCallable(functions, 'createProgramFromTemplate');
    const createProgramResponse = await createProgramFromTemplateCallable({
      clientId,
      templateId,
    });
    return {
      data: (createProgramResponse.data as any).program as Program,
    }
  }
  catch (error: any) {
    return {
      error,
    }
  }
}

type CreateClientProgramFromProgramArg = {
  clientId: string,
  programId: string,
}
type CreateClientProgramFromProgramReturnType = { data: Program } | { error: any };
const createClientProgramFromProgram = async ({ clientId, programId }: CreateClientProgramFromProgramArg): Promise<CreateClientProgramFromProgramReturnType> => {
  const coachId = auth.currentUser?.uid;
  if (!coachId) {
    return {
      error: "Authentication error",
    }
  }

  await new Promise(r => setTimeout(r, 1000));

  try {
    const createProgramFromProgramCallable = httpsCallable(functions, 'createProgramFromProgram');
    const createProgramResponse = await createProgramFromProgramCallable({
      clientId,
      programId,
    });
    return {
      data: (createProgramResponse.data as any).program as Program,
    }
  }
  catch (error: any) {
    return {
      error,
    }
  }
}

type SendProgramReturnType = { data: Program } | { error: any };
const sendProgram = async (programId: string): Promise<SendProgramReturnType> => {
  const coachId = auth.currentUser?.uid;
  if (!coachId) {
    return {
      error: "Authentication error",
    }
  }

  await new Promise(r => setTimeout(r, 1000));

  try {
    const sendProgramCallable = httpsCallable(functions, 'sendProgram');
    const sendProgramResponse = await sendProgramCallable({
      programId,
    });
    return {
      data: (sendProgramResponse.data as any).program as Program,
    }
  }
  catch (error: any) {
    return {
      error,
    }
  }
}

export const {
  useGetClientProgramsQuery,
  useGetProgramQuery,
  useSetProgramMutation,
  useSetWorkoutMutation,
  useDeleteWorkoutMutation,
  useGetWorkoutQuery,
  useCreateBlankClientProgramMutation,
  useCreateClientProgramFromTemplateMutation,
  useCreateClientProgramFromProgramMutation,
  useDeleteProgramMutation,
  useSendProgramMutation,
} = programsApi;