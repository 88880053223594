import { ExerciseField, ExerciseSet, ExerciseWithSets, ExerciseWithSetsAlias, WeightUnits } from "../types";

export const defaultSetFields = new Set<ExerciseField>([
  ExerciseField.Reps,
  ExerciseField.Weight,
]);

export const createExerciseWithName = (name: string): ExerciseWithSets<ExerciseSet> => {
  return {
    name,
    fields: defaultSetFields,
    weightUnits: WeightUnits.Kilograms,
    sets: [createSetFromFields(defaultSetFields)],
  }
};

export const createSetFromFields = (fields: Set<ExerciseField>): ExerciseSet => {
  return {
    ...(fields.has(ExerciseField.Reps) && { reps: null }),
    ...(fields.has(ExerciseField.Weight) && { weight: null }),
    ...(fields.has(ExerciseField.RPE) && { rpe: null }),
    ...(fields.has(ExerciseField.Percentage) && { percentage: null }),
    ...(fields.has(ExerciseField.Time) && { time: null }),
  };
};

export enum ActionType {
  LoadExercise,
  EditName,
  EditNotes,
  EditWeightUnits,
  AddSet,
  EditSetFieldValue,
  DeleteSet,
  EditField,
};

interface LoadExerciseAction<
  ExerciseType extends ExerciseWithSets<SetType>,
  SetType extends ExerciseSet = ExerciseWithSetsAlias<ExerciseType>
> {
  type: ActionType.LoadExercise,
  exercise: ExerciseType,
};

interface EditNameAction {
  type: ActionType.EditName,
  name: string,
};

interface EditNotesAction {
  type: ActionType.EditNotes,
  notes: string | null,
};

interface EditWeightUnits {
  type: ActionType.EditWeightUnits,
  weightUnits: WeightUnits,
};

interface AddSetAction<SetType extends ExerciseSet> {
  type: ActionType.AddSet,
  additionalFields?: Omit<SetType, keyof ExerciseSet>,
};

interface EditSetFieldValueAction {
  type: ActionType.EditSetFieldValue,
  index: number,
  field: ExerciseField,
  value: any,
};

interface DeleteSetAction {
  type: ActionType.DeleteSet,
  index: number,
};

interface EditField {
  type: ActionType.EditField,
  field: ExerciseField,
  include: boolean,
};

export type Action<
  ExerciseType extends ExerciseWithSets<SetType>,
  SetType extends ExerciseSet = ExerciseWithSetsAlias<ExerciseType>
> = LoadExerciseAction<ExerciseType, SetType>
  | EditNameAction
  | EditNotesAction
  | EditWeightUnits
  | AddSetAction<SetType>
  | EditSetFieldValueAction
  | DeleteSetAction
  | EditField;

export type ExerciseReducerReturnType = ReturnType<typeof exerciseReducer>;

export const exerciseReducer = <
  ExerciseType extends ExerciseWithSets<SetType>,
  SetType extends ExerciseSet = ExerciseWithSetsAlias<ExerciseType>
>(state: ExerciseType | undefined, action: Action<ExerciseType, SetType>): ExerciseType | undefined => {
  if (action.type === ActionType.LoadExercise) {
    return action.exercise;
  }

  if (!state) {
    return state;
  }

  switch (action.type) {
    case ActionType.EditName:
      return {
        ...state,
        name: action.name,
      };
    case ActionType.EditNotes:
      const { notes, ...exercise } = state;
      return {
        ...exercise,
        ...action.notes && { notes: action.notes },
      } as ExerciseType;
    case ActionType.EditWeightUnits:
      return editWeightUnits<ExerciseType, SetType>(state, action.weightUnits);
    case ActionType.AddSet:
      return addSet<ExerciseType, SetType>(state, action.additionalFields);
    case ActionType.EditSetFieldValue:
      return editSetFieldValue<ExerciseType, SetType>(state, action.index, action.field, action.value);
    case ActionType.DeleteSet:
      return deleteSet<ExerciseType, SetType>(state, action.index);
    case ActionType.EditField:
      return editField<ExerciseType, SetType>(state, action.field, action.include);
    default:
      return state;
  }
};

const editWeightUnits = <
  ExerciseType extends ExerciseWithSets<SetType>,
  SetType extends ExerciseSet = ExerciseWithSetsAlias<ExerciseType>
>(exercise: ExerciseType, weightUnits: WeightUnits): ExerciseType => {
  if (!exercise.fields.has(ExerciseField.Weight)) {
    return exercise;
  };
  return {
    ...exercise,
    weightUnits,
  };
};

const addSet = <
  ExerciseType extends ExerciseWithSets<SetType>,
  SetType extends ExerciseSet = ExerciseWithSetsAlias<ExerciseType>
>(exercise: ExerciseType, additionalFields?: Omit<SetType, keyof ExerciseSet>): ExerciseType => {
  if (!exercise.sets || exercise.sets.length === 0) {
    // If there's no sets, add a default set from the fields on the exercise.
    return {
      ...exercise,
      sets: [{
        ...createSetFromFields(exercise.fields),
        ...additionalFields,
      }],
    };
  }
  // If there's pre-existing sets, just duplicate the last one.
  const sets = exercise.sets.slice() ?? [];
  return {
    ...exercise,
    sets: [
      ...sets,
      {
        ...sets[sets.length - 1],
        ...additionalFields,
      },
    ],
  };
};

const editSetFieldValue = <
  ExerciseType extends ExerciseWithSets<SetType>,
  SetType extends ExerciseSet = ExerciseWithSetsAlias<ExerciseType>
>(exercise: ExerciseType, index: number, field: ExerciseField, value: any): ExerciseType => {
  if (!exercise.sets || index < 0 || index >= exercise.sets.length) {
    return exercise;
  }

  if (!exercise.fields.has(field)) {
    return exercise;
  }

  // Allow decimals for some field types, integers only for others.
  if (typeof value === 'number') {
    switch (field) {
      case ExerciseField.Reps:
      case ExerciseField.Time: {
        if (!Number.isInteger(value)) {
          return exercise;
        }
        break;
      }
      case ExerciseField.Weight:
      case ExerciseField.RPE:
      case ExerciseField.Percentage:
        break;
    }
  }
  else if (value !== null) {
    return exercise;
  }

  const sets = exercise.sets.slice();
  sets[index] = {
    ...sets[index],
    [field]: value,
  };
  return {
    ...exercise,
    sets,
  };
};

const deleteSet = <
  ExerciseType extends ExerciseWithSets<SetType>,
  SetType extends ExerciseSet = ExerciseWithSetsAlias<ExerciseType>
>(exercise: ExerciseType, index: number): ExerciseType => {
  if (!exercise.sets) {
    return exercise;
  }
  const sets = exercise.sets.slice();
  sets.splice(index, 1);
  if (sets.length === 0) {
    // Remove the sets entry from the object if there's no more sets.
    const { sets: _, ...exerciseWithoutSets } = exercise;
    return {
      ...exerciseWithoutSets,
    } as ExerciseType;
  }

  // Otherwise just set the new sets array.
  return {
    ...exercise,
    sets,
  };
};

const editField = <
  ExerciseType extends ExerciseWithSets<SetType>,
  SetType extends ExerciseSet = ExerciseWithSetsAlias<ExerciseType>
>(exercise: ExerciseType, field: ExerciseField, include: boolean): ExerciseType => {
  const fields = new Set(exercise.fields);
  if (include) {
    fields.add(field);
  }
  else {
    fields.delete(field);
  };

  if (fields.size === 0) {
    // No fields, so remove all the sets.
    const { sets: _, ...exerciseWithoutSets } = exercise;
    return {
      ...exerciseWithoutSets,
      fields,
    } as ExerciseType;
  }

  const weightUnits = fields.has(ExerciseField.Weight)
    ? exercise.weightUnits ?? WeightUnits.Kilograms
    : exercise.weightUnits;

  // Update each set, either keeping the field value if it already exists or setting to null for a new one.
  // Remove any fields that have been unchecked from pre-existing sets too.
  const sets = exercise.sets?.map(({reps, weight, time, rpe, percentage, ...otherFields}) => ({
    ...otherFields,
    ...(fields.has(ExerciseField.Reps) && { reps: reps ?? null }),
    ...(fields.has(ExerciseField.Weight) && {
        weight: weight ?? null,
      }
    ),
    ...(fields.has(ExerciseField.Time) && { time: time ?? null }),
    ...(fields.has(ExerciseField.RPE) && { rpe: rpe ?? null }),
    ...(fields.has(ExerciseField.Percentage) && { percentage: percentage ?? null }),
  } as SetType));

  return {
    ...exercise,
    fields,
    weightUnits,
    sets,
  };
};
