import Box from "@mui/material/Box"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Typography from "@mui/material/Typography"
import { Program } from "../../../../model/types"
import Button from "@mui/material/Button"
import LoadingButton from "@mui/lab/LoadingButton"
import { templatesApi, useDeleteProgramTemplateMutation } from "../../../../model/slices/templatesSlice"
import { useEffect } from "react"
import { store } from "../../../../model/store"

type Props = {
  program?: Program,
  open: boolean,
  onClose: (deleted: boolean) => void,
}

const DeleteProgramTemplateDialog = (props: Props) => {
  const [deleteProgramTemplate, { error, isLoading, isSuccess }] = useDeleteProgramTemplateMutation();

  const handleDelete = async () => {
    const programId = props.program?.id;
    if (!programId) {
      return;
    }
    await deleteProgramTemplate(programId);
  }

  useEffect(
    () => {
      if (isSuccess) {
        props.onClose(true);
      }
    },
    [isSuccess, props]
  )

  return (
    <Dialog
      maxWidth="sm"
      open={props.open}
      onClose={props.onClose}
    >
      <DialogTitle>
        Delete Program
      </DialogTitle>
      <DialogContent>
        <Box minWidth="300px">
          {!!error &&
            <Typography variant="body2" color="error" mb={2}>
              There was a problem deleting the program.
            </Typography>
          }
          <Typography mb={2}>
            You are about to remove <Box component="span" fontWeight='fontWeightMedium'>{props.program?.name}</Box>.
          </Typography>
          <Typography>
            This action cannot be undone.
          </Typography>
          <Box mt={3} display="flex" gap={1} justifyContent="flex-end">
            <Button onClick={() => props.onClose(false)} disabled={isLoading}>
              Cancel
            </Button>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              type="submit"
              color="error"
              onClick={handleDelete}
            >
              Delete
            </LoadingButton>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteProgramTemplateDialog