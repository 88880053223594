import Content from "../../../components/Content"
import React, { useState } from "react"
import DashboardAppBar from "../../../components/DashboardAppBar"
import Typography from "@mui/material/Typography"
import ContentPaper from "../../../components/ContentPaper"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import NewClientDialog from "./NewClientDialog"
import { useCurrentUser } from "../../../../../hooks/users/currentUserHook"
import { auth } from "../../../../../config/firebase"
import PendingClients from "./PendingClients"
import ExistingClientsTable from "./ExistingClientsTable"

const ClientsList = () => {
  const [newClientOpen, setNewClientOpen] = useState(false);
  const {userData, isLoading} = useCurrentUser(auth);

  return (
    <React.Fragment>
      <DashboardAppBar title="Clients" />
      <Content>
        <PendingClients />

        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6">
            Existing Clients
          </Typography>
          <Button variant="contained" onClick={() => setNewClientOpen(true)}>
            New Client
          </Button>
          <NewClientDialog open={newClientOpen} onClose={() => setNewClientOpen(false)} />
        </Box>
        <ContentPaper sx={{ mb: 4 }}>
          <ExistingClientsTable onInviteClicked={() => setNewClientOpen(true)} isUserLoading={isLoading} user={userData} />
        </ContentPaper>
      </Content>
    </React.Fragment>
  )
}

export default ClientsList