import { useAuthState } from "react-firebase-hooks/auth"
import { auth } from "../../config/firebase"
import { useEffect, useState } from "react";
import { useGetProgramTemplateQuery, useGetProgramTemplatesQuery } from "../../model/slices/templatesSlice";

export const useGetProgramTemplates = () => {
  const [user] = useAuthState(auth);
  const {data, isFetching, error} = useGetProgramTemplatesQuery(user?.uid);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(
    () => {
      setIsLoading(!user || isFetching);
    },
    [user, isFetching]
  );

  return {
    data,
    isLoading,
    error,
  }
}

export const useGetProgramTemplate = (programId: string | undefined) => {
  const [user] = useAuthState(auth);
  const {data, isLoading: templateLoading, error} = useGetProgramTemplateQuery(programId);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(
    () => {
      setIsLoading(!user || !programId || templateLoading);
    },
    [user, templateLoading, programId]
  );

  return {
    data,
    isLoading,
    error,
  }
}