import { useCallback, useEffect, useState } from "react";
import { Program } from "../../model/types";
import { useCreateClientProgramFromProgramMutation } from "../../model/slices/programsSlice";

export const useCreateProgramFromProgram = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean | undefined>(undefined);
  const [createClientProgramFromProgramMutation, { error }] = useCreateClientProgramFromProgramMutation();
  const [errorString, setErrorString] = useState<string>();

  const createProgramFromProgram = useCallback(
    async ({clientId, programId}: {clientId: string, programId: string}): Promise<Program | undefined> => {
      setIsSuccess(undefined);
      setIsLoading(true);
      const result = await createClientProgramFromProgramMutation({clientId, programId});
      setIsLoading(false);
      if ('data' in result) {
        setIsSuccess(true);
        return result.data;
      }
      else {
        setIsSuccess(false);
        return undefined;
      }
    },
    [createClientProgramFromProgramMutation]
  );

  useEffect(
    () => {
      if (error) {
        console.error(error);
        if (typeof error === 'string') {
          setErrorString(error as string);
        }
        else {
          setErrorString("An error occurred");
        }
      }
      else {
        setErrorString(undefined);
      }
    },
    [error]
  );

  return {
    createProgramFromProgram,
    isLoading,
    isSuccess,
    error: errorString,
  };
}