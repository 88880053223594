import React, { useEffect, useRef, useState } from 'react'
import ScrollToTopOnMount from "../components/ScrollToTopOnMount"
import Container from "@mui/material/Container"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import Page from "../components/Page"
import Box from "@mui/material/Box"
import { useForm } from "react-hook-form"
import TextField from "@mui/material/TextField"
import LoadingButton from "@mui/lab/LoadingButton"
import ReCAPTCHA from "react-google-recaptcha"
import { useSendContactFormMessage } from "../hooks/sendContactFormMessage"

interface ContactFormData {
  name: string,
  email: string,
  message: string,
}

const Contact = () => {
  const onSubmit = async (data: ContactFormData) => {
    try {
      setIsCaptureVerifying(true);
      const token = await recaptchaRef.current?.executeAsync();
      setIsCaptureVerifying(false);
      if (!token) {
        return;
      }
      
      sendContactFormMessage({
        ...data,
        recaptchaToken: token,
      });
      recaptchaRef.current?.reset();
    }
    catch (error) {
      setIsCaptureVerifying(false);
      console.error(error);
    }
  }

  const [isCaptureVerifying, setIsCaptureVerifying] = useState<boolean>(false);
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const {sendContactFormMessage, isLoading: isSendLoading, isSuccess, error} = useSendContactFormMessage();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<ContactFormData>();

  useEffect(
    () => {
      if (isSuccess) {
        reset();
      }
    },
    [isSuccess, reset],
  );

  return (
    <React.Fragment>
      <ScrollToTopOnMount />
      <Page>
        <Container>
          <Paper sx={{
            p: 3,
            mx: { md: 3 },
            my: 3,
          }}>
            <Typography variant="h4" mb={3}>
              Contact
            </Typography>
            
            <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleSubmit(onSubmit)}>
              {isSuccess &&
                <Typography pb={2} color="success.main">
                  Thank you, your message was successfully sent.
                </Typography>
              }

              {error &&
                <Typography pb={2} color="error">
                  {error}
                </Typography>
              }
              
              <TextField
                margin="normal"
                fullWidth
                id="name"
                label="Your Name"
                autoComplete="name"
                {...register("name", {
                  required: true,
                  maxLength: 120,
                })}
                error={!!errors.name}
                helperText={errors.name && "Name cannot be empty and must be valid"}
                disabled={isSendLoading || isCaptureVerifying}
                autoFocus
              />

              <TextField
                margin="normal"
                fullWidth
                id="email"
                label="Your Email"
                autoComplete="email"
                {...register("email", {
                  required: true,
                  maxLength: 120,
                  pattern: /^\S+@\S+$/i,
                })}
                error={!!errors.email}
                helperText={errors.email && "Email cannot be empty and must be valid"}
                disabled={isSendLoading || isCaptureVerifying}
              />

              <TextField
                margin="normal"
                fullWidth
                multiline
                rows={3}
                id="message"
                label="Message"
                autoComplete="message"
                {...register("message", {
                  required: true,
                })}
                error={!!errors.message}
                helperText={errors.message && "Message cannot be empty"}
                disabled={isSendLoading || isCaptureVerifying}
              />

              <ReCAPTCHA
                sitekey="6LcSjBopAAAAACRV01Cdz1kG7BKaqBSsBAss0ZHz"
                size="invisible"
                ref={recaptchaRef}
              />

              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ my: 2 }}
                loading={isSendLoading || isCaptureVerifying}
              >
                Send
              </LoadingButton>
            </Box>
          </Paper>
        </Container>
      </Page>
    </React.Fragment>
  )
}

export default Contact