import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import { theme } from "../../../theme"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import CloseIcon from '@mui/icons-material/Close';
import { ReactNode } from "react"

type Props = {
  open: boolean,
  onClose: (() => void) | undefined,
  children: ReactNode,
}

const WorkoutBuilderDialog = (props: Props) => {
  return (
    <Dialog
      fullWidth
      open={props.open}
      onClose={props.onClose}
      maxWidth="lg"
    >
      <DialogContent sx={{ background: theme.palette.paper }}>
        <Box display="flex">
          <IconButton
            onClick={() => props.onClose?.()}
            sx={{
              color: (theme) => theme.palette.grey[500],
              marginLeft: "auto",
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box pr={1}>
          {props.children}
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default WorkoutBuilderDialog