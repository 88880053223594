import Box from "@mui/material/Box"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { SafeRoutePath } from "../../../../../../components/SafeRouterLink"
import { ClientData } from "../../../../../../model/types"
import ContentPaper from "../../../../components/ContentPaper"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"
import TableLoading from "../../../../components/TableLoading"
import { useGetClientPrograms } from "../../../../../../hooks/programs/getClientProgramHooks"
import ProgramsTable from "./ProgramsTable"
import { useCreateProgramFromProgram } from "../../../../../../hooks/programs/createProgramFromProgramHook"

type Props = {
  client: ClientData,
  open: boolean,
  onClose: (() => void) | undefined
}

const HistoryDialog = (props: Props) => {
  const {
    createProgramFromProgram,
    isLoading: createLoading,
    error: createError
  } = useCreateProgramFromProgram();
  const { data: programs, isLoading: programsLoading } = useGetClientPrograms({
    clientId: props.client.id,
    draft: false,
  });

  const [newProgramId, setNewProgramId] = useState<string>();

  const navigate = useNavigate();
  useEffect(
    () => {
      if (newProgramId) {
        props.onClose?.();
        navigate(SafeRoutePath.ResolvedClientProgram(props.client.id, newProgramId).path)
      }
    },
    [newProgramId, props, navigate]
  );

  const handleClickProgram = async (programId: string) => {
    const program = await createProgramFromProgram({clientId: props.client.id, programId});
    if (program) {
      setNewProgramId(program.id);
    }
  }

  return (
    <Dialog
      fullWidth
      open={props.open}
      onClose={props.onClose}
      maxWidth="md"
      disableRestoreFocus
    >
      <DialogTitle>Create Program from History</DialogTitle>
      <DialogContent>
       <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={createLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box>
          {!!createError &&
            <Typography mb={2} variant="body2" color="error">
              {createError}
            </Typography>
          }

          <Typography mb={2}>
            Select from the program history of <strong>{props.client.firstName} {props.client.lastName}</strong> below to get started.
          </Typography>

          <Typography mb={2}>
            The new program will remain in draft until you decide to send it.
          </Typography>

          {programsLoading && <TableLoading loading={true} />}
          {!programsLoading && programs && programs.length > 0 &&
            <ProgramsTable
              draft={false}
              programs={programs}
              client={props.client}
              onProgramClicked={(program) => handleClickProgram(program.id)}
              rowsPerPage={5}
            />
          }
          {!programsLoading && (!programs || programs.length === 0) &&
            <ContentPaper sx={{ mx: 2, my: 3 }}>
              <Grid item xs={6}>
                <Typography align="center" mt={1} mb={2} color="error">
                  {props.client.firstName} {props.client.lastName} does not have any programs in their history.
                </Typography>
              </Grid>
            </ContentPaper>
          }

          <Grid container spacing={1}>
            <Grid item xs={12} mt={1}>
              <Box display="flex" gap={1} justifyContent="flex-end">
                <Button
                  disabled={createLoading}
                  onClick={props.onClose}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog >
  )
}

export default HistoryDialog