import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import styled from "@emotion/styled";
import { theme } from "../../theme";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import React from "react";
import Link from "@mui/material/Link";
import SafeRouterLink, { RoutePath } from "../../components/SafeRouterLink";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters square {...props} />
))(() => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(() => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .02)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const FrequentlyAskedQuestions = () => {
  return (
    <Container>
      <Typography variant="h4" my={4}>
        Common Questions
      </Typography>
      <React.Fragment>
        <Accordion
          onChange={(_, expanded) => {
            if (expanded) {
              window.zaraz?.track('qa_whenAvailable');
            }
          }}
        >
          <AccordionSummary>
            <Typography fontWeight="bold">
              When will Spotter be available?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography p={1}>
              Spotter is currently in development, and we're excited about the progress we're making!
            </Typography>
            <Typography p={1}>
              To stay updated on our launch timeline and be among the first to experience Spotter, <Link component={SafeRouterLink} sx={{ verticalAlign: "baseline" }} to={RoutePath.Waitlist}>join our waitlist</Link>.
            </Typography>
            <Typography p={1}>
              Waitlist members will receive exclusive updates, early access opportunities, and firsthand information about Spotter's launch and features.
            </Typography>
            <Typography p={1}>
              Reserve your spot today and be part of revolutionizing the coaching experience with Spotter!
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          onChange={(_, expanded) => {
            if (expanded) {
              window.zaraz?.track('qa_whatCost');
            }
          }}
        >
          <AccordionSummary>
            <Typography fontWeight="bold">
              How much does it cost?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography p={1}>
              Spotter is completely free for coaches to use, providing full access to the platform's features and allowing seamless management of unlimited clients.
            </Typography>
            <Typography p={1}>
            There are no charges imposed by Spotter for coach platform usage.
            </Typography>
            <Typography p={1}>
              Coaches have the flexibility to set individual coaching fees per client within the Spotter platform.
            </Typography>
            <Typography p={1}>
              Clients pay coaches for their services through the platform, and in addition, there's a small service fee for clients to use Spotter, enabling unlimited coaching from their chosen coach.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          onChange={(_, expanded) => {
            if (expanded) {
              window.zaraz?.track('qa_trialPeriod');
            }
          }}
        >
          <AccordionSummary>
            <Typography fontWeight="bold">
              Does Spotter offer a trial period?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography p={1}>
              Spotter provides coaches and clients with full, unrestricted access to our platform for free. There's no need for trials as Spotter offers its complete range of features and services without any cost.
            </Typography>
            <Typography p={1}>
              <Link component={SafeRouterLink} sx={{ verticalAlign: "baseline" }} to={RoutePath.Waitlist}>Join our waitlist</Link> to be among the first to experience our comprehensive platform firsthand, where you'll enjoy seamless client-coach interactions, personalized workouts, and much more, all at no charge!
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          onChange={(_, expanded) => {
            if (expanded) {
              window.zaraz?.track('qa_howAddClients');
            }
          }}
        >
          <AccordionSummary>
            <Typography fontWeight="bold">
              How can coaches add clients to the Spotter platform?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography p={1}>
              Coaches can simply navigate to their dedicated web app interface, where they'll find easy-to-use tools to invite and onboard clients via email. 
            </Typography>
            <Typography p={1}>
              
            </Typography>
            <Typography p={1}>
              Clients then simply need to sign up and accept the invitation within the app to kickstart their fitness journey with their coach.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          onChange={(_, expanded) => {
            if (expanded) {
              window.zaraz?.track('qa_howManyClients');
            }
          }}
        >
          <AccordionSummary>
            <Typography fontWeight="bold">
              How many clients can a coach have?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography p={1}>
              Unlimited!
            </Typography>
            <Typography p={1}>
              Spotter provides coaches the freedom to manage an unlimited number of clients within our platform without any additional charges or limitations.
            </Typography>
            <Typography p={1}>
              We believe in supporting the success of coaches without imposing constraints on their client base.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          onChange={(_, expanded) => {
            if (expanded) {
              window.zaraz?.track('qa_setOwnFees');
            }
          }}
        >
          <AccordionSummary>
            <Typography fontWeight="bold">
              Can coaches set their own fees?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography p={1}>
              Yes, coaches can easily set their fees within the Spotter platform.
            </Typography>
            <Typography p={1}>
              They have the flexibility to customize fees and establish payment schedules specific to each client's requirements.
            </Typography>
            <Typography p={1}>
              This allows coaches to manage their pricing structures effectively, ensuring a personalized approach to their services.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          onChange={(_, expanded) => {
            if (expanded) {
              window.zaraz?.track('qa_howPay');
            }
          }}
        >
          <AccordionSummary>
            <Typography fontWeight="bold">
              How do clients pay coaches for services on the platform?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography p={1}>
              To ensure convenience and security, automated payments are facilitated through the app using Stripe, providing a reliable and secure payment gateway for seamless transactions.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          onChange={(_, expanded) => {
            if (expanded) {
              window.zaraz?.track('qa_whichDevices');
            }
          }}
        >
          <AccordionSummary>
            <Typography fontWeight="bold">
              Which devices is Spotter available on?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography p={1}>
              Spotter provides dedicated apps for both iOS and Android, allowing clients to effortlessly track workouts.
            </Typography>
            <Typography p={1}>
              Meanwhile, coaches can efficiently create, manage, and customize personalized programs using the convenient web app interface.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </React.Fragment>
    </Container>
  )
}

export default FrequentlyAskedQuestions