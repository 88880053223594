import { Outlet, useOutletContext, useParams } from "react-router-dom";
import { useGetClient } from "../../../../../hooks/clients/clientHooks";
import Typography from "@mui/material/Typography";
import React from "react";
import DashboardAppBar from "../../../components/DashboardAppBar";
import Content from "../../../components/Content";
import { SafeRoutePath } from "../../../../../components/SafeRouterLink";
import { ClientData } from "../../../../../model/types";

type ContextClientData = {
  clientId: string | undefined,
  client: ClientData | undefined,
  isLoading: boolean,
  error: string | undefined,
}

const Client = () => {
  const { clientId } = useParams();
  const {data: client, isLoading, error} = useGetClient(clientId);

  const clientName = client ? `${client.firstName} ${client.lastName}` : undefined;

  return (
    <React.Fragment>
      <DashboardAppBar
        title={clientName ?? "Client Not Found"}
        loading={isLoading}
        navigation={client ? [
          {
            title: "Summary",
            path: SafeRoutePath.ResolvedClientSummary(client.id),
          },
          {
            title: "Programs",
            path: SafeRoutePath.ResolvedClientPrograms(client.id),
          },
        ] : []}
      />
      {!isLoading && error && <Content><Typography>{error}</Typography></Content>}
      {!error &&
      <Content p={0}>
        <Outlet context={{clientId, client, isLoading, error}} />
      </Content>
      }
    </React.Fragment>
  )
}

export function useClientContext() {
  return useOutletContext<ContextClientData>();
}

export default Client