import { forwardRef } from "react";
import { LinkProps, Link as RouterLink } from "react-router-dom";

export enum RoutePath {
  Root = "/",
  Dashboard = "/dashboard/",
    DashboardClients = "/dashboard/clients",
      DashboardClientsClient = "/dashboard/clients/:clientId",
        DashboardClientsClientSummary = "/dashboard/clients/:clientId/summary",
        DashboardClientsClientPrograms = "/dashboard/clients/:clientId/programs",
          DashboardClientsClientProgram = "/dashboard/clients/:clientId/programs/:programId",
    DashboardProfile = "/dashboard/profile",
      DashboardProfileDetails = "/dashboard/profile/details",
      DashboardProfilePassword = "/dashboard/profile/password",
    DashboardPrograms = "/dashboard/programs",
      DashboardProgramsProgram = "/dashboard/programs/:programId",
    DashboardBilling = "/dashboard/billing",
  Waitlist = "/waitlist",
  Terms = "/terms",
  Privacy = "/privacy",
  Contact = "/contact",
}

export class SafeRoutePath {
  path: string;

  constructor(routePath: RoutePath) {
    this.path = routePath;
  }

  static ResolvedClient(clientId: string) {
    const resolved = new SafeRoutePath(RoutePath.DashboardClientsClient);
    resolved.path = resolved.path.replace(":clientId", clientId);
    return resolved;
  }

  static ResolvedClientSummary(clientId: string) {
    const resolved = new SafeRoutePath(RoutePath.DashboardClientsClientSummary);
    resolved.path = resolved.path.replace(":clientId", clientId);
    return resolved;
  }

  static ResolvedClientPrograms(clientId: string) {
    const resolved = new SafeRoutePath(RoutePath.DashboardClientsClientPrograms);
    resolved.path = resolved.path.replace(":clientId", clientId);
    return resolved;
  }

  static ResolvedClientProgram(clientId: string, programId: string) {
    const resolved = new SafeRoutePath(RoutePath.DashboardClientsClientProgram);
    resolved.path = resolved.path.replace(":clientId", clientId);
    resolved.path = resolved.path.replace(":programId", programId);
    return resolved;
  }

  static ResolvedDashboardProgramsProgram(programId: string) {
    const resolved = new SafeRoutePath(RoutePath.DashboardProgramsProgram);
    resolved.path = resolved.path.replace(":programId", programId);
    return resolved;
  }
}

interface Props extends Omit<LinkProps, "to"> {
  to: RoutePath | SafeRoutePath,
}

export type Ref = HTMLAnchorElement;

const SafeRouterLink = forwardRef<Ref, Props>((props, ref) => {
  const path = props.to instanceof SafeRoutePath ? props.to.path : props.to;
  return <RouterLink
    ref={ref}
    {...props}
    to={path}
  />
});

export default SafeRouterLink