import Drawer, { DrawerProps } from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import LogoText from "../../components/LogoText";
import SafeRouterLink, { RoutePath } from "../../components/SafeRouterLink";
import Person from "@mui/icons-material/Person";
import CreditCard from "@mui/icons-material/CreditCard";
import FitnessCenter from "@mui/icons-material/FitnessCenter";
import { useLocation } from "react-router-dom";

type Navigate = {
  id: string,
  icon: JSX.Element,
  path: RoutePath,
};

const navigation: Navigate[] = [
  {
    id: "Profile",
    icon: <Person />,
    path: RoutePath.DashboardProfile,
  },
  {
    id: "Programs",
    icon: <FitnessCenter />,
    path: RoutePath.DashboardPrograms,
  },
  {
    id: "Clients",
    icon: <PeopleIcon />,
    path: RoutePath.DashboardClients,
  },
  {
    id: "Billing",
    icon: <CreditCard />,
    path: RoutePath.DashboardBilling,
  },
];

const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
};

export default function Navigator(props: DrawerProps) {
  const { ...other } = props;
  const location = useLocation();

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem sx={{ ...item, ...itemCategory, color: '#fff' }}>
          <LogoText />
        </ListItem>
        <ListItem disablePadding sx={{ ...item, ...itemCategory, mb: 3 }}>
          <ListItemButton sx={{ padding: 0 }}
            component={SafeRouterLink}
            to={RoutePath.Dashboard}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText>Dashboard Home</ListItemText>
          </ListItemButton>
        </ListItem>

        {navigation.map(({id, icon, path}) => (
          <ListItem disablePadding key={id}>
            <ListItemButton selected={location.pathname.startsWith(path)} sx={item} component={SafeRouterLink} to={path}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText>{id}</ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}