import Box from "@mui/material/Box"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import useMediaQuery from "@mui/material/useMediaQuery"
import { theme } from "../../../../../theme"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import TextField from "@mui/material/TextField"
import { FieldError, useForm } from "react-hook-form"
import LoadingButton from "@mui/lab/LoadingButton"
import { useInviteClient } from "../../../../../hooks/clients/clientInviteHooks"
import { useEffect } from "react"
import Button from "@mui/material/Button"

type Props = {
  open: boolean,
  onClose: (() => void) | undefined
}

type FormData = {
  firstName: string,
  lastName: string,
  email: string,
}

const NewClientDialog = (props: Props) => {
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>();
  const { inviteClient, isLoading: isAddLoading, isSuccess, error: addError } = useInviteClient();

  useEffect(
    () => {
      if (isSuccess) {
        props.onClose?.()
      }
    },
    [isSuccess, props]
  );

  const nameHelperText = (error: FieldError | undefined, prefix: string) => {
    if (!error) {
      return "";
    }
    switch (error.type) {
      case "required":
        return `${prefix} Name is required`;
      case "maxLength":
        return `${prefix} Name exceeds maximum length`;
      default:
        return `${prefix} Name is invalid`;
    }
  }

  const emailHelperText = (error: FieldError | undefined) => {
    if (!error) {
      return "";
    }
    switch (error.type) {
      case "required":
        return "Email is required";
      case "maxLength":
        return "Email exceeds maximum length";
      default:
        return "Invalid email address";
    }
  }

  const onSubmit = (data: FormData) => {
    inviteClient({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      timestamp: new Date(),
    });
  }

  return (
    <Dialog
      fullWidth={matches}
      open={props.open}
      onClose={props.onClose}
      maxWidth="md"
    >
      <DialogTitle>New Client</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          {!!addError &&
            <Typography mb={2} variant="body2" color="error">
              Unable to invite client.
            </Typography>
          }

          <Typography mb={1}>
            Fill in the details below to add a new client. They will be sent an email with information on how to download the app and accept the inviation.
          </Typography>

          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                fullWidth
                id="firstName"
                label="First Name"
                {...register("firstName", {
                  required: true,
                  maxLength: 80,
                })}
                error={!!errors.firstName}
                helperText={nameHelperText(errors.firstName, "First")}
                disabled={isAddLoading}
                autoFocus
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                fullWidth
                id="lastName"
                label="Last Name"
                {...register("lastName", {
                  required: true,
                  maxLength: 100,
                })}
                error={!!errors.lastName}
                helperText={nameHelperText(errors.lastName, "Last")}
                disabled={isAddLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                fullWidth
                id="email"
                label="Email"
                {...register("email", {
                  required: true,
                  maxLength: 100,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  }
                })}
                error={!!errors.email}
                helperText={emailHelperText(errors.email)}
                disabled={isAddLoading}
              />
            </Grid>

            <Grid item xs={12} mt={1}>
              <Box display="flex" gap={1} justifyContent="flex-end">
                <Button disabled={isAddLoading} onClick={props.onClose}>
                  Cancel
                </Button>
                <LoadingButton loading={isAddLoading} variant="contained" type="submit">
                  Invite
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>

        </Box>
      </DialogContent>
    </Dialog >
  )
}

export default NewClientDialog