import { Skeleton } from "@mui/material"
import React from 'react'

type Props = {
  loading: boolean,
  children: React.ReactNode,
}

const TextFieldLoading = (props: Props) => {
  return (
    <React.Fragment>
      {props.loading
        ? <Skeleton height="80px" />
        : props.children
      }
    </React.Fragment>
  )
}

export default TextFieldLoading