import Typography from "@mui/material/Typography";
// import { useClientContext } from ".";

const ClientSummary = () => {
  // const { client, isLoading, error } = useClientContext();

  return (
    <Typography>
      Hello
    </Typography>
  )
}

export default ClientSummary