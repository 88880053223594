import { useParams } from "react-router-dom"
import { useGetProgramTemplate } from "../../../../hooks/programs/getProgramTemplateHooks";
import ProgramBuilder, { Editability } from "../../components/ProgramBuilder";
import { templatesApi } from "../../../../model/slices/templatesSlice";
import Stack from "@mui/material/Stack";
import BuilderBreadcrumbs from "../../components/ProgramBuilder/BuilderBreadcrumbs";
import Typography from "@mui/material/Typography";
import { auth } from "../../../../config/firebase";
import { ExerciseSet, ExerciseWithSets, Program, Workout } from "../../../../model/types";
import { store } from "../../../../model/store";
import { useAuthState } from "react-firebase-hooks/auth";
import { WorkoutRepository } from "../../../../model/repositories/workoutRepository";
import { ProgramRepository } from "../../../../model/repositories/programRepository";
import WorkoutBuilder from "../../components/ProgramBuilder/WorkoutBuilder";

const ProgramTemplate = () => {
  const { programId } = useParams();
  const [user] = useAuthState(auth);

  const programRepository: ProgramRepository = {
    getProgram: async (programId: string): Promise<Program | string> => {
      const response = await store.dispatch(templatesApi.endpoints.getProgramTemplate.initiate(programId));
      return response.data ?? response.error as string;
    },
    saveProgram: async (program: Program): Promise<string | null> => {
      const response = await store.dispatch(templatesApi.endpoints.setProgramTemplate.initiate(program));
      if ("error" in response) {
        return response.error as string;
      }
      return null;
    },
  };

  const workoutRepository: WorkoutRepository = {
    getWorkout: async (workoutId: string): Promise<Workout<ExerciseWithSets<ExerciseSet>> | string> => {
      const response = await store.dispatch(templatesApi.endpoints.getWorkoutTemplate.initiate(workoutId));
      return response.data ?? response.error as string;
    },
    deleteWorkout: async (workoutId: string, programId?: string): Promise<string | null> => {
      const response = await store.dispatch(templatesApi.endpoints.deleteWorkoutTemplate.initiate({
        workoutTemplateId: workoutId,
        programTemplateId: programId,
      }));
      return "error" in response ? response.error as string : null;
    },
    saveWorkout: async (workout: Workout<ExerciseWithSets<ExerciseSet>>): Promise<string | null> => {
      const response = await store.dispatch(templatesApi.endpoints.setWorkoutTemplate.initiate(workout));
      if ("error" in response) {
        return response.error as string;
      }
      return null;
    },
  };

  const { data: program, isLoading: isProgramLoading, error: getProgramError } = useGetProgramTemplate(programId);

  if (!!getProgramError && !isProgramLoading) {
    return <Stack>
      <Typography color="error">
        There was a problem loading this template.
      </Typography>
    </Stack>
  }

  return (
    <Stack>
      <BuilderBreadcrumbs template={program} isLoading={isProgramLoading} />
      {!getProgramError && programId && user &&
        <ProgramBuilder
          programId={programId}
          programRepository={programRepository}
          workoutRepository={workoutRepository}
          editability={Editability.Always}
          workoutBuilderContents={(workoutBuilderProps) =>
            <WorkoutBuilder
              workoutId={workoutBuilderProps.workoutId}
              program={workoutBuilderProps.program}
              workoutRepository={workoutBuilderProps.workoutRepository}
              onWorkoutSaved={(workout) => workoutBuilderProps.onWorkoutSaved?.(workout)}
            />
          }
        />
      }
    </Stack>
  )
}

export default ProgramTemplate