
export const uniqueNameFromListOfNames = (names: string[], name: string) => {
  var i = 0;
  const namesSet = new Set(names);
  while (namesSet.has(name)) {
    if (i === 0) {
      name = `${name} (${++i})`;
    }
    else {
      name = name.replace(new RegExp(`\\(${i}\\)$`), `(${++i})`);
    }
  }
  return name;
}