import { useCallback, useEffect, useState } from "react";
import { useCreateProgramTemplateMutation } from "../../model/slices/templatesSlice";
import { Program } from "../../model/types";

export const useCreateProgramTemplate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean | undefined>(undefined);
  const [createProgramTemplateMutation, { error }] = useCreateProgramTemplateMutation();
  const [errorString, setErrorString] = useState<string>();

  const createProgramTemplate = useCallback(
    async ({name, description}: {name: string, description?: string}): Promise<Program | undefined> => {
      setIsSuccess(undefined);
      setIsLoading(true);
      const result = await createProgramTemplateMutation({name, description});
      setIsLoading(false);
      if ("data" in result) {
        setIsSuccess(true);
        return result.data;
      }
      else {
        setIsSuccess(false);
        return undefined;
      }
    },
    [createProgramTemplateMutation]
  );

  useEffect(
    () => {
      if (error) {
        setErrorString(error as string);
      }
      else {
        setErrorString(undefined);
      }
    },
    [error]
  )

  return {
    createProgramTemplate,
    isLoading,
    isSuccess,
    error: errorString,
  };
}