import Box from "@mui/material/Box"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import LoadingButton from "@mui/lab/LoadingButton"
import { useEffect, useState } from "react"
import { ClientData, Program } from "../../../../../../model/types"
import { useDeleteProgramMutation } from "../../../../../../model/slices/programsSlice"
import { useCreateProgramFromProgram } from "../../../../../../hooks/programs/createProgramFromProgramHook"
import { useNavigate } from "react-router-dom"
import { SafeRoutePath } from "../../../../../../components/SafeRouterLink"

type Props = {
  client: ClientData,
  program: Program,
  open: boolean,
  onClose: () => void,
}

const ProgramFromProgramPrompt = (props: Props) => {
  const {
    createProgramFromProgram,
    isLoading: createLoading,
    error: createError
  } = useCreateProgramFromProgram();

  const [newProgramId, setNewProgramId] = useState<string>();

  const navigate = useNavigate();
  useEffect(
    () => {
      if (newProgramId) {
        props.onClose();
        navigate(SafeRoutePath.ResolvedClientProgram(props.client.id, newProgramId).path)
      }
    },
    [newProgramId, props, navigate]
  );

  const handleSubmit = async () => {
    const program = await createProgramFromProgram({clientId: props.client.id, programId: props.program.id});
    if (program) {
      setNewProgramId(program.id);
    }
  }

  return (
    <Dialog
      maxWidth="sm"
      open={props.open}
      onClose={props.onClose}
    >
      <DialogTitle>
        New Program
      </DialogTitle>
      <DialogContent>
        <Box minWidth="300px">
          {!!createError &&
            <Typography variant="body2" color="error" mb={2}>
              There was a problem creating the program.
            </Typography>
          }
          <Typography mb={2}>
            Copy <Box component="span" fontWeight='fontWeightMedium'>{props.program.name}</Box> as a new program for '{props.client.firstName} {props.client.lastName}'?
          </Typography>
          <Typography mb={2}>
            The new program will remain in draft until you decide to send it.
          </Typography>
          <Box mt={3} display="flex" gap={1} justifyContent="flex-end">
            <Button onClick={() => props.onClose()} disabled={createLoading}>
              Cancel
            </Button>
            <LoadingButton
              loading={createLoading}
              variant="contained"
              type="submit"
              onClick={handleSubmit}
            >
              Create
            </LoadingButton>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default ProgramFromProgramPrompt
