import { User } from "firebase/auth";
import { useUpdateUserMutation } from "../../model/slices/userSlice";
import { useCallback, useState } from "react";

export const useUpdateUser = (user: User | null) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean | undefined>(undefined);
  const [updateUserMutation, { error }] = useUpdateUserMutation();

  const updateUser = useCallback(
    async (firstName: string, lastName: string, email: string) => {
      if (!user) {
        return;
      }
      setIsSuccess(undefined);
      setIsLoading(true);
      const result = await updateUserMutation({
        user: user,
        userData: {
          uid: user.uid,
          firstName,
          lastName,
          email,
        },
      });
      setIsLoading(false);
      setIsSuccess("data" in result);
    },
    [user, updateUserMutation]
  );

  return {
    updateUser,
    isLoading,
    isSuccess,
    error,
  };
}