import Box from "@mui/material/Box"
import Link from "@mui/material/Link"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow, { tableRowClasses } from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import { SxProps, Theme, styled } from "@mui/material/styles"
import { ReactElement, useEffect, useState } from "react"
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Fuse from "fuse.js"
import SearchField from "./SearchField"
import TableFooter from "@mui/material/TableFooter"
import TablePagination from "@mui/material/TablePagination"

export type SearchKey<T> = {
  name: string,
  weight?: number,
  value: (t: T) => any,
};

export enum SortOrder {
  Asc,
  Desc,
}

type Props<T> = {
  data: T[],
  columns: DataTableColumn<T>[],
  rowKey: (t: T) => any,
  rowsPerPage?: number,
  searchKeys?: SearchKey<T>[],
  defaultSortOrder?: SortOrder,
  defaultSortColumn?: string,
}

export type DataTableColumn<T> = {
  id: string,
  name?: string,
  sortable?: boolean,
  headerSx?: SxProps<Theme>;
  cellValue?: (t: T) => any,
  renderHeaderCell?: () => ReactElement,
  renderCell: (t: T) => ReactElement,
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#fafafa',
    flexGrow: "1",
    padding: 0,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`&.${tableRowClasses.head}`]: {
    boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.12)',
  },
  '&:hover': {
    backgroundColor: '#fafafa',
  },
  [`&.${tableRowClasses.footer}`]: {
    '&:last-child td': {
      borderBottom: 'none',
      backgroundColor: theme.palette.paper,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const DataTable = <T,>(props: Props<T>) => {
  const [sortBy, setSortBy] = useState<string | null>(props.defaultSortColumn ?? null);
  const [sortOrder, setSortOrder] = useState<SortOrder>(props.defaultSortOrder ?? SortOrder.Asc);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [page, setPage] = useState<number>(0);

  const [pageData, setPageData] = useState<T[]>([]);
  const [dataCount, setDataCount] = useState<number>(0);

  useEffect(
    () => {
      if (props.data.length === 0) {
        setPageData(props.data);
        setDataCount(0);
        return;
      }
      const sortedData = [...props.data];
      const sortColumn = props.columns.find((value) => value.id === sortBy);
      if (sortBy && sortColumn && sortColumn.cellValue) {
        sortedData.sort((a: T, b: T) => {
          const valueA = sortColumn.cellValue?.(a);
          const valueB = sortColumn.cellValue?.(b);

          if (valueA === undefined || valueB === undefined) {
            // Handle cases where the sortBy property is missing in some objects
            return 0;
          }
      
          let comparison = 0;
      
          if (valueA > valueB) {
            comparison = 1;
          } else if (valueA < valueB) {
            comparison = -1;
          }
      
          return sortOrder === SortOrder.Asc ? comparison : -comparison;
        });
      }

      let filteredData: T[];
      if (!searchValue || !props.searchKeys) {
        filteredData = [...sortedData];
      }
      else {
        const searchableData = sortedData.map((t) => {
          const searchValues: { [key: string]: any } = {};
          props.searchKeys?.forEach(searchKey => {
            searchValues[searchKey.name] = searchKey.value(t);
          });
          return searchValues;
        });
        const fuse = new Fuse(searchableData, {
          keys: props.searchKeys.map((sk) => ({
            name: sk.name,
            weight: sk.weight,
          })),
          threshold: 0.3,
        });
        const result = fuse.search(searchValue);
        filteredData = result
          .map((fr) => fr.refIndex)
          .map((index) => sortedData[index]);
      }

      let pagedData: T[] = [...filteredData];
      if (props.rowsPerPage && props.rowsPerPage < filteredData.length) {
        const start = page * props.rowsPerPage;
        pagedData = filteredData.slice(start, start + props.rowsPerPage);
      }

      setPageData(pagedData);
      setDataCount(filteredData.length);
    },
    [sortBy, sortOrder, searchValue, props.searchKeys, props.data, props.rowsPerPage, page, props.columns],
  );

  return (
    <Box>
      {props.searchKeys && <SearchField value={searchValue} onValueChanged={setSearchValue} />}
      {props.data.length > 0 && dataCount === 0 && searchValue &&
        <Typography m={2}>
          Nothing matching search term '{searchValue}'.
        </Typography>
      }
      {(props.data.length === 0 || pageData.length > 0) &&
        <Table sx={{
          '& th': {
            position: 'relative',
            '&:not(:last-child)::after': {
              content: "''",
              position: 'absolute',
              right: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              height: '50%',
              borderRight: '1px solid #f0f0f0',
              borderBottom: 'none',
            },
          },
        }}>
          <TableHead>
            <StyledTableRow>
              {props.columns.map((column) => <StyledTableCell
                key={column.id}
                sx={column.headerSx}
              >
                <Link
                  component="button"
                  sx={{
                    color: 'inherit',
                    textDecoration: 'inherit',
                    pointerEvents: !column.sortable && !searchValue ? 'none' : undefined,
                    width: '100%',
                    p: 2,
                  }}
                  onClick={() => {
                    if (column.sortable) {
                      if (sortBy === column.id) {
                        setSortOrder(sortOrder === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc);
                      }
                      else {
                        setSortBy(column.id);
                        setSortOrder(SortOrder.Asc);
                      }
                      setPage(0);
                    }
                  }}
                >
                  {column.renderHeaderCell
                    ? column.renderHeaderCell?.()
                    : <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        justifyItems="space-between"
                        
                      >
                        <Typography
                          fontWeight="500"
                          variant="body2"
                        >
                          {column.name}
                        </Typography>
                        <Box width={24} height={24}>
                          {!searchValue && sortBy === column.id && sortOrder === SortOrder.Asc &&
                            <ArrowDropUpIcon sx={{ opacity: 0.5 }} />
                          }
                          {!searchValue && sortBy === column.id && sortOrder === SortOrder.Desc &&
                            <ArrowDropDownIcon sx={{ opacity: 0.5 }} />
                          }
                        </Box>
                      </Box>
                  }
                </Link>
                </StyledTableCell>
              )}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {pageData.map((row) => (
              <StyledTableRow key={props.rowKey(row)}>
                {props.columns.map((column) => (
                  <TableCell key={column.id}>
                    {column.renderCell(row)}
                  </TableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
          <TableFooter>
            {props.rowsPerPage && props.rowsPerPage < dataCount &&
              <StyledTableRow>
                <TablePagination
                  count={dataCount}
                  rowsPerPage={props.rowsPerPage ?? dataCount}
                  page={page}
                  onPageChange={(_, page) => {
                    setPage(page);
                  }}
                  rowsPerPageOptions={[]}
                />
              </StyledTableRow>
            }
          </TableFooter>
        </Table>
      }
    </Box>
  )
}

export default DataTable