import Skeleton from "@mui/material/Skeleton"
import React from 'react'

type Props = {
  rows?: number,
  loading: boolean,
}

const TableLoading = (props: Props) => {
  return (
    <React.Fragment>
      {props.loading && [...Array(props.rows ?? 5)].map((_, i) => {
          return <Skeleton key={i} height={60} />
        })}
    </React.Fragment>
  )
}

export default TableLoading