import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import InputBase, { InputBaseProps } from "@mui/material/InputBase";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { TextFieldProps } from "@mui/material";
import React from "react";

export interface EditableTypographyProps {
  value?: string,
  editable?: boolean,
  onValueChange?: (value: string) => void;
  onCommit?: () => void,
  autoEdit?: boolean,
  autoSelectText?: boolean,
}

const InputBaseWithChildren = ({
  children,
  ...props
}: InputBaseProps & { children?: React.ReactNode }) => {
  let value = "";
  if (children) {
    if (typeof children == "string" || typeof children == "number") {
      value = children.toString();
    }
  }

  return (
    <InputBase
      {...props}
      className={""}
      value={value}
      inputProps={{ className: props.className }}
    />
  );
};

/**
 * Displaying like a `Typography`. But acting as an `input`
 */
const EditableTypography = ({
  onCommit,
  onValueChange,
  autoEdit,
  autoSelectText,
  editable = true,
  ...props
}: EditableTypographyProps & TypographyProps & Omit<TextFieldProps, "variant">) => {

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onValueChange?.(e.target.value);
  };

  const [isEditing, setIsEditing] = useState(false);
  const [editIconVisible, setEditIconVisible] = useState(false);

  useEffect(
    () => {
      setIsEditing(autoEdit ?? false);
    },
    [autoEdit]
  );

  const handleCommit = () => {
    setIsEditing(false);
    setEditIconVisible(false);
    onCommit?.();
  }

  return (
    <React.Fragment>
      {!isEditing &&
        <Box display="flex">
          <Box
            display="inline-flex"
            alignItems="center"
            gap={1}
            onClick={() => editable && setIsEditing(true)}
            onMouseEnter={() => editable && setEditIconVisible(true)}
            onMouseLeave={() => editable &&  setEditIconVisible(false)}
            p={1}
            sx={{
              ":hover": !editable
                ? undefined
                : {
                  backgroundColor: "white",
                  border: "1px solid #c4c4c4",
                  borderRadius: 1,
                },
              border: "1px solid transparent",
            }}
          >
            <Typography
              fontStyle={!props.value ? "italic" : undefined}
              color={!props.value && props.placeholder && editable ? "rgba(0, 0, 0, 0.4)" : undefined}
              {...props}
              onClick={() => editable && setIsEditing(true)}
            >
              {props.value
                ? props.value
                : editable
                  ? props.placeholder
                  : undefined
              }
            </Typography>
            {editIconVisible && <EditIcon sx={{ width: "16px" }} />}
          </Box>
        </Box>
      }
      {isEditing &&
        <Typography
          sx={{
            backgroundColor: "white",
            border: "1px solid #c4c4c4",
            borderRadius: 1,
            ...props.sx,
            p: 1,
          }}
          {...props}
          children={props.value}
          fullWidth
          autoFocus
          placeholder={props.placeholder}
          component={InputBaseWithChildren}
          className=""
          onChange={handleChange}
          onBlur={handleCommit}
          onFocus={(event) => {
            if (autoSelectText ?? true) {
              event.target.select();
            }
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter" || event.key === "Escape") {
              handleCommit();
            }
          }}
        />
      }
    </React.Fragment>
  );
};

export default EditableTypography;