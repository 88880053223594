import ValidateTextField from "../../../../../../components/ValidateTextField"
import { isInteger, isNumber, maximumNumberValue, minimumNumberValue } from "./fieldValidators"

type Props = {
  reps: number | null | undefined,
  onRepsChange: (reps: number | null) => void,
}

const RepsField = (props: Props) => {
  return (
    <ValidateTextField
      defaultValue={props.reps === null ? "" : props.reps}
      type="number"
      label="Reps"
      size="small"
      validators={[
        isNumber, minimumNumberValue(0), isInteger, maximumNumberValue(500),
      ]}
      onValidatedChange={(value) => {
        props.onRepsChange(value !== "" ? Number(value) : null)
      }}
      InputProps={{
        inputProps: {
          min: 1,
        }
      }}
    />
  )
}

export default RepsField