import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"

type Props = {
  rpe: number | null | undefined,
  onRpeChange: (rpe: number | null) => void,
}

const RpeField = (props: Props) => {
  return (
    <FormControl sx={{ minWidth: 120 }} size="small">
      <InputLabel id="rpeLabel">RPE</InputLabel>
      <Select
        defaultValue={props.rpe ?? undefined}
        variant="outlined"
        labelId="rpeLabel"
        label="RPE"
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: 175
            }
          }
        }}
        onChange={(event) => {
          props.onRpeChange((event.target.value && event.target.value !== "") ? Number(event.target.value) : null)
        }}
      >
        <MenuItem>
          <em>RPE</em>
        </MenuItem>
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={9.5}>9.5</MenuItem>
        <MenuItem value={9}>9</MenuItem>
        <MenuItem value={8.5}>8.5</MenuItem>
        <MenuItem value={8}>8</MenuItem>
        <MenuItem value={7.5}>7.5</MenuItem>
        <MenuItem value={7}>7</MenuItem>
        <MenuItem value={6.5}>6.5</MenuItem>
        <MenuItem value={6}>6</MenuItem>
        <MenuItem value={5.5}>5.5</MenuItem>
        <MenuItem value={5}>5</MenuItem>
        <MenuItem value={4.5}>4.5</MenuItem>
        <MenuItem value={4}>4</MenuItem>
        <MenuItem value={3.5}>3.5</MenuItem>
        <MenuItem value={3}>3</MenuItem>
        <MenuItem value={2.5}>2.5</MenuItem>
        <MenuItem value={2}>2</MenuItem>
        <MenuItem value={1.5}>1.5</MenuItem>
        <MenuItem value={1}>1</MenuItem>
        <MenuItem value={0.5}>0.5</MenuItem>
        <MenuItem value={0}>0</MenuItem>
      </Select>
    </FormControl>
  )
}

export default RpeField