import { useCallback, useState } from "react";
import { joinWaitlist as modelJoinWaitlist } from "../model/waitlist";

type WaitlistData = {
  role: string,
  name: string,
  email: string,
  instagram?: string,
  comments?: string,
  recaptchaToken: string,
}

export const useJoinWaitlist = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);

  const joinWaitlist = useCallback(
    async (data: WaitlistData): Promise<void> => {
      setIsSuccess(undefined);
      setIsLoading(true);
      setError(undefined);
      const result = await modelJoinWaitlist(data);
      setIsLoading(false);
      setIsSuccess(result.success);
      setError(result.error);
    },
    []
  );

  return {
    joinWaitlist,
    isLoading,
    isSuccess,
    error
  };
}