import { LoadingButton } from "@mui/lab";
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import { useCurrentUser } from "../../../../hooks/users/currentUserHook";
import { auth } from "../../../../config/firebase";
import TextFieldLoading from "../../../../components/TextFieldLoading";
import { useUpdatePassword } from "../../../../hooks/users/updatePasswordHook";
import { Typography } from "@mui/material";
import { useEffect } from "react";

type FormData = {
  currentPassword: string,
  newPassword: string,
  confirmNewPassword: string,
}

const EditPassword = () => {
  const { user, isLoading } = useCurrentUser(auth);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm<FormData>();

  const {
    updatePasword,
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccess,
    error: updateError
  } = useUpdatePassword(user);

  const onSubmit = (data: FormData) => {
    updatePasword(data.currentPassword, data.newPassword);
  }

  const currentPasswordHelperText = () => {
    const error = errors.currentPassword;
    if (!error) {
      return;
    }
    switch (error.type) {
      case "required":
        return "Current password is required.";
      default:
        return "";
    }
  }

  const newPasswordHelperText = () => {
    const error = errors.newPassword;
    if (!error) {
      return;
    }
    switch (error.type) {
      case "required":
        return "New password is required.";
      case "minLength":
        return "Password must be at least 6 characters";
      default:
        return "Not a valid password";
    }
  }

  const confirmNewPasswordHelperText = () => {
    const error = errors.confirmNewPassword;
    if (!error) {
      return;
    }
    switch (error.type) {
      case "required":
        return "Password confirmation is required";
      case "validate":
        return "Passwords do not match";
      default:
        return "Password confirmation is invalid";
    }
  }

  const hasFormErrors = errors.currentPassword || errors.newPassword || errors.confirmNewPassword;

  useEffect(() => {
    if (isUpdateSuccess) {
      reset();
    }
  }, [isUpdateSuccess, reset]);

  return (
    <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {(updateError || hasFormErrors) &&
            <Typography variant="body2" color="error" mb={2}>
              {updateError ? updateError : "Please make sure you've filled in all the fields correctly."}
            </Typography>
          }
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFieldLoading loading={isLoading}>
            <TextField
              margin="normal"
              type="password"
              fullWidth
              id="currentPassword"
              label="Current Password"
              {...register("currentPassword", {
                required: true,
              })}
              error={!!errors.currentPassword}
              helperText={currentPasswordHelperText()}
              disabled={isUpdateLoading}
            />
          </TextFieldLoading>

          <TextFieldLoading loading={isLoading}>
            <TextField
              margin="normal"
              type="password"
              fullWidth
              id="newPassword"
              label="New Password"
              {...register("newPassword", {
                required: true,
                minLength: 6,
              })}
              error={!!errors.newPassword}
              helperText={newPasswordHelperText()}
              disabled={isUpdateLoading}
            />
          </TextFieldLoading>

          <TextFieldLoading loading={isLoading}>
            <TextField
              margin="normal"
              type="password"
              fullWidth
              id="confirmNewPassword"
              label="Confirm New Password"
              {...register("confirmNewPassword", {
                required: true,
                validate: (val) => {
                  return val === watch("newPassword")
                }
              })}
              error={!!errors.confirmNewPassword}
              helperText={confirmNewPasswordHelperText()}
              disabled={isUpdateLoading}
            />
          </TextFieldLoading>

          <TextFieldLoading loading={isLoading}>
            <LoadingButton type="submit" variant="contained" sx={{ my: 2 }}>
              Save Changes
            </LoadingButton>
          </TextFieldLoading>

        </Grid>

        {isUpdateSuccess && 
          <Grid item xs={12}>
            <Typography variant="body2" color="success.main" my={1}>
              Changes saved successfully.
            </Typography>
          </Grid>
        }
      </Grid>
    </Box>
  )
}

export default EditPassword