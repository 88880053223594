import Typography from "@mui/material/Typography";
import { useCurrentUser } from "../../../../../hooks/users/currentUserHook";
import { auth } from "../../../../../config/firebase";
import { useSentInvitesQuery } from "../../../../../model/slices/clientsSlice";
import React, { useEffect, useState } from "react";
import ContentPaper from "../../../components/ContentPaper";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDeleteClientInvite } from "../../../../../hooks/clients/clientInviteHooks";
import Timestamp from "../../../../../components/Timestamp";
import { SentInviteData } from "../../../../../model/types";
import DataTable, { DataTableColumn, SortOrder } from "../../../components/DataTable";

const PendingClients = () => {
  const {userData} = useCurrentUser(auth);
  const {data: sentInvites} = useSentInvitesQuery(userData);
  const {
    deleteClientInvite,
    isSuccess: deleteClientSuccess,
    isLoading: deleteClientLoading,
    error: deleteClientError
  } = useDeleteClientInvite();
  const [anchorElOption, setAnchorElOption] = useState<null | HTMLElement>(null);
  const [activeOptionEmail, setActiveOptionEmail] = useState<string | undefined>();
  const [inviteToDelete, setInviteToDelete] = useState<SentInviteData | undefined>();
  const [deletePromptOpen, setDeletePromptOpen] = useState(false);

  const handleOpenOptionMenu = (event: React.MouseEvent<HTMLElement>, email: string) => {
    setActiveOptionEmail(email);
    setAnchorElOption(event.currentTarget);
  }
  
  const handleCloseUserMenu = () => {
    setAnchorElOption(null);
    setActiveOptionEmail(undefined);
  };

  const openDeletePrompt = (invite: SentInviteData) => {
    handleCloseUserMenu();
    setInviteToDelete(invite);
    setDeletePromptOpen(true);
  }

  const closeDeletePrompt = () => {
    setDeletePromptOpen(false);
  }

  const handleDeleteSubmit = async () => {
    if (inviteToDelete) {
      deleteClientInvite(inviteToDelete);
    }
  }

  useEffect(
    () => {
      if (deleteClientSuccess) {
        closeDeletePrompt();
      }
    },
    [deleteClientSuccess]
  );

  const columns: DataTableColumn<SentInviteData>[] = [
    {
      id: 'name',
      name: "Name",
      sortable: true,
      cellValue: (invite) => `${invite.firstName} ${invite.lastName}`,
      renderCell: (invite) => (
        <Typography>{invite.firstName} {invite.lastName}</Typography>
      ),
    },
    {
      id: 'email',
      name: "Email",
      sortable: true,
      cellValue: (invite) => invite.email,
      renderCell: (invite) => (
        <Typography>{invite.email}</Typography>
      ),
    },
    {
      id: 'sent',
      name: "Invite Sent",
      sortable: true,
      cellValue: (invite) => invite.timestamp,
      renderCell: (invite) => (
        <Timestamp variant="body1" timestamp={invite.timestamp} />
      ),
    },
    {
      id: 'options',
      renderHeaderCell: () => <></>,
      headerSx: {
        width: '1px',
        whiteSpace: 'nowrap'
      },
      renderCell: (invite) => (
        <React.Fragment>
          <IconButton onClick={(event) => handleOpenOptionMenu(event, invite.email)}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            sx={{ mt: "25px" }}
            anchorEl={anchorElOption}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={activeOptionEmail === invite.email}
            onClose={handleCloseUserMenu}
          >
            <MenuItem onClick={() => openDeletePrompt(invite)}>
              <Typography textAlign="center">Delete</Typography>
            </MenuItem>
          </Menu>
        </React.Fragment>
      ),
    },
  ];

  return (
    <React.Fragment>
      {sentInvites && sentInvites.length > 0 &&
        <React.Fragment>
          <Typography variant="h6" mb={2}>
            Pending
          </Typography>
          <ContentPaper sx={{ mb: 4 }}>
            <DataTable
              data={sentInvites}
              columns={columns}
              rowKey={({ email }) => email}
              defaultSortColumn='sent'
              defaultSortOrder={SortOrder.Desc}
              searchKeys={[
                {
                  name: 'fullName',
                  value: (invite) => `${invite.firstName} ${invite.lastName}`,
                },
                {
                  name: 'email',
                  value: (invite) => invite.email,
                },
              ]}
              rowsPerPage={10}
            />
          </ContentPaper>
        </React.Fragment>
      }
      <Dialog open={deletePromptOpen} maxWidth="sm" onClose={closeDeletePrompt}>
        <DialogTitle>Delete Invite</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            noValidate
            onSubmit={(event) => {
              event.preventDefault();
              handleDeleteSubmit();
            }}
          >
            {!!deleteClientError &&
              <Typography mb={2} color="error" variant="body2">
                There was a problem deleting the invitation.
              </Typography>
            }
            <Typography mb={2}>
              Delete invite to <Box component="span" fontWeight='fontWeightMedium'>{inviteToDelete?.firstName} {inviteToDelete?.lastName}</Box>? This action cannot be undone.
            </Typography>
            <Typography mb={2}>
              Note: Any emails sent to the recipient will remain sent.
            </Typography>

            <Grid container>
              <Grid item xs={12} mt={1}>
                <Box display="flex" gap={1} justifyContent="flex-end">
                  <Button onClick={closeDeletePrompt} disabled={deleteClientLoading}>
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={deleteClientLoading}
                    variant="contained"
                    type="submit"
                    color="error"
                  >
                    Delete
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}

export default PendingClients