import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { Timestamp, addDoc, collection, deleteDoc, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
import { Md5 } from "ts-md5";
import { ClientData, ClientMetadata, SentInviteData, UserData } from "../types";

type InviteClientArg = {
  userData: UserData,
  sentInviteData: SentInviteData,
}

export const clientsApi = createApi({
  reducerPath: "clients",
  baseQuery: fakeBaseQuery(),
  tagTypes: ["sentInvites", "clients", "client"],
  endpoints: (builder) => ({
    sentInvites: builder.query<SentInviteData[], UserData | undefined>({
      async queryFn(userData) {
        return await sentInvites(userData);
      },
      providesTags: ["sentInvites"],
    }),
    inviteClient: builder.mutation<SentInviteData, InviteClientArg>({
      async queryFn(arg) {
        return await inviteClient(arg);
      },
      invalidatesTags: ["sentInvites"],
    }),
    deleteClientInvite: builder.mutation<void, InviteClientArg>({
      async queryFn(arg) {
        return await deleteClientInvite(arg);
      },
      invalidatesTags: ["sentInvites"],
    }),
    getClientMetadatas: builder.query<ClientMetadata[], string | undefined>({
      async queryFn(userData) {
        return await getClientMetadatas(userData);
      },
      providesTags: ["clients"],
    }),
    removeClientFromCoach: builder.mutation<void, RemoveClientFromCoachArg>({
      async queryFn(arg) {
        return await removeClientFromCoach(arg);
      },
      invalidatesTags: ["clients"],
    }),
    getClient: builder.query<ClientData, GetClientArg>({
      async queryFn(arg) {
        return await getClient(arg);
      },
      providesTags: ["client"],
    }),
  }),
});

const sentInvites = async(userData: UserData | undefined): Promise<{ data: SentInviteData[] } | { error: any }> => {
  const sentInvites: SentInviteData[] = [];
  if (userData) {
    try {
      // First get any sent invites for users that haven't signed up yet (email invites).
      const sentEmailInvitesRef = collection(db, `users/${userData.uid}/sentEmailInvites`);
      const emailInviteDocs = await getDocs(sentEmailInvitesRef);
      emailInviteDocs.forEach((doc) => {
        sentInvites.push({
          firstName: doc.data().firstName,
          lastName: doc.data().lastName,
          email: doc.data().email,
          timestamp: (doc.data().timestamp as Timestamp).toDate(),
        });
      });

      // Now get any sent invites for existing users
      // (this could include backend conversions once a user has signed up)
      const sentInvitesRef = collection(db, `users/${userData.uid}/sentInvites`);
      const inviteDocs = await getDocs(sentInvitesRef);
      inviteDocs.forEach((doc) => {
        sentInvites.push({
          firstName: doc.data().firstName,
          lastName: doc.data().lastName,
          email: doc.data().email,
          timestamp: (doc.data().timestamp as Timestamp).toDate(),
        });
      });

      sentInvites.sort((a, b) => b.timestamp.valueOf() - a.timestamp.valueOf());
    }
    catch (error: any) {
      console.error(error.message);
      return {
        error: error.message,
      }
    }
  }
  return {
    data: sentInvites,
  }
}

const inviteClient = async({userData, sentInviteData}: InviteClientArg): Promise<{ data: SentInviteData } | { error: any }> => {
  const hashedEmail = Md5.hashStr(sentInviteData.email);

  await new Promise(r => setTimeout(r, 2000));

  try {
    const pendingRef = doc(db, `users/${userData.uid}/sentEmailInvites/${hashedEmail}`);
    await setDoc(pendingRef, sentInviteData);
  }
  catch (error: any) {
    console.error(error.message);
    return {
      error: error.message
    }
  }

  return {
    data: sentInviteData,
  }
}

const deleteClientInvite = async({userData, sentInviteData}: InviteClientArg): Promise<{ data: void } | { error: any }> => {
  const hashedEmail = Md5.hashStr(sentInviteData.email);

  await new Promise(r => setTimeout(r, 2000));

  try {
    const pendingRef = doc(db, `users/${userData.uid}/sentEmailInvites/${hashedEmail}`);
    await deleteDoc(pendingRef);
  }
  catch (error: any) {
    console.error(error.message);
    return {
      error: error.message
    }
  }

  return {
    data: undefined,
  }
}

type GetClientArg = {
  uid: string | undefined,
  clientId: string | undefined,
}
type GetClientReturnType = { data: ClientData } | { error: string };
const getClient = async (arg: GetClientArg): Promise<GetClientReturnType> => {
  if (!arg.uid) {
    return {
      error: "No current user id",
    }
  }

  if (!arg.clientId) {
    return {
      error: "No client ID was provided",
    }
  }

  await new Promise(r => setTimeout(r, 200));

  try {
    const clientRef = doc(db, `/users/${arg.clientId}`);
    const clientDoc = await getDoc(clientRef);
    if (clientDoc.exists() && clientDoc.data()) {
      return {
        data: {
          id: clientDoc.id,
          firstName: clientDoc.data().firstName,
          lastName: clientDoc.data().lastName,
        }
      }
    }
    else {
      console.error("client not found");
      return {
        error: "Client could not be found.",
      }
    }
  }
  catch (error: any) {
    console.error(error.message);
  }
  return {
    error: "An error occured",
  };
}

const getClientMetadatas = async(uid: string | undefined): Promise<{ data: ClientMetadata[] } | { error: any }> => {
  if (uid == null) {
    return {
      data: [],
    }
  }

  const clientData: ClientMetadata[] = [];
  try {
    const coachRef = doc(db, `users/${uid}`);
    const coachDoc = await getDoc(coachRef);
    if (coachDoc.exists() && coachDoc.data()) {
      if (coachDoc.data().clients) {
        for (const [clientId, data] of Object.entries(coachDoc.data().clients)) {
          const dataAny = data as any;
          clientData.push({
            id: clientId,
            firstName: dataAny.firstName,
            lastName: dataAny.lastName,
            clientSince: (dataAny.clientSince as Timestamp).toDate(),
          });
        }
      }
    }
  }
  catch (error: any) {
    console.error(error);
    return {
      error: error.message,
    }
  }

  return {
    data: clientData,
  }
}

type RemoveClientFromCoachArg = {
  user: UserData | undefined,
  clientId: string | undefined,
}
type RemoveClientFromCoachReturnType = { data: void } | { error: any };
const removeClientFromCoach = async (arg: RemoveClientFromCoachArg): Promise<RemoveClientFromCoachReturnType> => {
  if (arg.user == null) {
    return {
      data: undefined,
    }
  }

  await new Promise(r => setTimeout(r, 2000));

  try {
    const clientsToRemoveRef = collection(db, `users/${arg.user.uid}/clientsToRemove`);
    await addDoc(clientsToRemoveRef, {
      id: arg.clientId,
    });
  }
  catch (error: any) {
    console.error(error.message);
    return {
      error: error.message,
    }
  }

  return {
    data: undefined,
  }
}

export const {
  useInviteClientMutation,
  useDeleteClientInviteMutation,
  useSentInvitesQuery,
  useGetClientMetadatasQuery,
  useGetClientQuery,
  useRemoveClientFromCoachMutation,
} = clientsApi;