export const isNumber = (value: string) => isNaN(Number(value)) ? "Invalid" : null;

export const minimumNumberValue = (min: number) => {
  return (value: string) => {
    return Number(value) < min ? `Value too small` : null;
  }
}

export const maximumNumberValue = (max: number) => {
  return (value: string) => {
    return Number(value) > max ? `Value too large` : null;
  }
}

export const isInteger = (value: string) => !Number.isInteger(Number(value)) ? "Value cannot contain decimal places" : null;