import React from 'react'
import DashboardAppBar from "../../components/DashboardAppBar"
import Content from "../../components/Content"
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { RoutePath } from "../../../../components/SafeRouterLink";
import { Outlet } from "react-router-dom";

const navigation = [
  {
    title: "Profile Details",
    path: RoutePath.DashboardProfileDetails,
  },
  {
    title: "Change Password",
    path: RoutePath.DashboardProfilePassword,
  },
];

const Profile = () => {
  return (
    <React.Fragment>
      <DashboardAppBar title="Profile" navigation={navigation} />
      <Content>
        <Paper elevation={2} sx={{ p: 3 }}>
          <Grid container>
            <Grid item sm={12} md={9} lg={7}>
              <Outlet />
            </Grid>
          </Grid>
        </Paper>
      </Content>
    </React.Fragment>
  )
}

export default Profile