import Skeleton, { SkeletonProps } from "@mui/material/Skeleton"
import React from 'react'

interface Props extends SkeletonProps {
  loading: boolean,
}

const LoadingSkeleton = ({loading, ...otherProps}: Props) => {
  return (
    <React.Fragment>
      {loading ? <Skeleton {...otherProps} /> : otherProps.children}
    </React.Fragment>
  )
}

export default LoadingSkeleton