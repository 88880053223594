import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../config/firebase";
import { useEffect, useState } from "react";
import { useGetClientProgramsQuery } from "../../model/slices/programsSlice";

type Props = {
  clientId: string | undefined,
  draft: boolean,
}

export const useGetClientPrograms = (props: Props) => {
  const [user] = useAuthState(auth);
  const {data, isFetching, error} = useGetClientProgramsQuery({
    coachId: user?.uid,
    clientId: props.clientId,
    draft: props.draft,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(
    () => {
      setIsLoading(!user || !props.clientId || isFetching);
    },
    [user, isFetching, props.clientId]
  );

  return {
    data,
    isLoading,
    error,
  }
}