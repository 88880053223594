import { useCallback, useState } from "react";
import { saveMessage } from "../model/contact";

type ContactFormData = {
  name: string,
  email: string,
  message: string,
  recaptchaToken: string,
}

export const useSendContactFormMessage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);

  const sendContactFormMessage = useCallback(
    async (data: ContactFormData): Promise<void> => {
      setIsSuccess(undefined);
      setIsLoading(true);
      setError(undefined);
      const result = await saveMessage(data);
      setIsLoading(false);
      setIsSuccess(result.success);
      setError(result.error);
    },
    []
  );

  return {
    sendContactFormMessage,
    isLoading,
    isSuccess,
    error
  };
}