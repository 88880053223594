import Box from "@mui/material/Box"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import SafeRouterLink, { RoutePath, SafeRoutePath } from "../../../../../../components/SafeRouterLink"
import { useGetProgramTemplates } from "../../../../../../hooks/programs/getProgramTemplateHooks"
import { Program } from "../../../../../../model/types"
import Link from "@mui/material/Link"
import Stack from "@mui/material/Stack"
import Timestamp from "../../../../../../components/Timestamp"
import React from "react"
import ContentPaper from "../../../../components/ContentPaper"
import { useCreateProgramFromTemplate } from "../../../../../../hooks/programs/createProgramFromTemplateHook"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"
import TableLoading from "../../../../components/TableLoading"
import DataTable, { DataTableColumn, SortOrder } from "../../../../components/DataTable"

type Props = {
  clientId: string,
  open: boolean,
  onClose: (() => void) | undefined
}

const TemplatesDialog = (props: Props) => {
  const {
    createProgramFromTemplate,
    isLoading: createLoading,
    error: createError
  } = useCreateProgramFromTemplate();
  const { data: programTemplates, isLoading: templatesLoading } = useGetProgramTemplates();

  const [newProgramId, setNewProgramId] = useState<string>();

  const navigate = useNavigate();
  useEffect(
    () => {
      if (newProgramId) {
        props.onClose?.();
        navigate(SafeRoutePath.ResolvedClientProgram(props.clientId, newProgramId).path)
      }
    },
    [newProgramId, props, navigate]
  );

  const handleClickTemplate = async (templateId: string) => {
    const program = await createProgramFromTemplate({clientId: props.clientId, templateId});
    if (program) {
      setNewProgramId(program.id);
    }
  }

  const columns: DataTableColumn<Program>[] = [
    {
      id: 'program',
      name: "Program",
      sortable: true,
      cellValue: (program) => program.name,
      renderCell: (program) => (
        <Link
          component="button"
          sx={{
            textDecoration: "none",
          }}
          onClick={() => handleClickTemplate(program.id)}
          width="100%"
        >
          <Stack alignItems="flex-start">
            <Typography variant="body2" fontWeight="bold">
              {program.name}
            </Typography>
            {program.description && <Typography mt={1} variant="body2" color="black">{program.description}</Typography>}
          </Stack>
        </Link>
      ),
    },
    {
      id: 'created',
      name: "Created",
      sortable: true,
      cellValue: (program) => program.created,
      renderCell: (program) => {
        return program.created ? <Timestamp timestamp={program.created} /> : <React.Fragment />
      },
    },
    {
      id: 'updated',
      name: "Updated",
      sortable: true,
      cellValue: (program) => program.created,
      renderCell: (program) => {
        return program.modified ? <Timestamp timestamp={program.modified} /> : <React.Fragment />
      },
    },
  ];

  return (
    <Dialog
      fullWidth
      open={props.open}
      onClose={props.onClose}
      maxWidth="md"
      disableRestoreFocus
    >
      <DialogTitle>Create Program from Template</DialogTitle>
      <DialogContent>
       <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={createLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box>
          {!!createError &&
            <Typography mb={2} variant="body2" color="error">
              {createError}
            </Typography>
          }

          <Typography mb={2}>
            Select a template below to get started.
          </Typography>

          <Typography mb={2}>
            The program will remain in draft until you decide to send it.
          </Typography>

          <ContentPaper sx={{ mx: 2, my: 3 }}>
            {templatesLoading && <TableLoading loading={true} />}
            {!templatesLoading && programTemplates && programTemplates.length > 0 &&
              <DataTable
                data={programTemplates}
                columns={columns}
                rowKey={({ id }) => id}
                searchKeys={[
                  { name: 'name', value: (program) => program.name, weight: 0.7 }, // Prioritize 'name' with a higher weight
                  { name: 'description', value: (program) => program.description, weight: 0.3 }, // Lower weight for 'description'
                ]}
                defaultSortColumn='updated'
                defaultSortOrder={SortOrder.Desc}
                rowsPerPage={5}
              />
            }
            {!templatesLoading && (!programTemplates || programTemplates.length === 0) &&
              <Grid item xs={6}>
                <Typography align="center" mt={1} mb={2}>
                  You have not yet saved any <Link component={SafeRouterLink} sx={{ verticalAlign: "baseline" }} to={RoutePath.DashboardPrograms}>program templates.</Link>
                </Typography>
              </Grid>
            }
          </ContentPaper>

          <Grid container spacing={1}>
            <Grid item xs={12} mt={1}>
              <Box display="flex" gap={1} justifyContent="flex-end">
                <Button
                  disabled={createLoading}
                  onClick={props.onClose}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog >
  )
}

export default TemplatesDialog