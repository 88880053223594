import TextField, { TextFieldProps } from "@mui/material/TextField"
import { useRef, useState } from "react"

type ValidateTextFieldProps = TextFieldProps & {
  showHelperText?: boolean
  validators?: ((text: string) => string | null)[],
  onValidatedChange?: (text: string) => void,
}

const ValidateTextField = (props: ValidateTextFieldProps) => {
  const [error, setError] = useState<string>();

  const handleChange = (value: string) => {
    for (const validator of props.validators ?? []) {
      const validatorError = validator(value);
      if (validatorError) {
        setError(validatorError);
        return;
      }
    }
    setError(undefined);
    props.onValidatedChange?.(value);
  }

  const {
    showHelperText: _,
    onValidatedChange: __,
    validators: ___,
    ...textFieldProps
  } = props;

  const muiInput = useRef<HTMLInputElement>(null);

  return (
    <TextField
      error={!!error}
      helperText={props.showHelperText && error}
      onChange={(event) => handleChange(event.currentTarget.value)}
      {...textFieldProps}
      ref={muiInput}
      inputRef={muiInput}
    />
  )
}

export default ValidateTextField