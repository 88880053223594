import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../config/firebase";
import { useEffect, useState } from "react";
import { useGetClientQuery, useGetClientMetadatasQuery } from "../../model/slices/clientsSlice";

export const useGetClientMetadatas = () => {
  const [user] = useAuthState(auth);
  const {data, isLoading: clientsLoading, error} = useGetClientMetadatasQuery(user?.uid);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(
    () => {
      setIsLoading(!user || clientsLoading);
    },
    [user, clientsLoading]
  );

  return {
    data,
    isLoading,
    error,
  }
}

export const useGetClient = (clientId: string | undefined) => {
  const [user] = useAuthState(auth);
  const {data, isLoading: queryLoading, error} = useGetClientQuery({
    uid: user?.uid,
    clientId,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(
    () => {
      setIsLoading(!user || queryLoading);
    },
    [user, queryLoading]
  );

  return {
    data,
    isLoading,
    error: error as string | undefined,
  }
}