import React from 'react'
import SignIn from "../SignIn"
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../config/firebase";
import Page from "../../components/Page";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import Navigator from "./Navigator";
import Copyright from "../../components/Copyright";
import { ThemeProvider } from "@mui/material/styles";
import Header from "./Header";
import dashboardTheme from "../../theme/dashboard";
import { theme } from "../../theme";
import { Outlet } from "react-router-dom";
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount";

const drawerWidth = 256;

const Dashboard = () => {
  const [user, loading] = useAuthState(auth);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (!user && !loading) {
    return <Page showDashboardButton={false}>
      <SignIn />
    </Page>
  }

  const sideDrawer = <Box
    component="nav"
    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
  >
    {isSmUp ? null : (
      <Navigator
        PaperProps={{ style: { width: drawerWidth } }}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
      />
    )}
    <Navigator
      PaperProps={{ style: { width: drawerWidth } }}
      sx={{ display: { sm: 'block', xs: 'none' } }}
    />
  </Box>

  return (
    <ThemeProvider theme={dashboardTheme}>
      <ScrollToTopOnMount />
      <Box sx={{ display: "flex", minHeight: "100vh" }}>

        {sideDrawer}

        <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <Header onDrawerToggle={handleDrawerToggle} />

          <Outlet />

          <Box component="footer" sx={{ p: 2, pt: 3, bgcolor: theme.palette.paper, textAlign: "center" }}>
            <Copyright />
          </Box>
        </Box>

      </Box>
    </ThemeProvider>
  )
}

export default Dashboard
