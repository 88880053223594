import { doc, getDoc } from "firebase/firestore";
import { Program, WorkoutMetadata } from "../types";
import { db } from "../../config/firebase";

export type GetProgramTemplateArg = {
  uid: string | undefined,
  programId: string | undefined,
}
export type GetProgramTemplateReturnType = { data: Program } | { error: any };
export const getProgramTemplate = async (programId?: string): Promise<GetProgramTemplateReturnType> => {
  if (!programId) {
    return {
      error: "No program id",
    }
  }

  try {
    const docRef = doc(db, `programTemplates/${programId}`);
    const snapshot = await getDoc(docRef);
    const data = snapshot.data();
    if (!data) {
      return {
        error: `Couldn't find program template with ID ${programId}`,
      }
    }

    const workouts: WorkoutMetadata[] = [];
    if (data.workouts && Array.isArray(data.workouts)) {
      for (const metadata of data.workouts) {
        workouts.push({
          id: metadata.id,
          name: metadata.name,
          ...(metadata.description && { description: metadata.description }),
          ...(metadata.exercises && { exercises: metadata.exercises }),
        });
      }
    }

    return {
      data: {
        id: programId,
        name: data.name,
        description: data.description,
        created: new Date(Date.parse(data.created)),
        ...(data.modified && { modified: new Date(Date.parse(data.modified)) }),
        ...(workouts.length > 0 && { workouts: workouts }),
      },
    }
  }
  catch (error: any) {
    console.error(`${programId} - ${error.message}`);
    return {
      error: error.message,
    }
  }
}