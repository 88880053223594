import Box from "@mui/material/Box"
import { theme } from "../../../../../../theme"
import Stack from "@mui/material/Stack"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import LibraryBooks from "@mui/icons-material/LibraryBooks"
import BrowseGallery from "@mui/icons-material/BrowseGallery"
import Add from "@mui/icons-material/Add"
import NewProgramCard from "../NewProgramCard"
import LoadingSkeleton from "../../../../../../components/LoadingSkeleton"
import { useClientContext } from ".."
import ProgramsTable from "./ProgramsTable"
import BlankProgramDialog from "./BlankProgramDialog"
import React, { useEffect, useState } from "react"
import TemplatesDialog from "./TemplatesDialog"
import { Program } from "../../../../../../model/types"
import DeleteProgramDialog from "./DeleteProgramDialog"
import { useGetClientPrograms } from "../../../../../../hooks/programs/getClientProgramHooks"
import TableLoading from "../../../../components/TableLoading"
import ContentPaper from "../../../../components/ContentPaper"
import HistoryDialog from "./HistoryDialog"
import { SafeRoutePath } from "../../../../../../components/SafeRouterLink"
import { useNavigate } from "react-router-dom"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import SaveProgramAsTemplateDialog from "./SaveProgramAsTemplateDialog"
import ProgramFromProgramPrompt from "./ProgramFromProgramPrompt"
import SendProgramDialog from "./SendProgramDialog"

const ClientPrograms = () => {
  const navigate = useNavigate();
  const {client} = useClientContext();
  const {data: draftPrograms, isLoading: draftProgramsLoading} = useGetClientPrograms({clientId: client?.id, draft: true});
  const {data: programs, isLoading: programsLoading} = useGetClientPrograms({clientId: client?.id, draft: false});

  const [blankProgramDialogOpen, setBlankProgramDialogOpen] = useState(false);
  const [templatesDialogOpen, setTemplatesDialogOpen] = useState(false);
  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);

  const [programShowingOptions, setProgramShowingOptions] = useState<Program | null>(null);
  const [optionMenuAnchorEl, setOptionMenuAnchorEl] = useState<HTMLElement | null>(null);
  const handleOpenOptionMenu = (event: React.MouseEvent<HTMLElement>, program: Program) => {
    setOptionMenuAnchorEl(event.currentTarget);
    setProgramShowingOptions(program);
  }
  const handleCloseOptionMenu = () => {
    setProgramShowingOptions(null);
    setOptionMenuAnchorEl(null);
  }

  const [deletedProgramIds, setDeletedProgramIds] = useState(new Set<string>());
  const [deletePromptOpen, setDeletePromptOpen] = useState(false);
  const [programToDelete, setProgramToDelete] = useState<Program | null>(null);
  const closeDeletePrompt = async (wasDeleted: boolean) => {
    if (wasDeleted) {
      setDeletedProgramIds((deletedProgramIds) => {
        if (programToDelete) {
          deletedProgramIds.add(programToDelete.id);
        }
        return deletedProgramIds;
      });
    }
    setDeletePromptOpen(false);
    setProgramToDelete(null);
  }
  useEffect(
    () => {
      setDeletePromptOpen(!!programToDelete);
    },
    [programToDelete],
  );

  const [saveAsTemplateDialogOpen, setSaveAsTemplateDialogOpen] = useState(false);
  const [programToSaveAsTemplate, setProgramToSaveAsTemplate] = useState<Program | null>(null);
  const closeSaveAsTemplateDialog = async () => {
    setSaveAsTemplateDialogOpen(false);
    setProgramToSaveAsTemplate(null);
  }
  useEffect(
    () => {
      setSaveAsTemplateDialogOpen(!!programToSaveAsTemplate);
    },
    [programToSaveAsTemplate],
  );

  const [programFromProgramPromptOpen, setProgramFromProgramPromptOpen] = useState(false);
  const [programToCreateProgramFrom, setProgramToCreateProgramFrom] = useState<Program | null>(null);
  const closeProgramFromProgramPrompt = async () => {
    setProgramFromProgramPromptOpen(false);
    setProgramToCreateProgramFrom(null);
  }
  useEffect(
    () => {
      setProgramFromProgramPromptOpen(!!programToCreateProgramFrom);
    },
    [programToCreateProgramFrom],
  );
  
  const [sendProgramPromptOpen, setSendProgramPromptOpen] = useState(false);
  const [programToSend, setProgramToSend] = useState<Program | null>(null);
  const closeSendProgramPrompt = async () => {
    setSendProgramPromptOpen(false);
    setProgramToSend(null);
  }
  useEffect(
    () => {
      setSendProgramPromptOpen(!!programToSend);
    },
    [programToSend],
  );

  return (
    <Stack>
      <Box sx={{ background: theme.palette.primary.veryLight }} p={4} mb={4}>
        <Container maxWidth="sm">
          <LoadingSkeleton loading={programsLoading} width="100%">
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12}>
                <Typography variant="subtitle2" align="center" px={4}>
                  Create a new program
                </Typography>
              </Grid>
              <Grid item xs={3.5}>
                <NewProgramCard
                  text="Blank"
                  onClick={() => setBlankProgramDialogOpen(true)}
                >
                  <Add fontSize="large" color="primary" />
                </NewProgramCard>
              </Grid>
              <Grid item xs={3.5}>
                <NewProgramCard
                  text="Templates"
                  onClick={() => setTemplatesDialogOpen(true)}
                >
                  <LibraryBooks fontSize="large" color="primary" />
                </NewProgramCard>
              </Grid>
              {programs && programs.length > 0 &&
                <Grid item xs={3.5}>
                  <NewProgramCard
                    text="History"
                    onClick={() => setHistoryDialogOpen(true)}
                  >
                    <BrowseGallery fontSize="large" color="primary" />
                  </NewProgramCard>
                </Grid>
              }
            </Grid>
          </LoadingSkeleton>
        </Container>
      </Box>

      {(draftProgramsLoading || draftPrograms) && 
        <Box px={4}>
          <TableLoading rows={4} loading={draftProgramsLoading} />
          {!draftProgramsLoading && draftPrograms && draftPrograms.length > 0 && client &&
            <React.Fragment>
              <Typography variant="h6" mb={2}>
                Draft
              </Typography>
              <ProgramsTable
                draft={true}
                programs={draftPrograms.filter((p) => !deletedProgramIds.has(p.id))}
                client={client}
                onOptionsClicked={handleOpenOptionMenu}
                onProgramClicked={(program) => {
                  navigate(SafeRoutePath.ResolvedClientProgram(client.id, program.id).path)
                }}
                rowsPerPage={5}
              />
            </React.Fragment>
          }
        </Box>
      }

      {(programsLoading || programs) && 
        <Box px={4}>
          <TableLoading rows={4} loading={programsLoading} />
          {!programsLoading && client &&
            <React.Fragment>
              <Typography variant="h6" mb={2}>
                Programs
              </Typography>
              {programs && programs.length > 0
                ? <ProgramsTable
                    draft={false}
                    programs={programs.filter((p) => !deletedProgramIds.has(p.id))}
                    client={client}
                    onOptionsClicked={handleOpenOptionMenu}
                    onProgramClicked={(program) => {
                      navigate(SafeRoutePath.ResolvedClientProgram(client.id, program.id).path)
                    }}
                  />
                : <ContentPaper>
                  <Typography m={2}>
                    <strong>{client.firstName} {client.lastName}</strong> does not yet have any active or completed programs.
                  </Typography>
                  <Typography m={2} mt={3}>
                    Use the options above to get started with a new program.
                  </Typography>
                </ContentPaper>
              }
            </React.Fragment>
          }
        </Box>
      }

      {programShowingOptions &&
        <Menu
          sx={{ mt: "25px" }}
          anchorEl={optionMenuAnchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={true}
          onClose={handleCloseOptionMenu}
        >
          {!programShowingOptions.sent &&
            <MenuItem onClick={() => {
              const program = programShowingOptions;
              handleCloseOptionMenu();
              setProgramToSend(program);
            }}>
              <Typography textAlign="center">Send</Typography>
            </MenuItem>
          }
          <MenuItem onClick={() => {
            const program = programShowingOptions;
            handleCloseOptionMenu();
            setProgramToSaveAsTemplate(program);
          }}>
            <Typography textAlign="center">Save to program templates</Typography>
          </MenuItem>
          {programShowingOptions.sent && 
            <MenuItem onClick={() => {
              const program = programShowingOptions;
              handleCloseOptionMenu();
              setProgramToCreateProgramFrom(program);
            }}>
              <Typography textAlign="center">Copy as new program</Typography>
            </MenuItem>
          }
          <MenuItem onClick={() => {
            const programToDelete = programShowingOptions;
            handleCloseOptionMenu();
            setProgramToDelete(programToDelete);
          }}>
            <Typography textAlign="center">Delete</Typography>
          </MenuItem>
        </Menu>
      }

      {programToSaveAsTemplate && <SaveProgramAsTemplateDialog
        program={programToSaveAsTemplate}
        open={saveAsTemplateDialogOpen}
        onClose={() => closeSaveAsTemplateDialog()}
      />}

      {client && programToSend && <SendProgramDialog
        client={client}
        program={programToSend}
        open={sendProgramPromptOpen}
        onClose={() => closeSendProgramPrompt()}
      />}

      {client?.id && <BlankProgramDialog
        clientId={client.id}
        open={blankProgramDialogOpen}
        onClose={() => setBlankProgramDialogOpen(false)}
      />}

      {client?.id && <TemplatesDialog
        clientId={client.id}
        open={templatesDialogOpen}
        onClose={() => setTemplatesDialogOpen(false)}
      />}

      {client && <HistoryDialog
        client={client}
        open={historyDialogOpen}
        onClose={() => setHistoryDialogOpen(false)}
      />}

      {client && programToDelete && <DeleteProgramDialog
        client={client}
        program={programToDelete}
        open={deletePromptOpen}
        onClose={closeDeletePrompt}
      />}

      {client && programToCreateProgramFrom && <ProgramFromProgramPrompt
        client={client}
        program={programToCreateProgramFrom}
        open={programFromProgramPromptOpen}
        onClose={closeProgramFromProgramPrompt}
      />}
    </Stack>
  )
}

export default ClientPrograms