import Breadcrumbs from "@mui/material/Breadcrumbs"
import Link from "@mui/material/Link"
import SafeRouterLink, { RoutePath } from "../../../../components/SafeRouterLink"
import Typography from "@mui/material/Typography"
import LoadingSkeleton from "../../../../components/LoadingSkeleton"
import { Program } from "../../../../model/types"

type Props = {
  template?: Program,
  isLoading?: boolean
}

const BuilderBreadcrumbs = (props: Props) => {
  return (
    <Breadcrumbs separator="›" sx={{ mb: 2, mx: 1 }}>
      {[
        <Link key="0" underline="hover" component={SafeRouterLink} to={RoutePath.DashboardPrograms}>
          <Typography>Programs</Typography>
        </Link>,
        <Typography key="1">
          <LoadingSkeleton width="100px" loading={props.isLoading ?? false}>
            {props.template?.name}
          </LoadingSkeleton>
        </Typography>
      ]}
    </Breadcrumbs>
  )
}

export default BuilderBreadcrumbs