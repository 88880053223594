import React from 'react'
import { Navigate, useParams } from "react-router-dom"
import { SafeRoutePath } from "../../../../../components/SafeRouterLink"

const NavigateToSummary = () => {
  const { clientId } = useParams();

  return (
    clientId
      ? <Navigate to={SafeRoutePath.ResolvedClientSummary(clientId).path} replace />
      : <React.Fragment />
  )
}

export default NavigateToSummary