import Box from "@mui/material/Box";
import logo from "./images/logo.svg";

const LogoText = () => {
  return (
    <Box display="flex" alignItems="center">
      <img src={logo} height="25" alt="Spotter - Elevate your coaching journey" />
    </Box>
  )
}

export default LogoText