import Breadcrumbs from "@mui/material/Breadcrumbs"
import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"
import SafeRouterLink, { RoutePath, SafeRoutePath } from "../../../../../../components/SafeRouterLink"
import { useClientContext } from ".."
import Content from "../../../../components/Content"
import LoadingSkeleton from "../../../../../../components/LoadingSkeleton"
import { programsApi, useGetProgramQuery } from "../../../../../../model/slices/programsSlice"
import { useParams } from "react-router-dom"
import { useAuthState } from "react-firebase-hooks/auth"
import { auth } from "../../../../../../config/firebase"
import { ProgramRepository } from "../../../../../../model/repositories/programRepository"
import { ExerciseSet, ExerciseWithSets, Program, Workout } from "../../../../../../model/types"
import { store } from "../../../../../../model/store"
import { WorkoutRepository } from "../../../../../../model/repositories/workoutRepository"
import ProgramBuilder, { Editability } from "../../../../components/ProgramBuilder"
import WorkoutBuilder from "../../../../components/ProgramBuilder/WorkoutBuilder"
import { ReactNode, useState } from "react"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ReactTimeago from "react-timeago"
import TimeagoTooltip from "../../../../components/TimeagoTooltip"
import Timestamp from "../../../../../../components/Timestamp"
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import AlertBox, { AlertType } from "../../../../components/AlertBox"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ClientWorkoutDialogContents from "./ClientWorkoutDialogContents"

const ClientProgram = () => {
  const {programId} = useParams();
  const [user] = useAuthState(auth);
  const {client, isLoading: clientLoading} = useClientContext();
  const {data: program, isLoading: programLoading, error: getProgramError} = useGetProgramQuery(programId);

  const isLoading = !user || clientLoading || programLoading;

  const programRepository: ProgramRepository = {
    getProgram: async (programId: string): Promise<Program | string> => {
      const response = await store.dispatch(programsApi.endpoints.getProgram.initiate(programId));
      return response.data ?? response.error as string;
    },
    saveProgram: async (program: Program): Promise<string | null> => {
      const response = await store.dispatch(programsApi.endpoints.setProgram.initiate(program));
      if ("error" in response) {
        return response.error as string;
      }
      return null;
    },
  };

  const workoutRepository: WorkoutRepository = {
    getWorkout: async (workoutId: string): Promise<Workout<ExerciseWithSets<ExerciseSet>> | string> => {
      const response = await store.dispatch(programsApi.endpoints.getWorkout.initiate(workoutId));
      return response.data ?? response.error as string;
    },
    deleteWorkout: async (workoutId: string, programId?: string): Promise<string | null> => {
      const response = await store.dispatch(programsApi.endpoints.deleteWorkout.initiate({
        workoutId,
        programId,
      }));
      return "error" in response ? response.error as string : null;
    },
    saveWorkout: async (workout: Workout<ExerciseWithSets<ExerciseSet>>): Promise<string | null> => {
      const response = await store.dispatch(programsApi.endpoints.setWorkout.initiate(workout));
      if ("error" in response) {
        return response.error as string;
      }
      return null;
    },
  };

  const statusBox = (): ReactNode | undefined => {
    if (!program || !client) {
      return undefined;
    }

    var alertType: AlertType;
    var date: Date;
    var icon: ReactNode;
    var text: string;
    
    if (program.finished) {
      alertType = AlertType.Success;
      date = program.finished;
      icon = <CheckCircleIcon color="success" />
      text = `${client.firstName} ${client.lastName} finished the program.`;
    }
    else if (program.started) {
      alertType = AlertType.Information;
      date = program.started;
      icon = <AccessTimeIcon color="primary" />
      text = `${client.firstName} ${client.lastName} started the program.`;
    }
    else if (program.sent) {
      alertType = AlertType.Information;
      date = program.sent;
      icon = <MobileFriendlyIcon color="inherit" sx={{ opacity: 0.4 }} />;
      text = `Program was sent to ${client.firstName} ${client.lastName}.`;
    }
    else {
      return <AlertBox
        type={AlertType.Information}
        py={2}
        px={3}
        mb={1}
      >
        Program is currently in draft.
      </AlertBox>
    }

    return <AlertBox
      type={alertType}
      py={2}
      px={3}
      mb={1}
    >
      <Stack gap={1}>
        <Timestamp timestamp={date} />
          <Stack direction="row" gap={1} alignItems="center">
            {icon}
            <Typography>{text}</Typography>
          </Stack>
      </Stack>
    </AlertBox>
  }

  const programEditability = (): Editability => {
    if (!program) {
      return Editability.Never;
    }
    if (!program.sent) {
      return Editability.Always;
    }
    return Editability.OnDemandDefaultUneditable;
  }

  return (
    <Content>
      <LoadingSkeleton height="30px" width="150px" loading={isLoading}>
        <Breadcrumbs separator="›" sx={{ mb: 2, mx: 1 }}>
          {[
            <Link key="0" underline="hover" component={SafeRouterLink} to={RoutePath.DashboardClients}>
              <Typography>Clients</Typography>
            </Link>,
            ...client ? [
              <Link key="1" underline="hover" component={SafeRouterLink} to={SafeRoutePath.ResolvedClient(client.id)}>
                <Typography>{client?.firstName} {client?.lastName}</Typography>
              </Link>,
              <Link key="2" underline="hover" component={SafeRouterLink} to={SafeRoutePath.ResolvedClientPrograms(client.id)}>
                <Typography>Programs</Typography>
              </Link>,
            ] : [],
            ...program ? [
              <Typography key={program.name}>{program.name}</Typography>
            ] : [],
          ]}
        </Breadcrumbs>
      </LoadingSkeleton>

      {!!getProgramError && <Typography color="error">
        Unable to load program.
      </Typography>}

      {!getProgramError && programId && client &&
        <ProgramBuilder
          programId={programId}
          programRepository={programRepository}
          workoutRepository={workoutRepository}
          editability={programEditability()}
          workoutBuilderContents={(workoutBuilderProps) =>
            <ClientWorkoutDialogContents
              workoutId={workoutBuilderProps.workoutId}
              program={workoutBuilderProps.program}
              workoutRepository={workoutBuilderProps.workoutRepository}
              onWorkoutSaved={workoutBuilderProps.onWorkoutSaved}
              client={client}
            />
          }
          additionalHeader={statusBox()}
        />
      }
    </Content>
  )
}

export default ClientProgram