import Card from "@mui/material/Card"
import CardActionArea from "@mui/material/CardActionArea"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

type Props = {
  text: string,
  children: React.ReactNode,
  onClick?: () => void,
}

const NewProgramCard = (props: Props) => {
  return (
    <Stack gap={1}>
      <Card
        elevation={2}
        sx={{
          aspectRatio: 1.3,
        }}
      >
        <CardActionArea
          sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100%",
          }}
          onClick={props.onClick}
        >
          {props.children}
        </CardActionArea>
      </Card>
      <Typography variant="body2" px={1}>
        {props.text}
      </Typography>
    </Stack>
  )
}

export default NewProgramCard