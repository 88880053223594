import Box from "@mui/material/Box"
import { useEffect, useState } from 'react'
import { theme } from "../../../theme"
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

export enum SavingToastStatus {
  Saving,
  Saved,
  Error,
};

type Props = {
  status: SavingToastStatus,
  position: 'fixed' | 'absolute',
}

const SavingToast = (props: Props) => {
  const [visible, setVisible] = useState(false);

  useEffect(
    () => {
      if (props.status === SavingToastStatus.Saving) {
        setVisible(true);
      } else {
        const timeout = setTimeout(() => {
          setVisible(false);
        }, 2000);
  
        return () => clearTimeout(timeout);
      }
    },
    [props.status],
  );

  return (
    <Box sx={{
      position: props.position ?? 'fixed',
      bottom: 0,
      right: 0,
      zIndex: 1,
      background: theme.palette.primary.veryDark,
      color: 'white',
      opacity: visible ? 1 : 0,
      transition: 'opacity 0.2s ease-in-out',
    }}
      borderRadius={1.5}
      padding={1}
      margin={2}
    >
      <Box display="flex" alignItems="center" gap={1}>
        <Typography variant="body2">{
          props.status === SavingToastStatus.Saving
            ? "Saving"
            : props.status === SavingToastStatus.Error
              ? "Error"
              : "Saved"
          }
        </Typography>
        {props.status === SavingToastStatus.Saving && <CircularProgress size={16} />}
        {props.status === SavingToastStatus.Saved && <CheckCircleIcon sx={{ width: 18, height: 18 }} color="primary" />}
        {props.status === SavingToastStatus.Error && <ErrorIcon sx={{ width: 18, height: 18 }} color="error" />}
      </Box>
    </Box>
  )
}

export default SavingToast