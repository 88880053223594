import Tooltip from "@mui/material/Tooltip"
import React from 'react'
import ReactTimeago from "react-timeago"

type Props = {
  prefix?: string,
  date: Date,
  children: React.ReactElement,
}

const TimeagoTooltip = (props: Props) => {
  return (
    <Tooltip title={<React.Fragment>
      {!!props.prefix && props.prefix} <ReactTimeago date={props.date} />
    </React.Fragment>}>
      {props.children}
    </Tooltip>
  )
}

export default TimeagoTooltip