import "./App.css"
import { ThemeProvider } from "@emotion/react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { theme } from "./theme";
import CssBaseline from "@mui/material/CssBaseline";
import LandingPage from "./pages/LandingPage";
import Dashboard from "./pages/Dashboard";
import { RoutePath } from "./components/SafeRouterLink";
import Clients from "./pages/Dashboard/pages/Clients";
import Programs from "./pages/Dashboard/pages/Programs";
import Billing from "./pages/Dashboard/pages/Billing";
import Profile from "./pages/Dashboard/pages/Profile";
import EditDetails from "./pages/Dashboard/pages/Profile/EditDetails";
import EditPassword from "./pages/Dashboard/pages/Profile/EditPassword";
import ProgramTemplates from "./pages/Dashboard/pages/Programs/ProgramTemplates";
import Client from "./pages/Dashboard/pages/Clients/Client";
import ClientsList from "./pages/Dashboard/pages/Clients/ClientsList";
import ClientSummary from "./pages/Dashboard/pages/Clients/Client/ClientSummary";
import ClientPrograms from "./pages/Dashboard/pages/Clients/Client/ClientPrograms";
import NavigateToSummary from "./pages/Dashboard/pages/Clients/Client/NavigateToSummary";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Contact from "./pages/Contact";
import Waitlist from "./pages/Waitlist";
import NotFound from "./pages/NotFound";
import ProgramTemplate from "./pages/Dashboard/pages/Programs/ProgramTemplate";
import ClientProgram from "./pages/Dashboard/pages/Clients/Client/ClientProgram";

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path={RoutePath.Root} element={<LandingPage />} />
          <Route path={RoutePath.Dashboard} element={<Dashboard />}>
            <Route path={RoutePath.Dashboard} element={<Navigate to={RoutePath.DashboardClients} replace />} />
            <Route path={RoutePath.DashboardClients} element={<Clients />}>
              <Route path={RoutePath.DashboardClients} element={<ClientsList />} />
              <Route path={RoutePath.DashboardClientsClient} element={<Client />}>
                <Route path={RoutePath.DashboardClientsClient} element={<NavigateToSummary />} />
                <Route path={RoutePath.DashboardClientsClientSummary} element={<ClientSummary />} />
                <Route path={RoutePath.DashboardClientsClientPrograms} element={<ClientPrograms />} />
                <Route path={RoutePath.DashboardClientsClientProgram} element={<ClientProgram />} />
              </Route>
            </Route>
            <Route path={RoutePath.DashboardPrograms} element={<Programs />}>
              <Route path={RoutePath.DashboardPrograms} element={<ProgramTemplates />} />
              <Route path={RoutePath.DashboardProgramsProgram} element={<ProgramTemplate />} />
            </Route>
            <Route path={RoutePath.DashboardBilling} element={<Billing />} />
            <Route path={RoutePath.DashboardProfile} element={<Profile />}>
              <Route path={RoutePath.DashboardProfile} element={<Navigate to={RoutePath.DashboardProfileDetails} replace />} />
              <Route path={RoutePath.DashboardProfileDetails} element={<EditDetails />} />
              <Route path={RoutePath.DashboardProfilePassword} element={<EditPassword />} />
            </Route>
          </Route>
          <Route path={RoutePath.Terms} element={<Terms />} />
          <Route path={RoutePath.Privacy} element={<Privacy />} />
          <Route path={RoutePath.Contact} element={<Contact />} />
          <Route path={RoutePath.Waitlist} element={<Waitlist />} />
          <Route path='*' element={<NotFound />}/>
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
