import { useEffect, useReducer, useRef, useState } from "react";
import { ExerciseSet, ExerciseWithSets, IdentifiableExercise, IdentifiableExerciseSet, Program, Workout } from "../../model/types";
import { ActionType, workoutReducer } from "../../model/reducers/workoutReducer";
import { createExerciseWithName } from "../../model/reducers/exerciseReducer";
import {v4 as uuidv4} from 'uuid';
import { useDebouncedCallback } from "use-debounce";
import { uniqueNameFromListOfNames } from "../../utils/uniqueName";
import { WorkoutRepository } from "../../model/repositories/workoutRepository";
var deepEqual = require('deep-equal');

type Props = {
  workoutId?: string, // if empty, a new workout will be instantiated in the builder
  program?: Program,
  workoutRepository: WorkoutRepository,
}

const convertExerciseToIdentifiable = (exercise: ExerciseWithSets<ExerciseSet>): IdentifiableExercise<IdentifiableExerciseSet> => {
  const { sets, ...rest } = exercise;
  const identifiableSets = sets?.map(set => ({ ...set, id: uuidv4() }));
  return {
    ...rest,
    ...sets && identifiableSets && {sets: identifiableSets},
    id: uuidv4(),
  };
};

export const convertIdentifiableExerciseToExercise = (exercise: IdentifiableExercise<IdentifiableExerciseSet>): ExerciseWithSets<ExerciseSet> => {
  const { id, sets: identifiableSets, ...rest } = exercise;
  const sets = identifiableSets?.map(identifiableSet => {
    const { id, ...rest } = identifiableSet;
    return rest;
  });
  return {
    ...rest,
    ...sets && identifiableSets && {sets},
  };
};

const convertWorkoutToIdentifiable = (workout: Workout<ExerciseWithSets<ExerciseSet>>): Workout<IdentifiableExercise<IdentifiableExerciseSet>> => {
  const { baseExercises, performedExercises, ...rest } = workout;
  return {
    ...rest,
    ...baseExercises && {baseExercises: baseExercises.map(convertExerciseToIdentifiable)},
    ...performedExercises && {performedExercises: performedExercises.map(convertExerciseToIdentifiable)},
  };
};

const identifiableWorkoutToWorkout = (identifiableWorkout: Workout<IdentifiableExercise<IdentifiableExerciseSet>>): Workout<ExerciseWithSets<ExerciseSet>> => {
  const { baseExercises, performedExercises, ...rest } = identifiableWorkout;
  return {
    ...rest,
    ...baseExercises && {baseExercises: identifiableWorkout.baseExercises?.map(convertIdentifiableExerciseToExercise)},
    ...performedExercises && {performedExercises: identifiableWorkout.performedExercises?.map(convertIdentifiableExerciseToExercise)},
  };
}

const useWorkoutBuilder = (props: Props) => {
  const [workout, dispatch] = useReducer(workoutReducer<IdentifiableExercise<IdentifiableExerciseSet>>, undefined);

  // Represents the current state of the workout in the remote repository.
  // Loaded when first mounted by using the workoutId and then it is
  // updated after each save.
  const [remoteWorkout, setRemoteWorkout] = useState<Workout<IdentifiableExercise<IdentifiableExerciseSet>>>();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // If there's no workoutId, create a new workout definition in the reducer.
  useEffect(
    () => {
      if (!props.workoutId && !workout) {
        dispatch({
          type: ActionType.LoadWorkout,
          workout: {
            id: uuidv4(),
            name: uniqueNameFromListOfNames(props.program?.workouts?.map((workoutMeta) => workoutMeta.name) ?? [], "Untitled Workout"),
            clientId: props.program?.clientId,
            ...props.program && {
              program: {
                id: props.program.id,
                name: props.program.name,
                description: props.program.description,
              },
            },
          },
        });
      }
    },
    [props.workoutId, workout, props.program],
  );

  // Set the loadedProgram on first render or when there's a change of workoutId.
  useEffect(
    () => {
      if (props.workoutId && (!remoteWorkout || props.workoutId !== remoteWorkout.id)) {
        setIsLoading(true);
        const loadWorkoutFromRemote = async () => {
          if (!props.workoutId) {
            console.error("No workout ID provided")
            setError("Failed to load workout");
            return;
          }
          const response = await props.workoutRepository.getWorkout(props.workoutId);
          if (typeof response === 'string') {
            console.error(response);
            setError("Failed to load workout");
          }
          else {
            setRemoteWorkout(convertWorkoutToIdentifiable(response));
            setError(null);
          }
        };
        loadWorkoutFromRemote();
      }
    },
    [props.workoutId, remoteWorkout, props.workoutRepository],
  );

  // When the workout is loaded, set the workout in the reducer.
  useEffect(
    () => {
      if (!remoteWorkout && !workout && error) {
        setIsLoading(false);
        return;
      }
      if (remoteWorkout && !workout) {
        dispatch({type: ActionType.LoadWorkout, workout: remoteWorkout});
      }
    },
    [remoteWorkout, error, workout],
  );

  // When the workout has been set in the reducer, set to loaded.
  useEffect(
    () => {
      if (workout && isLoading) {
        setIsLoading(false);
      }
    },
    [workout, isLoading],
  );

  // Count the number of on-going saves so we only set isSaving to false
  // when there are no more save operations.
  let saveWorkoutCounter = useRef(0);

  const saveWorkoutToRemote = async (workout: Workout<IdentifiableExercise<IdentifiableExerciseSet>>) => {
    saveWorkoutCounter.current++;
    setIsSaving(true);
    const error = await props.workoutRepository.saveWorkout(identifiableWorkoutToWorkout(workout));
    if (error) {
      console.error(error);
      setError("Failed to save workout");
      if (remoteWorkout) {
        dispatch({type: ActionType.LoadWorkout, workout: remoteWorkout});
      }
    }
    else {
      setRemoteWorkout(workout);
      setError(null);
    }
    saveWorkoutCounter.current--;
    if (saveWorkoutCounter.current === 0) {
      setIsSaving(false);
    }
  };

  const debouncedSaveWorkout = useDebouncedCallback(saveWorkoutToRemote, 1000);

  // Keep a reference to isLoading so it can be checked in other useEffects
  // without requiring it to be in the dependency array.
  const isLoadingRef = useRef(isLoading);
  useEffect(
    () => { isLoadingRef.current = isLoading },
    [isLoading],
  )

  useEffect(
    () => {
      if (!workout || isLoadingRef.current) {
        return;
      }
      if (!remoteWorkout || !deepEqual(remoteWorkout, workout)) {
        debouncedSaveWorkout(workout);
      }
    },
    [remoteWorkout, workout, debouncedSaveWorkout],
  );

  const existingNames = props.program?.workouts?.filter((w) => w.id !== props.workoutId)?.map((w) => w.name) ?? [];

  const editName = (name: string) => {
    if (name === "") {
      setError("Name cannot be empty");
      return;
    }
    if (existingNames && existingNames.findIndex((existingName) => existingName === name) >= 0) {
      const errorString = `'${name}' already exists in this program.`;
      setError(errorString);
      return errorString;
    }
    dispatch({type: ActionType.EditName, name});
  };

  const editDescription = (description: string | null) => dispatch({type: ActionType.EditDescription, description});

  const moveExercise = (fromIndex: number, toIndex: number) => {
    if (!workout?.baseExercises) {
      return;
    }
    if (fromIndex < 0 || fromIndex >= workout.baseExercises.length || toIndex < 0 || toIndex >= workout.baseExercises.length) {
      console.error("Invalid indices in moving exercise");
      setError("Error moving exercise");
      return;
    }
    dispatch({type: ActionType.MoveExercise, fromIndex, toIndex});
  }

  const deleteExercise = (index: number) => {
    if (!workout?.baseExercises || index < 0 || index >= workout.baseExercises.length) {
      console.error(`Could not delete exercise at index ${index}`);
      setError("Error deleting exercise");
      return;
    }
    dispatch({type: ActionType.DeleteExercise, index});
  }

  const addExercise = (name: string): IdentifiableExercise<IdentifiableExerciseSet> => {
    const exercise = convertExerciseToIdentifiable(createExerciseWithName(name));
    dispatch({type: ActionType.AddExercise, exercise});
    return exercise;
  };

  const editExercise = (index: number, exercise: IdentifiableExercise<IdentifiableExerciseSet>) => {
    if (!workout?.baseExercises || index < 0 || index >= workout.baseExercises.length) {
      console.error(`Could not edit exercise at index =${index}`);
      setError("An error occurred");
      return;
    }
    dispatch({type: ActionType.EditExercise, index, exercise});
  }

  return {
    workout,

    editName,
    editDescription,

    addExercise,
    moveExercise,
    editExercise,
    deleteExercise,

    isLoading,
    isSaving,
    error,
  }
}

export type UseWorkoutBuilderReturnType = ReturnType<typeof useWorkoutBuilder>;

export default useWorkoutBuilder;