import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { developmentMode } from "./developmentMode";

const firebaseConfig = {
  apiKey: "AIzaSyAMFZD7BAzOKPjSCjMhr6n0fZ7fQZMcJa4",
  authDomain: "spotter-2b965.firebaseapp.com",
  projectId: "spotter-2b965",
  storageBucket: "spotter-2b965.appspot.com",
  messagingSenderId: "389876280429",
  appId: "1:389876280429:web:efdf901836083e4cd86364",
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app, 'europe-west1');

if (developmentMode) {
  connectAuthEmulator(auth, "http://127.0.0.1:9099");
  connectFirestoreEmulator(db, '127.0.0.1', 8080);
  connectFunctionsEmulator(functions, "127.0.0.1", 5003);
}

export const emulatorFunctionsUrl = developmentMode ? `http://127.0.0.1:5003/${firebaseConfig.projectId}/${functions.region}` : "";