import { addDoc, collection } from "firebase/firestore"
import { useCallback, useState } from "react"
import { db } from "../../config/firebase"

type OutgoingProgramData = {
  coachId: string,
  clientId: string,
  programId: string,
  programName?: string,
  programDescription?: string,
}

export const useAddOutgoingProgram = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState();

  const addOutgoingProgram = useCallback(
    async (data: OutgoingProgramData): Promise<string | null> => {
      setIsLoading(true);
      try {
        await new Promise(r => setTimeout(r, 2000));
        const collectionRef = collection(db, `users/${data.coachId}/outgoingPrograms`);
        await addDoc(collectionRef, data);
        setIsSuccess(true);
        setIsLoading(false);
        return null;
      }
      catch (error: any) {
        console.error(error.message);
        setError(error.message);
        setIsSuccess(false);
        setIsLoading(false);
        return error.message;
      }
    },
    [],
  )

  return {
    addOutgoingProgram,
    isLoading,
    isSuccess,
    error
  }
}