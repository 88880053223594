import React from "react"
import ScrollToTopOnMount from "../components/ScrollToTopOnMount"
import Page from "../components/Page"
import Container from "@mui/material/Container"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"

const NotFound = () => {
  return (
    <React.Fragment>
      <ScrollToTopOnMount />
      <Page>
        <Container>
          <Paper sx={{
            p: 3,
            mx: { md: 3 },
            my: 3,
          }}>
            <Typography variant="h4" mb={3}>
              Page Not Found
            </Typography>

            <Typography>
              This page was not found.
            </Typography>
          </Paper>
        </Container>
      </Page>
    </React.Fragment>
  );
}

export default NotFound;