import AppBar from "@mui/material/AppBar"
import Container from "@mui/material/Container"
import Toolbar from "@mui/material/Toolbar"
import React from "react"
import Link from "@mui/material/Link"
import SafeRouterLink, { RoutePath } from "./SafeRouterLink"
import LogoText from "./LogoText"

const HomeNavbar = ({showDashboardButton = true}: {showDashboardButton?: boolean}) => {
  // const [user] = useAuthState(auth);
  // const [signOut] = useSignOut(auth);

  return (
    <React.Fragment>
      <AppBar enableColorOnDark>
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
            <Link
              component={SafeRouterLink}
              to={RoutePath.Root}
              sx={{
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <LogoText />
            </Link>

            {/* {showDashboardButton &&
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ my: 2, display: "block" }}
                  component={SafeRouterLink}
                  to={RoutePath.Dashboard}
                >
                  Dashboard
                </Button>

                {user &&
                  <Link color="inherit" component="button" sx={{ textDecoration: "none" }} onClick={() => {
                    signOut()
                  }}>
                    Sign Out
                  </Link>
                }
              </Stack>
            } */}

          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar />
    </React.Fragment>
  )
}

export default HomeNavbar