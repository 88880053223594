import Box from "@mui/material/Box"
import { ReactNode } from 'react'

const FlexContainer = ({children}: {children: ReactNode}) => {
  return (
    <Box sx={{
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
    }}>
      {children}
    </Box>
  )
}

export default FlexContainer