import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ExerciseField } from "../../../../../../model/types";

type Props = {
  checked: Set<ExerciseField>,
  onChange: (field: ExerciseField, checked: boolean) => void,
}

const FieldSelector = (props: Props) => {
  const [fieldsAnchorEl, setFieldsAchorEl] = useState<HTMLElement | null>(null);

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        endIcon={<KeyboardArrowDownIcon />}
        onClick={(event) => setFieldsAchorEl(event.currentTarget)}
      >
        Fields
      </Button>
      <Menu
        anchorEl={fieldsAnchorEl}
        open={!!fieldsAnchorEl}
        onClose={() => setFieldsAchorEl(null)}
      >
        <Box px={2}>
          <FormGroup>
            {Object.keys(ExerciseField).filter((v) => isNaN(Number(v))).map((fieldStr) => {
              const field = ExerciseField[fieldStr as keyof typeof ExerciseField];
              return <FormControlLabel
                key={fieldStr}
                control={
                  <Checkbox
                    checked={props.checked.has(field)}
                    onChange={(event) => props.onChange(field, event?.target.checked)}
                  />
                }
                label={fieldStr}
              />
            })}
          </FormGroup>
        </Box>
      </Menu>
    </React.Fragment>
  )
}

export default FieldSelector