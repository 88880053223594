export type UserData = {
  uid: string,
  email: string,
  firstName: string,
  lastName: string,
}

export type Program = {
  id: string,
  name: string,
  description?: string,
  workouts?: WorkoutMetadata[],
  clientId?: string,

  maxWorkoutStarted?: Date,
  created?: Date,
  sent?: Date,
  started?: Date,
  modified?: Date,
  finished?: Date,
}

export type WorkoutMetadata = {
  id: string,
  name: string,
  description?: string,
  exercises?: string[],

  started?: Date,
  modified?: Date,
  finished?: Date,
}

export type Workout<
  ExerciseType extends ExerciseWithSets<SetType>,
  SetType extends ExerciseSet = ExerciseType extends ExerciseWithSets<infer T> ? T : never>
= {
  id: string,
  name: string,
  clientId?: string,
  program?: ProgramMetadata,
  description?: string,
  baseExercises?: ExerciseType[],
  performedExercises?: ExerciseType[],

  started?: Date,
  modified?: Date,
  finished?: Date,
}

export type ProgramMetadata = {
  id: string,
  name: string,
  description?: string,
}

/* An exercise definition within a workout (i.e. it has sets and notes) */
/* Exercises can be added multiple times into a workout so ID acts as a uniqueness constraint */
export type ExerciseWithSets<T extends ExerciseSet> = {
  name: string,
  notes?: string,
  sets?: T[],
  fields: Set<ExerciseField>,
  weightUnits?: WeightUnits,
};

export type ExerciseWithSetsAlias<ExerciseType> = ExerciseType extends ExerciseWithSets<infer T> ? T : never;

export type IdentifiableExercise<T extends ExerciseSet> = ExerciseWithSets<T> & { id: string };

export enum ExerciseField {
  Reps = "reps",
  Weight = "weight",
  Time = "time",
  RPE = "rpe",
  Percentage = "percentage",
};

/* 
   Everything is optional in a set template because different combinations are possible
   If the value is null, it means it is provided but empty (i.e. templated).
   If the value is present, then this is the value used in the template.
*/
export type ExerciseSet = {
  reps?: number | null,
  weight?: number | null,
  time?: number | null,
  rpe?: number | null,
  percentage?: number | null,

  notes?: string,
};

export type IdentifiableExerciseSet = ExerciseSet & { id: string };

export enum WeightUnits {
  Kilograms = "kg",
  Pounds = "lbs",
}

export type SentInviteData = {
  firstName: string,
  lastName: string,
  email: string,
  timestamp: Date,
}

export type ClientMetadata = {
  id: string,
  firstName: string,
  lastName: string,
  clientSince: Date,
}

export type ClientData = {
  id: string,
  firstName: string,
  lastName: string,
}