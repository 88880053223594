import IconButton from "@mui/material/IconButton"
import DeleteIcon from '@mui/icons-material/Delete';

type Props = {
  onDelete: () => void,
}

const DeleteButton = (props: Props) => {
  return (
    <IconButton
      onClick={() => props.onDelete()}
      sx={{
        opacity: 0.25,
        ":hover": {
          opacity: 1,
        }
      }}
    >
      <DeleteIcon />
    </IconButton>
  )
}

export default DeleteButton