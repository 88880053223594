import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import React, { useEffect, useRef, useState } from 'react'
import EditableTypography from "../../EditableTypography"
import AddExercise from "./AddExercise"
import {
  DndContext, 
  closestCenter,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  restrictToParentElement,
} from '@dnd-kit/modifiers';
import ExerciseListItem from "./ExerciseListItem"
import { ExerciseSet, ExerciseWithSets, Program, Workout } from "../../../../../model/types"
import Typography from "@mui/material/Typography"
import LoadingSkeleton from "../../../../../components/LoadingSkeleton"
import TableLoading from "../../TableLoading"
import useWorkoutBuilder from "../../../../../hooks/programs/workoutBuilder"
import SavingToast, { SavingToastStatus } from "../../SavingToast"
import { WorkoutRepository } from "../../../../../model/repositories/workoutRepository"

export type WorkoutBuilderProps = {
  workoutId?: string,
  program?: Program,
  workoutRepository: WorkoutRepository,
  onWorkoutSaved: (workout: Workout<ExerciseWithSets<ExerciseSet>>) => void,
}

const WorkoutBuilder = (props: WorkoutBuilderProps) => {
  const workoutRepository: WorkoutRepository = {
    ...props.workoutRepository,
    saveWorkout: async (workout: Workout<ExerciseWithSets<ExerciseSet>>): Promise<string | null> => {
      const error = await props.workoutRepository.saveWorkout(workout);
      if (error === null) {
        props.onWorkoutSaved(workout);
      }
      return error;
    },
  }

  const {
    workout,

    addExercise,
    moveExercise,
    editExercise,
    deleteExercise,

    editName,
    editDescription,

    isLoading,
    isSaving,
    error,
  } = useWorkoutBuilder({
    workoutId: props.workoutId,
    program: props.program,
    workoutRepository,
  });

  // Store the most recently added workoutId so we can auto-expand
  const [addedExerciseId, setAddedExerciseId] = useState<string>();

  const handleAddExercise = (name: string) => {
    const exercise = addExercise(name);
    if (exercise) {
      setAddedExerciseId(exercise.id);
    }
  }

  const handleDragEnd = ({active, over}: DragEndEvent) => {
    if (!active || !over || !workout || active.id === over.id) {
      return;
    }
    const activeIndex = workout.baseExercises?.findIndex((e) => e.id === active.id.toString()) ?? -1;
    const overIndex = workout.baseExercises?.findIndex((e) => e.id === over.id.toString()) ?? -1;
    if (activeIndex < 0 || overIndex < 0) {
      return;
    }
    moveExercise(activeIndex, overIndex);
  }

  const [displayName, setDisplayName] = useState<string>();
  useEffect(
    () => {
      setDisplayName(workout?.name);
    },
    [workout?.name]
  );

  const commitNameChange = () => {
    if (!displayName) {
      setDisplayName(workout?.name);
      return;
    }
    editName(displayName);
  };

  const [displayDescription, setDisplayDescription] = useState<string>();
  useEffect(
    () => {
      setDisplayDescription(workout?.description);
    },
    [workout?.description],
  );

  useEffect(
    () => {
      if (error && workout) {
        setDisplayName(workout.name);
        setDisplayDescription(workout.description);
      }
    },
    [error, workout],
  );

  return (
    <React.Fragment>
      <Stack gap={1}>
        {error && 
          <Typography px={1} variant="body2" color="error">
            {error}
          </Typography>
        }

        <LoadingSkeleton width="250px" height="60px" loading={isLoading}>
          {workout && 
            <EditableTypography
              autoEdit={!props.workoutId}
              variant="h5"
              value={displayName}
              onValueChange={(name) => setDisplayName(name)}
              onCommit={commitNameChange}
            />
          }
        </LoadingSkeleton>

        <Box display="flex" gap={1}>
          <Box flex="1" mb={1}>
            <LoadingSkeleton width="300px" height="50px" loading={isLoading}>
              {workout &&
                <EditableTypography
                  variant="body1"
                  value={displayDescription}
                  placeholder={displayDescription ? undefined : "Add description"}
                  onValueChange={(value) => setDisplayDescription(value)}
                  onCommit={() => editDescription(displayDescription ?? null)}
                />
              }
            </LoadingSkeleton>
          </Box>
          {workout && <AddExercise onAddExercise={handleAddExercise} />}
        </Box>
      </Stack>

      <Box mb={3}>
        {isLoading && <TableLoading rows={3} loading={true} />}
        {workout && !isLoading &&
          <DndContext
            modifiers={[restrictToParentElement]}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={workout?.baseExercises ?? []}
              strategy={verticalListSortingStrategy}
            >
              {workout.baseExercises?.map((exercise, index) =>
                <ExerciseListItem
                  key={exercise.id}
                  exercise={exercise}
                  defaultExpanded={exercise.id === addedExerciseId}
                  onExerciseChange={(newExercise) => editExercise(index, newExercise)}
                  onExerciseDelete={() => deleteExercise(index)}
                />
              )}
            </SortableContext>
          </DndContext>
        }
      </Box>

      <SavingToast
        position="absolute"
        status={
          isSaving
            ? SavingToastStatus.Saving
            : !!error
              ? SavingToastStatus.Error
              : SavingToastStatus.Saved
        }
      />
    </React.Fragment>
  )
}

export default WorkoutBuilder