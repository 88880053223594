import React from 'react'
import DashboardAppBar from "../../components/DashboardAppBar"
import Content from "../../components/Content"
import { Outlet } from "react-router-dom"

const Programs = () => {
  return (
    <React.Fragment>
      <DashboardAppBar title="Programs" />
      <Content>
        <Outlet />
      </Content>
    </React.Fragment>
  )
}

export default Programs