import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { useState } from "react";
import { FieldError, useForm } from "react-hook-form";
import { theme } from "../theme";
import { auth } from "../config/firebase";
import { useCreateUser } from "../hooks/users/createUserHook";
import LoadingButton from '@mui/lab/LoadingButton';
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";

export enum FormAuthType {
  SignUp,
  SignIn,
}

interface SignUpFormData {
  firstName?: string,
  lastName?: string,
  email: string,
  password: string,
  confirmPassword?: string,
}

const SignIn = ({defaultAuthType = FormAuthType.SignUp}: {defaultAuthType?: FormAuthType}) => {
  const [
    createUser,
    ,
    registerLoading,
    registerError,
  ] = useCreateUser(auth);

  const [
    formAuthType,
    setFormAuthType
  ] = useState<FormAuthType>(defaultAuthType);

  const {
    register,
    watch,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<SignUpFormData>({
    reValidateMode: "onSubmit",
  });

  const [signIn, , signInLoading, signInError] = useSignInWithEmailAndPassword(auth);

  const onSubmit = (data: SignUpFormData) => {
    if (formAuthType === FormAuthType.SignUp) {
      if (data.firstName && data.lastName) {
        createUser(data.email, data.password, data.firstName, data.lastName);
      }
    }
    else {
      signIn(data.email, data.password);
    }
  }

  const registerErrorText = () => {
    if (!registerError) {
      return "";
    }
    switch (registerError.code) {
      case "auth/email-already-in-use":
        return "An account with that email already exists.";
      default:
        return "Authentication error.";
    }
  }

  const signInErrorText = () => {
    if (!signInError) {
      return "";
    }
    switch (signInError.code) {
      case "auth/user-not-found":
        return "No account with that email was found.";
      case "auth/wrong-password":
        return "Incorrect password.";
      default:
        return "Authentication error.";
    }
  }

  const hasFormErrors = (
    !!errors.firstName ||
    !!errors.lastName ||
    !!errors.email ||
    !!errors.password ||
    !!errors.confirmPassword
  );

  const nameHelperText = (error: FieldError | undefined, prefix: string) => {
    if (!error) {
      return "";
    }
    switch (error.type) {
      case "required":
        return `${prefix} Name is required`;
      case "maxLength":
        return `${prefix} Name exceeds maximum length`;
      default:
        return `${prefix} Name is invalid`;
    }
  }

  const emailHelperText = () => {
    const error = errors.email;
    if (!error) {
      return;
    }
    switch (error.type) {
      case "required":
        return "Email address is required";
      case "pattern":
      default:
        return "Not a valid email address";
    }
  }

  const passwordHelperText = () => {
    const error = errors.password;
    if (!error) {
      return;
    }
    switch (error.type) {
      case "required":
        return "Password is required.";
      case "minLength":
        return "Password must be at least 6 characters";
      default:
        return "Not a valid password";
    }
  }

  const confirmPasswordHelperText = () => {
    const error = errors.confirmPassword;
    if (!error) {
      return;
    }
    switch (error.type) {
      case "required":
        return "Password confirmation is required";
      case "validate":
        return "Passwords do not match";
      default:
        return "Password confirmation is invalid";
    }
  }

  return (
    <Container maxWidth="sm">
      <Paper sx={{
        p: 3,
        m: 3,
      }}>
        <Typography variant="h4" mb={2}>
          Sign {formAuthType === FormAuthType.SignUp ? "Up" : "In"}
        </Typography>

        {hasFormErrors && 
          <Typography my={2} variant="body2" color={theme.palette.error.main}>
            Please make sure you've filled in all the fields correctly.
          </Typography>
        }

        {registerError &&
          <Typography my={2} variant="body2" color={theme.palette.error.main}>
            {registerErrorText()}
          </Typography>
        }

        {signInError &&
          <Typography my={2} variant="body2" color={theme.palette.error.main}>
            {signInErrorText()}
          </Typography>
        }

        <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleSubmit(onSubmit)}>
            {formAuthType === FormAuthType.SignUp &&
              <Grid container spacing={1}>
                <Grid item xs>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="firstName"
                    label="First Name"
                    autoComplete="firstName"
                    {...register("firstName", {
                      required: formAuthType === FormAuthType.SignUp,
                      maxLength: 80,
                    })}
                    error={!!errors.firstName}
                    helperText={nameHelperText(errors.firstName, "First")}
                    disabled={registerLoading}
                    autoFocus
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    autoComplete="lastName"
                    {...register("lastName", {
                      required: formAuthType === FormAuthType.SignUp,
                      maxLength: 100,
                    })}
                    error={!!errors.lastName}
                    helperText={nameHelperText(errors.lastName, "Last")}
                    disabled={registerLoading}
                  />
                </Grid>
              </Grid>
            }

            <TextField
              margin="normal"
              fullWidth
              id="email"
              label="Email Address"
              autoComplete="email"
              autoFocus={formAuthType === FormAuthType.SignIn}
              {...register("email", {
                required: true,
                pattern: /^\S+@\S+$/i,
              })}
              error={!!errors.email}
              helperText={emailHelperText()}
              disabled={registerLoading || signInLoading}
            />

            <TextField
              margin="normal"
              fullWidth
              label="Password"
              type="password"
              id="password"
              autoComplete="password"
              {...register("password", {
                required: true,
                minLength: 6,
              })}
              error={!!errors.password}
              helperText={passwordHelperText()}
              disabled={registerLoading || signInLoading}
            />

            {formAuthType === FormAuthType.SignUp &&
              <TextField
                margin="normal"
                fullWidth
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                {...register("confirmPassword", {
                  required: true,
                  validate: (val) => {
                    return val === watch("password")
                  }
                })}
                error={!!errors.confirmPassword}
                helperText={confirmPasswordHelperText()}
                disabled={registerLoading}
              />
            }

            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              loading={registerLoading || signInLoading}
            >
              Sign {formAuthType === FormAuthType.SignUp ? "Up" : "In"}
            </LoadingButton>
            <Grid container>
              <Grid item xs>
                {formAuthType === FormAuthType.SignIn &&
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                }
              </Grid>
              <Grid item>
                <Link component="button" onClick={(event) => {
                  event.preventDefault();
                  const enteredEmail = watch("email");
                  reset();
                  setFormAuthType(formAuthType === FormAuthType.SignUp ? FormAuthType.SignIn : FormAuthType.SignUp);
                  setValue("email", enteredEmail);
                }} variant="body2">
                  {formAuthType === FormAuthType.SignUp
                    ? "Already have an account? Sign In"
                    : "Don't have an account? Sign Up"
                  }
                </Link>
              </Grid>
            </Grid>
          </Box>

      </Paper>
    </Container>
  )
}

export default SignIn