import Stack from "@mui/material/Stack";
import ContentPaper from "../ContentPaper";
import NameDescription from "./NameDescription";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { ReactNode, useEffect, useState } from "react";
import WorkoutBuilderDialog from "./WorkoutBuilderDialog";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import {
  DndContext, 
  closestCenter,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  restrictToParentElement,
} from '@dnd-kit/modifiers';
import WorkoutListItem from "./WorkoutBuilder/WorkoutListItem";
import { ExerciseSet, ExerciseWithSets, Program, Workout } from "../../../../model/types";
import useProgramBuilder  from "../../../../hooks/programs/programBuilder";
import TableLoading from "../TableLoading";
import SavingToast, { SavingToastStatus } from "../SavingToast";
import { ProgramRepository } from "../../../../model/repositories/programRepository";
import { WorkoutRepository } from "../../../../model/repositories/workoutRepository";
import WorkoutDialog from "../WorkoutDialog";
import { WorkoutBuilderProps } from "./WorkoutBuilder";

export enum Editability {
  Always,
  OnDemandDefaultEditable,
  OnDemandDefaultUneditable,
  Never,
}

type Props = {
  programId: string,
  programRepository: ProgramRepository,
  workoutRepository: WorkoutRepository,
  workoutBuilderContents: (props: WorkoutBuilderProps) => ReactNode,
  additionalHeader?: ReactNode,
  editability: Editability,
  editButtonVisible?: boolean,
}

const ProgramBuilder = (props: Props) => {
   const {
    program,
    editName,
    editDescription,
    setWorkoutMetadata,
    moveWorkout,
    deleteWorkout,
    isLoading,
    isSaving,
    error,
  } = useProgramBuilder({
    programId: props.programId,
    programRepository: props.programRepository,
    workoutRepository: props.workoutRepository,
  });

  // The workoutId currently being edited.
  const [selectedWorkoutId, setSelectedWorkoutId] = useState<string>();

  // Whether or not we're creating a new workout.
  const [createNewWorkout, setCreateNewWorkout] = useState(false);

  useEffect(
    () => {
      setWorkoutDialogOpen(!!selectedWorkoutId || createNewWorkout);
    },
    [selectedWorkoutId, createNewWorkout],
  );

  const [workoutDialogOpen, setWorkoutDialogOpen] = useState<boolean>(false);

  const handleAddWorkout = () => {
    setCreateNewWorkout(true);
  }

  const handleDragEnd = ({active, over}: DragEndEvent) => {
    if (!active || !over || !program || active.id === over.id) {
      return;
    }
    const activeIndex = program.workouts?.findIndex((w) => w.id === active.id.toString()) ?? -1;
    const overIndex = program.workouts?.findIndex((w) => w.id === over.id.toString()) ?? -1;
    if (activeIndex < 0 || overIndex < 0) {
      return;
    }
    moveWorkout(activeIndex, overIndex);
  }

  const handleWorkoutSaved = (workout: Workout<ExerciseWithSets<ExerciseSet>>) => {
    setWorkoutMetadata(workout.id, workout, createNewWorkout);
  }

  const handleCloseDialog = () => {
    setWorkoutDialogOpen(false);
    setSelectedWorkoutId(undefined);
    setCreateNewWorkout(false);
  }

  const [isEditing, setIsEditing] = useState(
    props.editability === Editability.Always || props.editability === Editability.OnDemandDefaultEditable
  );
  useEffect(
    () => {
      setIsEditing(props.editability === Editability.Always || props.editability === Editability.OnDemandDefaultEditable)
    },
    [props.editability],
  );

  return (
    <Stack>
      {!!error && <Typography p={1} color="error">{error}</Typography>}

      <Stack
        direction="row"
        display="flex"
        gap={1}
      >
        <Box flex="1">
          <NameDescription
            program={program}
            onNameChanged={editName}
            onDescriptionChanged={(description) => editDescription(description ?? null)}
            isLoading={isLoading}
            editable={isEditing}
          />
        </Box>
        {props.editability === Editability.OnDemandDefaultEditable || props.editability === Editability.OnDemandDefaultUneditable &&
          <Button
            variant="contained"
            sx={{ alignSelf: "flex-start", mt: 1 }}
            onClick={() => setIsEditing(!isEditing)}
          >
            {isEditing ? "Done" : "Edit"}
          </Button>
        }
      </Stack>

      <Box py={2.5} px={1}>
        <Divider />
      </Box>

      {props?.additionalHeader}

      <Box display="flex" alignItems="center" mt={1}>
        <Typography flex="1" variant="h6">Workouts</Typography>
        {isEditing &&
          <Button onClick={handleAddWorkout} variant="contained">
            Add Workout
          </Button>
        }
      </Box>

      {program && (program.workouts ?? []).length === 0 &&
        <ContentPaper sx={{ my: 1 }}>
          <Grid item xs={6}>
            <Typography align="center" mt={3} mb={1} color="gray">
              This program currently contains no workouts.
            </Typography>
            <Typography align="center" mb={3} color="gray">
              Start by <Link component="button" sx={{ verticalAlign: "baseline" }} onClick={handleAddWorkout}>adding a new workout.</Link>
            </Typography>
          </Grid>
        </ContentPaper>
      }

      <Box
        sx={{ my: 1 }}
      >
        {(isLoading || !program) && <TableLoading loading={true} />}
        {!isLoading && program &&
          <DndContext
            modifiers={[restrictToParentElement]}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={program?.workouts ?? []}
              strategy={verticalListSortingStrategy}
            >
              {program?.workouts?.map((workoutMeta) =>
                <WorkoutListItem
                  key={workoutMeta.id}
                  workoutMetadata={workoutMeta}
                  onClick={() => setSelectedWorkoutId(workoutMeta.id)}
                  onDelete={() => deleteWorkout(workoutMeta.id)}
                  editable={isEditing}
                />
              )}
            </SortableContext>
          </DndContext>
        }
      </Box>

      {!workoutDialogOpen &&
        <SavingToast
          position="fixed"
          status={
            isSaving
              ? SavingToastStatus.Saving
              : !!error
                ? SavingToastStatus.Error
                : SavingToastStatus.Saved
            }
        />
      }

      {(selectedWorkoutId || createNewWorkout) &&
        <WorkoutDialog
          open={workoutDialogOpen}
          onClose={handleCloseDialog}
        >
          {props.workoutBuilderContents({
            workoutId: selectedWorkoutId,
            program,
            workoutRepository: props.workoutRepository,
            onWorkoutSaved: handleWorkoutSaved,
          })}
        </WorkoutDialog>
      }
    </Stack>
  )
}

export default ProgramBuilder