import Typography from "@mui/material/Typography"
import { useCurrentUser } from "../../../../hooks/users/currentUserHook";
import { auth } from "../../../../config/firebase";
import { FieldError, useForm } from "react-hook-form";
import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import TextFieldLoading from "../../../../components/TextFieldLoading";
import LoadingButton from "@mui/lab/LoadingButton";
import {useUpdateUser} from "../../../../hooks/users/updateUserHook";

type FormData = {
  firstName: string,
  lastName: string,
  email: string,
}

const EditDetails = () => {
  const { userData, isLoading, error } = useCurrentUser(auth);
  const {
    updateUser,
    isLoading: updateLoading,
    isSuccess: updateSuccess,
  } = useUpdateUser(auth.currentUser);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>();

  const onSubmit = (data: FormData) => {
    updateUser(data.firstName, data.lastName, data.email);
  }

  const nameHelperText = (error: FieldError | undefined, prefix: string) => {
    if (!error) {
      return "";
    }
    switch (error.type) {
      case "required":
        return `${prefix} Name is required`;
      case "maxLength":
        return `${prefix} Name exceeds maximum length`;
      default:
        return `${prefix} Name is invalid`;
    }
  }

  const emailHelperText = () => {
    const error = errors.email;
    if (!error) {
      return;
    }
    switch (error.type) {
      case "required":
        return "Email address is required";
      case "pattern":
      default:
        return "Not a valid email address";
    }
  }

  return (
    <React.Fragment>
      {!!error
        ? <Typography color="error">
          Couldn't load profile. Please refresh the page and try again.
        </Typography>
        : <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <TextFieldLoading loading={isLoading}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoComplete="firstName"
                  defaultValue={userData?.firstName}
                  {...register("firstName", {
                    required: true,
                    maxLength: 80,
                  })}
                  error={!!errors.firstName}
                  helperText={nameHelperText(errors.firstName, "First")}
                  disabled={updateLoading}
                />
              </TextFieldLoading>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldLoading loading={isLoading}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  autoComplete="lastName"
                  defaultValue={userData?.lastName}
                  {...register("lastName", {
                    required: true,
                    maxLength: 100,
                  })}
                  error={!!errors.lastName}
                  helperText={nameHelperText(errors.lastName, "Last")}
                  disabled={updateLoading}
                />
              </TextFieldLoading>
            </Grid>
            <Grid item xs={12}>
              <TextFieldLoading loading={isLoading}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Email"
                  autoComplete="email"
                  defaultValue={userData?.email}
                  {...register("email", {
                    required: true,
                    maxLength: 100,
                  })}
                  error={!!errors.email}
                  helperText={emailHelperText()}
                  disabled={updateLoading}
                />
              </TextFieldLoading>
            </Grid>
            <Grid item xs={12}>
              <TextFieldLoading loading={isLoading}>
                <LoadingButton loading={updateLoading} type="submit" variant="contained">
                  Save Changes
                </LoadingButton>
              </TextFieldLoading>
            </Grid>
            {updateSuccess &&
              <Grid item xs={12}>
                <Typography variant="body2" color="success.main" my={1}>
                  Changes saved successfully.
                </Typography>
              </Grid>
            }
          </Grid>
        </Box>
      }
    </React.Fragment>
  )
}

export default EditDetails