import React, { useEffect, useState } from 'react'
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { useRemoveClientFromCoach } from "../../../../../hooks/clients/removeClientHook";
import Link from "@mui/material/Link";
import { useGetClientMetadatasQuery } from "../../../../../model/slices/clientsSlice";
import Timestamp from "../../../../../components/Timestamp";
import TableLoading from "../../../components/TableLoading";
import { ClientMetadata, UserData } from "../../../../../model/types";
import SafeRouterLink, { SafeRoutePath } from "../../../../../components/SafeRouterLink";
import DataTable, { DataTableColumn, SortOrder } from "../../../components/DataTable";

type Props = {
  isUserLoading: boolean,
  user: UserData | undefined,
  onInviteClicked?: () => void,
}

const ExistingClientsTable = (props: Props) => {
  const { data: clients, isLoading: clientsLoading } = useGetClientMetadatasQuery(props.user?.uid);
  const isLoading = props.isUserLoading || clientsLoading;

  const [anchorElOption, setAnchorElOption] = useState<null | HTMLElement>(null);
  const [activeOptionClientId, setActiveOptionClientId] = useState<string | undefined>();
  const [clientToDelete, setClientToDelete] = useState<ClientMetadata | undefined>();
  const [deletePromptOpen, setDeletePromptOpen] = useState(false);
  const {
    removeClientFromCoach,
    isSuccess: deleteClientSuccess,
    isLoading: deleteClientLoading,
    error: deleteClientError
  } = useRemoveClientFromCoach();

  const handleOpenOptionMenu = (event: React.MouseEvent<HTMLElement>, client: ClientMetadata) => {
    setActiveOptionClientId(client.id);
    setAnchorElOption(event.currentTarget);
  }

  const handleCloseUserMenu = () => {
    setAnchorElOption(null);
    setActiveOptionClientId(undefined);
  };

  const openDeletePrompt = (client: ClientMetadata) => {
    handleCloseUserMenu();
    setClientToDelete(client);
    setDeletePromptOpen(true);
  }

  const closeDeletePrompt = () => {
    setDeletePromptOpen(false);
  }

  const [deletedClientIds, setDeletedClientIds] = useState(new Set<string>());

  const handleDeleteSubmit = async () => {
    if (clientToDelete) {
      if (await removeClientFromCoach(clientToDelete.id)) {
        deletedClientIds.add(clientToDelete.id);
        setDeletedClientIds(deletedClientIds);
      }
    }
  }

  useEffect(
    () => {
      if (deleteClientSuccess) {
        closeDeletePrompt();
      }
    },
    [deleteClientSuccess],
  );

  const columns: DataTableColumn<ClientMetadata>[] = [
    {
      id: 'name',
      name: "Name",
      sortable: true,
      cellValue: (client) => `${client.firstName} ${client.lastName}`,
      renderCell: (client) => (
        <Typography>
          <Link
            component={SafeRouterLink}
            to={SafeRoutePath.ResolvedClient(client.id)}
            sx={{
              textDecoration: "none",
            }}
          >
            {client.firstName} {client.lastName}
          </Link>
        </Typography>
      ),
    },
    {
      id: 'clientSince',
      name: "Client Since",
      sortable: true,
      cellValue: (client) => client.clientSince,
      renderCell: (client) => (
        <Timestamp variant="body1" timestamp={client.clientSince} />
      ),
    },
    {
      id: 'options',
      renderHeaderCell: () => <></>,
      headerSx: {
        width: '1px',
        whiteSpace: 'nowrap'
      },
      renderCell: (client) => (
        <React.Fragment>
          <IconButton onClick={(event) => handleOpenOptionMenu(event, client)}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            sx={{ mt: "25px" }}
            anchorEl={anchorElOption}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={activeOptionClientId === client.id}
            onClose={handleCloseUserMenu}
          >
            <MenuItem onClick={() => openDeletePrompt(client)}>
              <Typography textAlign="center">Delete</Typography>
            </MenuItem>
          </Menu>
        </React.Fragment>
      ),
    },
  ];

  const existingClients = clients?.filter((client) => !deletedClientIds.has(client.id));

  return (
    <React.Fragment>

      <TableLoading loading={isLoading} rows={5} />

      {!isLoading && existingClients?.length === 0 &&
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={6}>
            <Typography align="center" mt={3} mb={1} color="gray">
              You haven't got any clients yet.
            </Typography>
            <Typography align="center" mb={3} color="gray">
              Start by <Link component="button" sx={{ verticalAlign: "baseline" }} onClick={props.onInviteClicked}>inviting a new client.</Link>
            </Typography>
          </Grid>
        </Grid>
      }

      {!isLoading && existingClients && existingClients.length > 0 && <DataTable
        data={existingClients}
        columns={columns}
        rowKey={({ id }) => id}
        defaultSortColumn='name'
        defaultSortOrder={SortOrder.Asc}
        searchKeys={[
          {
            name: 'fullName',
            value: (client) => `${client.firstName} ${client.lastName}`,
          },
        ]}
        rowsPerPage={20}
      />}

      <Dialog open={deletePromptOpen} maxWidth="sm" onClose={closeDeletePrompt}>
        <DialogTitle>Delete Client</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            noValidate
            onSubmit={(event) => {
              event.preventDefault();
              handleDeleteSubmit();
            }}
          >
            {!!deleteClientError &&
              <Typography mb={2} color="error" variant="body2">
                There was a problem deleting the client.
              </Typography>
            }
            <Typography mb={2}>
              You are about to remove <Box component="span" fontWeight='fontWeightMedium'>{clientToDelete?.firstName} {clientToDelete?.lastName}</Box> from being one of your clients. This action cannot be undone.
            </Typography>

            <Grid container>
              <Grid item xs={12} mt={1}>
                <Box display="flex" gap={1} justifyContent="flex-end">
                  <Button onClick={closeDeletePrompt} disabled={deleteClientLoading}>
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={deleteClientLoading}
                    variant="contained"
                    type="submit"
                    color="error"
                  >
                    Delete
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>

    </React.Fragment>
  )
}

export default ExistingClientsTable