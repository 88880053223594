import React from 'react'
import { ClientData, Program } from "../../../../../../model/types";
import Typography from "@mui/material/Typography";
import ContentPaper from "../../../../components/ContentPaper";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Timestamp from "../../../../../../components/Timestamp";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import TimeagoTooltip from "../../../../components/TimeagoTooltip";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DataTable, { DataTableColumn, SortOrder } from "../../../../components/DataTable";
import Box from "@mui/material/Box";

type Props = {
  client: ClientData,
  programs: Program[],
  draft: boolean,
  rowsPerPage?: number,
  onProgramClicked?: (program: Program) => void,
  onOptionsClicked?: (event: React.MouseEvent<HTMLElement>, program: Program) => void,
}

const ProgramsTable = (props: Props) => {
  const columns: DataTableColumn<Program>[] = [
    {
      id: 'program',
      name: "Program",
      cellValue: (program) => program.name,
      sortable: true,
      renderCell: (program) => {
        const nameAndDescription = <Stack alignItems="flex-start">
          <Typography variant="body2" fontWeight="bold">
            {program.name}
          </Typography>
          {program.description &&
            <Typography
              mt={1}
              variant="body2"
              color="black"
              align="justify"
            >
              {program.description}
            </Typography>
          }
        </Stack>;
        return props.onProgramClicked
          ? <Link
              component="button"
              onClick={() => props.onProgramClicked?.(program)}
              sx={{
                textDecoration: "none",
              }}
              width="100%"
            >
              {nameAndDescription}
            </Link>
          : nameAndDescription;
      },
    },
    ...props.draft ? [
      {
        id: 'created',
        name: "Created",
        sortable: true,
        cellValue: (program: Program) => program.created,
        renderCell: (program: Program) => {
          return program.created ? <Timestamp timestamp={program.created} /> : <React.Fragment />
        },
      },
      {
        id: 'modified',
        name: "Updated",
        sortable: true,
        cellValue: (program: Program) => program.modified,
        renderCell: (program: Program) => {
          return program.modified ? <Timestamp timestamp={program.modified} /> : <React.Fragment />
        },
      },
    ] : [
      {
        id: 'sent',
        name: "Sent",
        sortable: true,
        cellValue: (program: Program) => program.sent,
        renderCell: (program: Program) => {
          return program.sent ? <Timestamp timestamp={program.sent} /> : <React.Fragment />
        },
      },
      {
        id: 'status',
        name: "Status",
        sortable: true,
        cellValue: (program: Program) => {
          if (program.sent) {
            return `4_${program.sent.toISOString()}`;
          }
          else if (program.started) {
            return `3_${program.started.toISOString()}`;
          }
          if (program.finished) {
            return `2_${program.finished.toISOString()}`;
          }
          else if (program.created) {
            return `1_${program.created.toISOString()}`;
          }
          return "0";
        },
        renderCell: (program: Program) => {
          if (program.finished) {
            return <TimeagoTooltip prefix="Finished" date={program.finished}>
              <CheckCircleIcon color="success" />
            </TimeagoTooltip>
          }
          if (program.started) {
            return <TimeagoTooltip prefix="Started" date={program.started}>
              <AccessTimeIcon color="primary" />
            </TimeagoTooltip>
          }
          if (program.sent) {
            return <TimeagoTooltip prefix="Sent" date={program.sent}>
              <MobileFriendlyIcon color="inherit" sx={{ opacity: 0.2 }} />
            </TimeagoTooltip>
          }
          return <></>;
        },
      }
    ],
    ...props.onOptionsClicked ? [
      {
        id: 'options',
        name: "Options",
        renderHeaderCell: () => <></>,
        headerSx: {
          width: '1px',
          whiteSpace: 'nowrap'
        },
        renderCell: (program: Program) => (
          <IconButton onClick={(event) => props.onOptionsClicked?.(event, program)}>
            <MoreVertIcon />
          </IconButton>
        ),
      },
    ] : [],
  ];

  return (
    <ContentPaper sx={{ mb: 4 }}>
      <DataTable
        data={props.programs}
        columns={columns}
        rowKey={({ id }) => id}
        defaultSortColumn={props.draft ? 'modified' : 'status'}
        defaultSortOrder={SortOrder.Desc}
        rowsPerPage={props.rowsPerPage ?? 20}
      />
    </ContentPaper>
  )
}

export default ProgramsTable