import { createTheme } from "@mui/material/styles";

declare module '@mui/material/styles' {
  interface Palette {
    tertiary?: string,
    paper?: string,
    infoBackground?: string,
    successBackground?: string,
    errorBackground?: string,
    warningBackground?: string,
  }

  interface PaletteColor {
    veryDark?: string;
    veryLight?: string;
  }

  interface SimplePaletteColorOptions {
    veryDark?: string;
    veryLight?: string;
  }

  interface PaletteOptions {
    tertiary?: string,
    paper?: string,
    infoBackground?: string,
    successBackground?: string,
    errorBackground?: string,
    warningBackground?: string,
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: "#1aa0b3",
      veryDark: "#003041",
      veryLight: "#bae3e8",
    },
    secondary: {
      main: "#e95f27",
    },
    tertiary: "#085B66",
    paper: "#eaeff1",
    infoBackground: "#c4e8f2",
    successBackground: "#e4efda",
    errorBackground: "#ebdfde",
    warningBackground: "#faf8e5",
  },
  typography: {
    h1: {
      fontFamily: "Oswald",
      fontWeight: 700,
    },
    h2: {
      fontFamily: "Oswald",
      fontWeight: 700,
    },
    h3: {
      fontFamily: "Oswald",
      fontWeight: 700,
    },
    h4: {
      fontFamily: "Oswald",
      fontWeight: 700,
    },
    h5: {
      fontFamily: "Oswald",
      fontWeight: 700,
    },
    h6: {
      fontFamily: "Oswald",
      fontWeight: 400,
    },
  }
});