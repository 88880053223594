import TableHead from "@mui/material/TableHead"
import { ExerciseField, ExerciseSet, ExerciseWithSets } from "../../../../../../model/types"
import Table from "@mui/material/Table"
import TableRow, { tableRowClasses } from "@mui/material/TableRow"
import TableCell, { TableCellProps, tableCellClasses } from "@mui/material/TableCell"
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody"
import Typography from "@mui/material/Typography"
import React from "react"
import Box from "@mui/material/Box"
import { alpha } from "@mui/material";
import { theme } from "../../../../../../theme"

type Props = {
  exercise: ExerciseWithSets<ExerciseSet>
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    paddingTop: 10,
    paddingBottom: 10,
    width: "0.1%",
    whiteSpace: "nowrap",
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
    paddingTop: 9,
    paddingBottom: 9,
    width: "0.1%",
    whiteSpace: "nowrap",
  },
}));

const StyledCenteredTableCell = (props: TableCellProps) => {
  return <StyledTableCell {...props} align="center" />
}

const ExerciseSummary = (props: Props) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          {props.exercise.fields.has(ExerciseField.Reps) && <StyledCenteredTableCell>Reps</StyledCenteredTableCell>}
          {props.exercise.fields.has(ExerciseField.Weight) &&
            <StyledCenteredTableCell>
              Weight ({props.exercise.weightUnits})
            </StyledCenteredTableCell>
          }
          {props.exercise.fields.has(ExerciseField.Time) && <StyledCenteredTableCell>Time</StyledCenteredTableCell>}
          {props.exercise.fields.has(ExerciseField.RPE) && <StyledCenteredTableCell>RPE</StyledCenteredTableCell>}
          {props.exercise.fields.has(ExerciseField.Percentage) && <StyledCenteredTableCell>Percentage</StyledCenteredTableCell>}
        </TableRow>
      </TableHead>
      <TableBody>
        {props.exercise.sets?.map((set, index) =>
          <React.Fragment key={index}>
            <TableRow style={{
              borderTop: `1px solid ${theme.palette.divider}`,
            }}>
              {props.exercise.fields.has(ExerciseField.Reps) &&
                <StyledCenteredTableCell>
                  {set.reps ?? "-"}
                </StyledCenteredTableCell>
              }

              {props.exercise.fields.has(ExerciseField.Weight) &&
                <StyledCenteredTableCell>
                  {set.weight ?? "-"}
                </StyledCenteredTableCell>
              }

              {props.exercise.fields.has(ExerciseField.Time) &&
                <StyledCenteredTableCell>
                  {set.time ?? "-"}
                </StyledCenteredTableCell>
              }

              {props.exercise.fields.has(ExerciseField.RPE) &&
                <StyledCenteredTableCell>
                  {set.rpe ?? "-"}
                </StyledCenteredTableCell>
              }

              {props.exercise.fields.has(ExerciseField.Percentage) &&
                <StyledCenteredTableCell>
                  {set.percentage ?? "-"}
                </StyledCenteredTableCell>
              }
            </TableRow>
            {set.notes &&
              <TableRow>
                <StyledTableCell
                  align="center"
                  colSpan={props.exercise.fields.size}
                  style={{
                    paddingTop: 0,
                  }}
                >
                  <Box
                    sx={{
                      background: (theme) => alpha(theme.palette.primary.light, 0.15),
                      border: (theme) => `1px solid ${alpha(theme.palette.primary.light, 0.3)}`,
                      width: 'fit-content',
                    }}
                  >
                    <Typography variant="body2" p={1}>
                      {set.notes}
                    </Typography>
                  </Box>
                </StyledTableCell>
              </TableRow>
            }
            {index === (props.exercise.sets!.length - 1) && <TableRow style={{ borderBottom: `1px solid ${theme.palette.divider}` }} />}
          </React.Fragment>
        )}
      </TableBody>
    </Table>
  )
}

export default ExerciseSummary