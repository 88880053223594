import { Auth } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { useFetchCurrentUserQuery } from "../../model/slices/userSlice";

export const useCurrentUser = (auth: Auth) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<any>(undefined);
  const [user, authLoading, authError] = useAuthState(auth);
  const {data, isLoading: fetchLoading, error: fetchError} = useFetchCurrentUserQuery(user);

  useEffect(
    () => {
      setIsLoading(authLoading || fetchLoading);
      if (authError) {
        setError(authError);
      }
      else if (fetchError) {
        setError(fetchError);
      }
      else {
        setError(undefined);
      }
    },
    [user, authLoading, authError, fetchLoading, fetchError]
  );

  return {
    user: user,
    userData: data,
    isLoading: isLoading,
    error: error,
  };
};