import { Theme } from "@mui/material"
import Box, { BoxProps } from "@mui/material/Box"
import { ReactNode } from "react"

export enum AlertType {
  Information,
  Success,
  Error,
  Warning,
}

type Props = {
  type: AlertType,
  children: ReactNode,
}

const AlertBox = (props: Props & BoxProps) => {
  const color = (): string | ((theme: Theme) => string | undefined) => {
    switch (props.type) {
      case AlertType.Information:
        return (theme) => theme.palette.info.dark;
      case AlertType.Success:
        return (theme) => theme.palette.success.dark;
      case AlertType.Error:
        return (theme) => theme.palette.error.dark;
      case AlertType.Warning:
        return "#63450c";
    }
  }

  const borderColor = (): string | ((theme: Theme) => string | undefined) => {
    switch (props.type) {
      case AlertType.Information:
        return (theme) => theme.palette.info.main;
      case AlertType.Success:
        return (theme) => theme.palette.success.main;
      case AlertType.Error:
        return (theme) => theme.palette.error.main;
      case AlertType.Warning:
        return "#f5cb7d";
    }
  }

  const backgroundColor = (): string | ((theme: Theme) => string | undefined) => {
    switch (props.type) {
      case AlertType.Information:
        return (theme) => theme.palette.infoBackground;
      case AlertType.Success:
        return (theme) => theme.palette.successBackground;
      case AlertType.Error:
        return (theme) => theme.palette.errorBackground;
      case AlertType.Warning:
        return (theme) => theme.palette.warningBackground;
    }
  }

  return (
    <Box
      color={color()}
      bgcolor={backgroundColor()}
      border="1px solid"
      borderColor={borderColor()}
      {...props}
    >
      {props.children}
    </Box>
  )
}

export default AlertBox