import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button"
import Popover from "@mui/material/Popover";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";

type Props = {
  onAddExercise?: (exercise: string) => void,
}

type FormData = {
  exercise: string,
}

const AddExercise = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const {register, handleSubmit} = useForm<FormData>();

  const onSubmit = (data: FormData) => {
    props.onAddExercise?.(data.exercise);
    handleClose();
  }

  return (
    <React.Fragment>
      <Button
        variant="contained"
        sx={{ alignSelf: "flex-start", mb: 1 }}
        onClick={handleClick}
      >
        Add Exercise
      </Button>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} p={2}>
          <TextField
            label="Exercise"
            autoFocus
            autoComplete="none"
            {...register("exercise")}
          />
          <Button type="submit" variant="outlined">
            Go
          </Button>
        </Box>
      </Popover>
    </React.Fragment>
  )
}

export default AddExercise