import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import React, { useEffect, useState } from 'react'
import ContentPaper from "../../components/ContentPaper"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import { useGetProgramTemplates } from "../../../../hooks/programs/getProgramTemplateHooks"
import Skeleton from "@mui/material/Skeleton"
import SafeRouterLink, { SafeRoutePath } from "../../../../components/SafeRouterLink"
import Timestamp from "../../../../components/Timestamp"
import Stack from "@mui/material/Stack"
import { Program } from "../../../../model/types"
import IconButton from "@mui/material/IconButton"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { useNavigate } from "react-router-dom"
import CopyToClientDialog from "./CopyToClientDialog"
import CreateProgramTemplateDialog from "./CreateProgramTemplateDialog"
import DeleteProgramTemplateDialog from "./DeleteProgramTemplateDialog"
import DataTable, { DataTableColumn, SortOrder } from "../../components/DataTable"

const ProgramTemplates = () => {
  const [createProgramOpen, setCreateProgramOpen] = useState(false);
  const { data: loadedProgramTemplates, isLoading: templatesLoading } = useGetProgramTemplates();
  const [deletedProgramIds, setDeletedProgramIds] = useState(new Set<string>());

  const programTemplates = loadedProgramTemplates?.filter((p) => !deletedProgramIds.has(p.id));

  const noProgramTemplates = <Grid container alignItems="center" justifyContent="center">
    <Grid item xs={6}>
      <Typography align="center" mt={3} mb={1} color="gray">
        You haven't created any programs yet.
      </Typography>
      <Typography align="center" mb={3} color="gray">
        Start by <Link component="button" sx={{ verticalAlign: "baseline" }} onClick={() => setCreateProgramOpen(true)}>creating a new program.</Link>
      </Typography>
    </Grid>
  </Grid>

  const templatesSkeleton = <React.Fragment>
    {[...Array(5)].map((_, i) => {
      return <Skeleton key={i} height={60} />
    })}
  </React.Fragment>

  const navigate = useNavigate();

  const columns: DataTableColumn<Program>[] = [
    {
      id: 'program',
      name: "Program",
      sortable: true,
      cellValue: (program) => program.name,
      renderCell: (program) => (
        <Link
          component={SafeRouterLink}
          to={SafeRoutePath.ResolvedDashboardProgramsProgram(program.id)}
          sx={{
            textDecoration: "none",
          }}
        >
          <Stack>
            <Typography variant="body2" fontWeight="bold">
              {program.name}
            </Typography>
            {program.description && <Typography mt={1} variant="body2" color="black">{program.description}</Typography>}
          </Stack>
        </Link>
      ),
    },
    {
      id: 'created',
      name: "Created",
      sortable: true,
      cellValue: (program) => program.created,
      renderCell: (program) => {
        return program.created ? <Timestamp timestamp={program.created} /> : <React.Fragment />
      },
    },
    {
      id: 'updated',
      name: "Updated",
      sortable: true,
      cellValue: (program) => program.modified,
      renderCell: (program) => {
        return program.modified ? <Timestamp timestamp={program.modified} /> : <React.Fragment />
      },
    },
    {
      id: 'options',
      renderHeaderCell: () => <></>,
      headerSx: {
        width: '1px',
        whiteSpace: 'nowrap'
      },
      renderCell: (program) => (
        <React.Fragment>
          <IconButton onClick={(event) => handleOpenOptionMenu(event, program.id)}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            sx={{ mt: "25px" }}
            anchorEl={anchorElOption}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={activeOptionMenuProgramId === program.id}
            onClose={handleCloseOptionMenu}
          >
            <MenuItem onClick={() => openCopyToClientPrompt(program)}>
              <Typography textAlign="center">Copy To Client</Typography>
            </MenuItem>
            <MenuItem onClick={() => navigate(SafeRoutePath.ResolvedDashboardProgramsProgram(program.id).path)}>
              <Typography textAlign="center">Edit</Typography>
            </MenuItem>
            <MenuItem onClick={() => setProgramToDelete(program)}>
              <Typography textAlign="center">Delete</Typography>
            </MenuItem>
          </Menu>
        </React.Fragment>
      ),
    },
  ]

  const [activeOptionMenuProgramId, setActiveOptionMenuProgramId] = useState<string | null>(null);
  const [anchorElOption, setAnchorElOption] = useState<HTMLElement | null>(null);
  const handleCloseOptionMenu = () => {
    setAnchorElOption(null);
    setActiveOptionMenuProgramId(null);
  }
  const handleOpenOptionMenu = (event: React.MouseEvent<HTMLElement>, programId: string) => {
    setAnchorElOption(event.currentTarget);
    setActiveOptionMenuProgramId(programId);
  }

  const [deletePromptOpen, setDeletePromptOpen] = useState(false);
  const [programToDelete, setProgramToDelete] = useState<Program>();
  const closeDeletePrompt = async (wasDeleted: boolean) => {
    if (wasDeleted) {
      setDeletedProgramIds((deletedProgramIds) => {
        if (programToDelete) {
          deletedProgramIds.add(programToDelete.id);
        }
        return deletedProgramIds;
      });
    }
    setDeletePromptOpen(false);
    setProgramToDelete(undefined);
  }
  useEffect(
    () => {
      handleCloseOptionMenu();
      setDeletePromptOpen(!!programToDelete);
    },
    [programToDelete],
  );

  const [copyToClientPromptOpen, setCopyToClientPromptOpen] = useState(false);
  const [programToCopy, setProgramToCopy] = useState<Program>();
  const openCopyToClientPrompt = (program: Program) => {
    handleCloseOptionMenu();
    setProgramToCopy(program);
    setCopyToClientPromptOpen(true);
  }
  const closeCopyToClientPrompt = async () => {
    setCopyToClientPromptOpen(false);
    await new Promise(r => setTimeout(r, 200));
    setProgramToCopy(undefined);
  }

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">
          Program Templates
        </Typography>
        <Button variant="contained" onClick={() => setCreateProgramOpen(true)}>
          Create Program
        </Button>
        <CreateProgramTemplateDialog open={createProgramOpen} onClose={() => setCreateProgramOpen(false)} />
      </Box>
      <ContentPaper sx={{ mb: 4 }}>
        {templatesLoading && templatesSkeleton}
        {!templatesLoading && programTemplates && programTemplates.length > 0 &&
          <DataTable
            data={programTemplates}
            columns={columns}
            rowKey={({ id }) => id}
            searchKeys={[
              { name: 'name', value: (program) => program.name, weight: 0.7 }, // Prioritize 'name' with a higher weight
              { name: 'description', value: (program) => program.description, weight: 0.3 }, // Lower weight for 'description'
            ]}
            defaultSortColumn='modified'
            defaultSortOrder={SortOrder.Desc}
            rowsPerPage={20}
          />
        }
        {!templatesLoading && (!programTemplates || programTemplates.length === 0) &&
          noProgramTemplates
        }
      </ContentPaper>
      {programToDelete &&
        <DeleteProgramTemplateDialog
          program={programToDelete}
          open={deletePromptOpen}
          onClose={closeDeletePrompt}
        />
      }
      {programToCopy &&
        <CopyToClientDialog
          program={programToCopy}
          open={copyToClientPromptOpen}
          onClose={closeCopyToClientPrompt}
        />
      }
    </React.Fragment>
  )
}

export default ProgramTemplates