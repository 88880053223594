import { useCallback, useState } from "react";
import { useDeleteClientInviteMutation, useInviteClientMutation } from "../../model/slices/clientsSlice"
import { useCurrentUser } from "../users/currentUserHook";
import { auth } from "../../config/firebase";
import { SentInviteData } from "../../model/types";

export const useInviteClient = () => {
  const {userData} = useCurrentUser(auth);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean | undefined>(undefined);
  const [inviteClientMutation, { error }] = useInviteClientMutation();

  const inviteClient = useCallback(
    async (sentInviteData: SentInviteData) => {
      if (!userData) {
        return;
      }
      setIsSuccess(undefined);
      setIsLoading(true);
      const result = await inviteClientMutation({userData, sentInviteData});
      setIsLoading(false);
      setIsSuccess("data" in result);
    },
    [userData, inviteClientMutation]
  );

  return {
    inviteClient,
    isLoading,
    isSuccess,
    error
  };
}

export const useDeleteClientInvite = () => {
  const {userData} = useCurrentUser(auth);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean | undefined>(undefined);
  const [deleteClientMutation, { error }] = useDeleteClientInviteMutation();

  const deleteClientInvite = useCallback(
    async (sentInviteData: SentInviteData): Promise<boolean> => {
      if (!userData) {
        return false;
      }
      setIsSuccess(undefined);
      setIsLoading(true);
      const result = await deleteClientMutation({userData, sentInviteData});
      setIsLoading(false);
      setIsSuccess("data" in result);
      return "data" in result;
    },
    [userData, deleteClientMutation]
  );

  return {
    deleteClientInvite,
    isLoading,
    isSuccess,
    error
  };
}