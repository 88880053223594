import { useEffect, useState } from 'react'
import LoadingSkeleton from "../../../../components/LoadingSkeleton"
import EditableText from "../EditableTypography"
import { auth } from "../../../../config/firebase"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import { Program } from "../../../../model/types"

type Props = {
  program?: Program,
  onNameChanged: (name: string) => void,
  onDescriptionChanged: (description: string | undefined) => void,
  isLoading?: boolean,
  editable?: boolean,
}

const NameDescription = (props: Props) => {
  const [displayName, setDisplayName] = useState<string | undefined>();
  const [displayDescription, setDisplayDescription] = useState<string | undefined>();

  const handleCommitName = () => {
    const uid = auth.currentUser?.uid;
    if (!props.program || !uid || displayName === props.program.name) {
      return;
    }

    // If empty, do nothing and reset back to the program name.
    if (!displayName) {
      setDisplayName(props.program.name);
      return;
    }

    props.onNameChanged(displayName);
  }

  const handleCommitDescription = () => {
    const uid = auth.currentUser?.uid;
    if (!props.program || !uid || displayDescription === (props.program.description ?? "")) {
      return;
    }
    props.onDescriptionChanged(displayDescription === "" ? undefined : displayDescription);
  }

  useEffect(
    () => {
      setDisplayName(props.program?.name);
      setDisplayDescription(props.program?.description);
    },
    [props.program]
  );

  return (
    <Box display="flex">
      <Stack flex="1">
        <LoadingSkeleton width="200px" height="60px" loading={props.isLoading ?? false}>
          {props.program?.name && !props.isLoading &&
            <EditableText
              variant="h5"
              value={displayName}
              onValueChange={(value) => setDisplayName(value)}
              onCommit={handleCommitName}
              editable={props.editable}
            />
          }
        </LoadingSkeleton>

        {(props.program?.description || props.editable) &&
          <LoadingSkeleton width="300px" height="30px" loading={props.isLoading ?? false}>
            <EditableText
              variant="body1"
              value={displayDescription}
              placeholder={displayDescription ? undefined : "Add description"}
              onValueChange={(value) => setDisplayDescription(value)}
              onCommit={handleCommitDescription}
              editable={props.editable}
            />
          </LoadingSkeleton>
        }
      </Stack>
    </Box>
  )
}

export default NameDescription