import Paper from "@mui/material/Paper"
import { ExerciseSet, ExerciseWithSets } from "../../../../../../model/types"
import ExerciseSummary from "./ExerciseSummary"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import React from "react"

type Props = {
  exercises: ExerciseWithSets<ExerciseSet>[],
}

const ExercisesSummary = (props: Props) => {
  return <Stack gap={2}>
    {props.exercises.map((exercise, index) => {
      return <Paper
        key={index}
        sx={{ p: 3 }}
      >
        <Stack key={index} gap={1}>
          <Typography fontWeight="bold">{exercise.name}</Typography>
          {exercise.notes && <Typography>{exercise.notes}</Typography>}
          <ExerciseSummary exercise={exercise} />
        </Stack>
      </Paper>
    })}
  </Stack>
}

export default ExercisesSummary