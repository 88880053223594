import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { collection, doc, getDoc, getDocs, orderBy, query, where } from "firebase/firestore";
import { auth, db, functions } from "../../config/firebase";
import { Program, ExerciseSet, Workout, ExerciseWithSets } from "../types";
import { getProgramTemplate } from "../services/templateService";
import { httpsCallable } from "firebase/functions";
import { programToRawData, workoutToRawData } from "../services/programService";

export const templatesApi = createApi({
  reducerPath: 'templates',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['programTemplates', 'programTemplate', 'workoutTemplate'],
  endpoints: (builder) => ({
    getProgramTemplates: builder.query<Program[], string | undefined>({
      async queryFn(arg) {
        return await getProgramTemplates(arg);
      },
      providesTags: ['programTemplates'],
    }),
    getProgramTemplate: builder.query<Program, string | undefined>({
      async queryFn(arg) {
        return await getProgramTemplate(arg);
      },
      providesTags: (_, __, arg) => [{ type: 'programTemplate', id: arg }],
    }),
    setProgramTemplate: builder.mutation<Program, Program>({
      async queryFn(arg) {
        return await setProgramTemplate(arg);
      },
      invalidatesTags: (result, __, arg) => [
        ...result ? ['programTemplates' as const] : [],
        ...result ? [{type: 'programTemplate' as const, id: arg.id}] : [],
      ],
    }),
    createProgramTemplate: builder.mutation<Program, CreateProgramTemplateArg>({
      async queryFn(arg) {
        return await createProgramTemplate(arg);
      },
      invalidatesTags: ['programTemplates'],
    }),
    deleteProgramTemplate: builder.mutation<null, string>({
      async queryFn(arg) {
        return await deleteProgramTemplate(arg);
      },
      invalidatesTags: (result, __, arg) => [
        ...result === null ? ['programTemplates' as const] : [],
        ...result === null ? [{type: 'programTemplate' as const, id: arg}] : [],
      ],
    }),
    getWorkoutTemplate: builder.query<Workout<ExerciseWithSets<ExerciseSet>>, string | undefined>({
      async queryFn(arg) {
        return await getWorkoutTemplate(arg);
      },
      providesTags: (_, __, arg) => [{ type: 'workoutTemplate', id: arg }],
    }),
    setWorkoutTemplate: builder.mutation<Workout<ExerciseWithSets<ExerciseSet>>, Workout<ExerciseWithSets<ExerciseSet>>>({
      async queryFn(arg) {
        return await setWorkoutTemplate(arg);
      },
      invalidatesTags: (_, __, arg) => [{ type: 'workoutTemplate', id: arg.id }],
    }),
    deleteWorkoutTemplate: builder.mutation<null, DeleteWorkoutTemplateArg>({
      async queryFn(arg) {
        return await deleteWorkoutTemplate(arg);
      },
      invalidatesTags: (_, __, arg) => [
        { type: 'workoutTemplate', id: arg.workoutTemplateId },
        ...arg.programTemplateId ? [{ type: 'programTemplate' as const, id: arg.programTemplateId }] : [],
      ],
    }),
    createTemplateFromProgram: builder.mutation<Program, CreateTemplateFromProgramArg>({
      async queryFn(arg) {
        return await createTemplateFromProgram(arg);
      },
      invalidatesTags: (newTemplate) => [
        ...newTemplate ? ['programTemplates' as const] : [],
        ...newTemplate ? [{type: 'programTemplate' as const, id: newTemplate.id}] : [],
      ],
    }),
  }),
});

const programTemplateWithNameExists = async (uid: string, name: string): Promise<boolean> => {
  const templatesRef = collection(db, 'programTemplates');
  var q = query(templatesRef, where("coachId", "==", uid));
  q = query(q, where("name", "==", name));
  const matchingNames = await getDocs(q);
  return matchingNames.size > 0;
}

type CreateProgramTemplateArg = {
  name: string,
  description?: string,
}
type CreateProgramReturnType = { data: Program } | { error: string };
const createProgramTemplate = async ({ name, description }: CreateProgramTemplateArg): Promise<CreateProgramReturnType> => {
  const uid = auth.currentUser?.uid;
  if (!uid) {
    return {
      error: "Authentication error",
    }
  }

  await new Promise(r => setTimeout(r, 2000));

  try {
    if (await programTemplateWithNameExists(uid, name)) {
      return {
        error: `'${name}' already exists, please choose a unique name.`,
      }
    }

    const date = new Date();
    const program: Program = {
      id: doc(collection(db, 'programTemplates')).id,
      name,
      ...description && {description},
      created: date,
      modified: date,
    }

    const writeProgramTemplateCallable = httpsCallable(functions, 'writeProgramTemplate');
    const writeProgramTemplateResponse = await writeProgramTemplateCallable({
      programTemplateId: program.id,
      programTemplate: programToRawData(program, uid),
    });
    return {
      data: (writeProgramTemplateResponse.data as any).template as Program,
    }
  }
  catch (error: any) {
    console.error(error);
    return {
      error: "Unable to create program",
    }
  }
}

type GetProgramTemplatesReturnType = { data: Program[] } | { error: any };
const getProgramTemplates = async (uid: string | undefined): Promise<GetProgramTemplatesReturnType> => {
  uid = auth.currentUser?.uid;
  if (!uid) {
    return {
      data: [],
    }
  }

  await new Promise(r => setTimeout(r, 500));

  try {
    const programTemplates: Program[] = [];
    var q = query(collection(db, 'programTemplates'), where('coachId', '==', uid));
    q = query(q, orderBy('modified', 'desc'));
    const snapshot = await getDocs(q);
    snapshot.forEach(doc => {
      programTemplates.push({
        id: doc.id,
        name: doc.data().name,
        description: doc.data().description,
        created: new Date(Date.parse(doc.data().created)),
        ...(doc.data().modified && { modified: new Date(Date.parse(doc.data().modified)) }),
        workouts: doc.data().workouts,
      });
    });
    return {
      data: programTemplates,
    }
  }
  catch (error: any) {
    console.error(error.message);
    return {
      error: error.message,
    }
  }
}

type SetProgramTemplateReturnType = { data: Program } | { error: string };
const setProgramTemplate = async (programTemplate: Program): Promise<SetProgramTemplateReturnType> => {
  await new Promise(r => setTimeout(r, 200));

  const uid = auth.currentUser?.uid;
  if (!uid) {
    return {
      error: "Authentication error",
    };
  }

  try {
    const writeProgramTemplateCallable = httpsCallable(functions, 'writeProgramTemplate');
    const writeProgramTemplateResponse = await writeProgramTemplateCallable({
      programTemplateId: programTemplate.id,
      programTemplate: programToRawData(
        {
          ...programTemplate,
          modified: new Date(),
        },
        uid,
      ),
    });
    return {
      data: (writeProgramTemplateResponse.data as any).template as Program,
    }
  }
  catch (error: any) {
    console.error(error.message);
    return {
      error: "Unable to save program",
    }
  }
}

type DeleteProgramTemplateReturnType = { data: null } | { error: string };
const deleteProgramTemplate = async (templateId: string): Promise<DeleteProgramTemplateReturnType> => {
  const uid = auth.currentUser?.uid;
  if (!uid) {
    return {
      error: "Authentication error",
    };
  }
 
  try {
    const deleteProgramTemplateCallable = httpsCallable(functions, 'deleteProgramTemplate');
    await deleteProgramTemplateCallable({
      programTemplateId: templateId,
    });
    return {
      data: null,
    }
  }
  catch (error: any) {
    console.error(error.message);
    return {
      error: "Unable to delete program",
    }
  }
}

type GetWorkoutTemplateReturnType = { data: Workout<ExerciseWithSets<ExerciseSet>> } | { error: any };
const getWorkoutTemplate = async (workoutId?: string): Promise<GetWorkoutTemplateReturnType> => {
  const uid = auth.currentUser?.uid;
  if (!uid) {
    return {
      error: "No user id",
    }
  }

  if (!workoutId) {
    return {
      error: "No workout id",
    }
  }

  await new Promise(r => setTimeout(r, 500));

  try {
    const docRef = doc(db, `workoutTemplates/${workoutId}`);
    const snapshot = await getDoc(docRef);
    const data = snapshot.data();
    if (!data) {
      return {
        error: `Couldn't find workout template with ID ${workoutId}`,
      }
    }

    const baseExercises: ExerciseWithSets<ExerciseSet>[] = [];
    if (data.baseExercises && Array.isArray(data.baseExercises)) {
      for (const exerciseData of data.baseExercises) {
        const sets: ExerciseSet[] = [];

        if (exerciseData.sets && Array.isArray(exerciseData.sets)) {
          for (const set of exerciseData.sets) {
            sets.push({
              ...set,
            });
          }
        }

        baseExercises.push({
          name: exerciseData.name,
          notes: exerciseData.notes,
          fields: new Set(exerciseData.fields),
          weightUnits: exerciseData.weightUnits,
          ...(sets.length > 0 && { sets: sets }),
        });
      }
    }

    return {
      data: {
        id: workoutId,
        program: {
          id: data.program.id,
          name: data.program.name,
          ...data.program.description && {description: data.program.description},
        },
        name: data.name,
        description: data.description,
        ...(baseExercises.length > 0 && { baseExercises: baseExercises }),
      },
    }
  }
  catch (error: any) {
    console.error(error.message);
    return {
      error: error.message,
    }
  }
}

type SetWorkoutTemplateReturnType = { data: Workout<ExerciseWithSets<ExerciseSet>> } | { error: string };
const setWorkoutTemplate = async (workoutTemplate: Workout<ExerciseWithSets<ExerciseSet>>): Promise<SetWorkoutTemplateReturnType> => {
  await new Promise(r => setTimeout(r, 500));

  const uid = auth.currentUser?.uid;
  if (!uid) {
    return {
      error: "Authentication error",
    };
  }

  if (!workoutTemplate.program || !workoutTemplate.program.id || !workoutTemplate.id) {
    console.error(`Program ID on program (${workoutTemplate.program}) or Workout ID (${workoutTemplate.id}) is invalid`);
    return {
      error: "No program or workout id",
    }
  }

  try {
    const writeWorkoutTemplateCallable = httpsCallable(functions, 'writeWorkoutTemplate');
    const writeWorkoutTemplateResponse = await writeWorkoutTemplateCallable({
      workoutTemplateId: workoutTemplate.id,
      workoutTemplate: workoutToRawData(workoutTemplate, uid),
    });
    return {
      data: (writeWorkoutTemplateResponse.data as any).template as Program,
    }
  }
  catch (error: any) {
    console.error(error.message);
    return {
      error: "Unable to save program",
    }
  }
}

type DeleteWorkoutTemplateArg = {
  workoutTemplateId: string,
  programTemplateId?: string,
}
type DeleteWorkoutTemplateReturnType = { data: null } | { error: string };
const deleteWorkoutTemplate = async (arg: DeleteWorkoutTemplateArg): Promise<DeleteWorkoutTemplateReturnType> => {
  const uid = auth.currentUser?.uid;
  if (!uid) {
    return {
      error: "Authentication error",
    };
  }

  try {
    const deleteWorkoutTemplateCallable = httpsCallable(functions, 'deleteWorkoutTemplate');
    await deleteWorkoutTemplateCallable({
      workoutTemplateId: arg.workoutTemplateId,
    });
    return {
      data: null,
    }
  }
  catch (error: any) {
    console.error(error.message);
    return {
      error: "Unable to delete workout template",
    }
  }
}

type CreateTemplateFromProgramArg = {
  programId: string,
  newTemplateName: string,
  newTemplateDescription?: string,
}
type CreateTemplateFromProgramReturnType = { data: Program } | { error: any };
const createTemplateFromProgram = async ({ programId, newTemplateName, newTemplateDescription }: CreateTemplateFromProgramArg): Promise<CreateTemplateFromProgramReturnType> => {
  await new Promise(r => setTimeout(r, 1000));

  const uid = auth.currentUser?.uid;
  if (!uid) {
    return {
      error: 'authentication error',
    }
  }

  try {
    if (await programTemplateWithNameExists(uid, newTemplateName)) {
      return {
        error: `'${newTemplateName}' already exists, please choose a unique name.`,
      }
    }

    const createTemplateFromProgramCallable = httpsCallable(functions, 'createTemplateFromProgram');
    const createTemplateResponse = await createTemplateFromProgramCallable({
      programId,
      newTemplateName,
      newTemplateDescription,
    });
    return {
      data: (createTemplateResponse.data as any).template as Program,
    }
  }
  catch (error: any) {
    return {
      error,
    }
  }
}

export const {
  useCreateProgramTemplateMutation,
  useGetProgramTemplatesQuery,
  useGetProgramTemplateQuery,
  useSetProgramTemplateMutation,
  useDeleteProgramTemplateMutation,
  useGetWorkoutTemplateQuery,
  useSetWorkoutTemplateMutation,
  useDeleteWorkoutTemplateMutation,
  useCreateTemplateFromProgramMutation,
} = templatesApi;