import IconButton from "@mui/material/IconButton"
import TextField from "@mui/material/TextField"
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

type Props = {
  value: string | null,
  onValueChanged: (value: string | null) => void,
}

const SearchField = (props: Props) => {
  return (
    <TextField
      id="searchField"
      value={props.value ?? ""}
      onChange={(e) => props.onValueChanged(e.currentTarget.value)}
      InputProps={{
        endAdornment: <IconButton
          edge="end"
          onClick={() => {
            if (props.value) {
              props.onValueChanged?.(null)
            }
          }}
        >{props.value
          ? <ClearIcon sx={{ opacity: 0.5, width: 18 }} />
          : <SearchIcon sx={{ opacity: 0.5, width: 18 }} />
          }
        </IconButton>
      }}
      size="small"
      sx={{
        mb: 1,
      }}
      placeholder="Search"
      autoComplete="off"
    />
  )
}

export default SearchField