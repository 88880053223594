import React, { useEffect, useRef, useState } from "react"
import ScrollToTopOnMount from "../components/ScrollToTopOnMount"
import Page from "../components/Page"
import Container from "@mui/material/Container"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import FormControl from "@mui/material/FormControl"
import Box from "@mui/material/Box"
import FormLabel from "@mui/material/FormLabel"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Radio from '@mui/material/Radio';
import TextField from "@mui/material/TextField"
import LoadingButton from "@mui/lab/LoadingButton"
import { Controller, useForm } from "react-hook-form"
import ReCAPTCHA from "react-google-recaptcha"
import { useJoinWaitlist } from "../hooks/joinWaitlist"
import useMediaQuery from "@mui/material/useMediaQuery"
import { theme } from "../theme"

type WaitlistFormData = {
  role: string,
  name: string,
  email: string,
  instagram?: string,
  comments?: string,
}

const Waitlist = () => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm<WaitlistFormData>();

  const onSubmit = async (data: WaitlistFormData) => {
    try {
      setIsCaptureVerifying(true);
      const token = await recaptchaRef.current?.executeAsync();
      setIsCaptureVerifying(false);
      if (!token) {
        return;
      }
      
      joinWaitlist({
        ...data,
        recaptchaToken: token,
      });
      
      recaptchaRef.current?.reset();
    }
    catch (error) {
      setIsCaptureVerifying(false);
      console.error(error);
    }
  }

  const [isCaptureVerifying, setIsCaptureVerifying] = useState<boolean>(false);
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const {
    joinWaitlist,
    isSuccess: isJoinWaitListSuccess,
    isLoading: isJoinWaitListLoading,
    error: joinWaitlistError
  } = useJoinWaitlist();

  const [formIsLoading, setFormIsLoading] = useState(false);

  useEffect(
    () => {
      setFormIsLoading(isCaptureVerifying || isJoinWaitListLoading);
    },
    [isCaptureVerifying, isJoinWaitListLoading]
  )

  useEffect(
    () => {
      if (isJoinWaitListSuccess) {
        reset();
      }
    },
    [isJoinWaitListSuccess, reset],
  );

  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <React.Fragment>
      <ScrollToTopOnMount />
      <Page>
        <Container>
          <Paper sx={{
            p: 3,
            mx: { md: 3 },
            my: 3,
          }}>
            <Typography variant="h4" mb={3}>
              Join The Waitlist
            </Typography>

            {isJoinWaitListSuccess &&
              <React.Fragment>
                <Typography py={1} fontWeight="bold">
                  Thank you for joining the Spotter waitlist!
                </Typography>
                  
                <Typography py={1}>
                  You're now on board for exclusive updates, early access opportunities, and the latest news about our platform's launch and features. Stay tuned for exciting developments as we revolutionize fitness coaching together.
                </Typography>

                <Typography py={1}>
                  Welcome to the Spotter community!
                </Typography>
              </React.Fragment>
            }

            {!isJoinWaitListSuccess &&
              <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>

                <Typography pb={4}>
                  Be among the first to experience fitness coaching reinvented! Join the Spotter waitlist and receive exclusive updates, early access opportunities, and firsthand information about our platform's launch and features.
                </Typography>

                {joinWaitlistError &&
                  <Typography pb={2} variant="body2" color="error">
                    {joinWaitlistError}
                  </Typography>
                }

                <Controller
                  name="role"
                  rules={{ required: true }}
                  control={control}
                  defaultValue=""
                  render={({
                    field,
                    fieldState,
                  }) => (
                    <FormControl>
                      <FormLabel error={!!fieldState.error}>I am</FormLabel>
                      <RadioGroup row={!onlySmallScreen} {...field}>
                        <FormControlLabel
                          disabled={formIsLoading}
                          value="coach"
                          control={<Radio />}
                          label={
                            <Typography color={fieldState.error && "error"}>
                              A coach
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          disabled={formIsLoading}
                          value="client"
                          control={<Radio />}
                          label={
                            <Typography color={fieldState.error && "error"}>
                              Looking for a coach
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          disabled={formIsLoading}
                          value="other"
                          control={<Radio />}
                          label={
                            <Typography color={fieldState.error && "error"}>
                              Just interested in Spotter
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />

                <TextField
                  margin="normal"
                  fullWidth
                  id="name"
                  label="Name"
                  autoComplete="name"
                  {...register("name", {
                    required: true,
                    maxLength: 120,
                  })}
                  error={!!errors.name}
                  helperText={errors.name && "Name cannot be empty and must be valid"}
                  disabled={formIsLoading}
                />

                <TextField
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Email"
                  autoComplete="email"
                  {...register("email", {
                    required: true,
                    maxLength: 120,
                    pattern: /^\S+@\S+$/i,
                  })}
                  error={!!errors.email}
                  helperText={errors.email && "Email cannot be empty and must be valid"}
                  disabled={formIsLoading}
                />

                <TextField
                  margin="normal"
                  fullWidth
                  id="instagram"
                  label="(Optional) Instagram Username"
                  autoComplete="instagram"
                  {...register("instagram", {
                    maxLength: 120,
                  })}
                  error={!!errors.instagram}
                  helperText={errors.instagram && "Instagram username must be valid"}
                  disabled={formIsLoading}
                />

                <TextField
                  margin="normal"
                  fullWidth
                  multiline
                  rows={2}
                  id="comments"
                  label="(Optional) Any comments, requests, questions, etc."
                  autoComplete="comments"
                  {...register("comments")}
                  error={!!errors.comments}
                  disabled={formIsLoading}
                />

                <ReCAPTCHA
                  sitekey="6LcSjBopAAAAACRV01Cdz1kG7BKaqBSsBAss0ZHz"
                  size="invisible"
                  ref={recaptchaRef}
                />

                <LoadingButton
                  id="joinButton"
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ my: 2 }}
                  loading={formIsLoading}
                >
                  Join
                </LoadingButton>
              </Box>
            }


          </Paper>
        </Container>
      </Page>
    </React.Fragment>
  );
}

export default Waitlist;