import React from 'react'
import DashboardAppBar from "../../components/DashboardAppBar"
import Content from "../../components/Content"
import Link from "@mui/material/Link"
import ScrollToTopOnMount from "../../../../components/ScrollToTopOnMount"

const Billing = () => {
  return (
    <React.Fragment>
      <ScrollToTopOnMount />
      <DashboardAppBar title="Billing" />
      <Content>
        <Link>Billing</Link>
      </Content>
    </React.Fragment>
  )
}

export default Billing