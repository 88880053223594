import Box from "@mui/material/Box"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Typography from "@mui/material/Typography"
import { Program } from "../../../../model/types"
import Button from "@mui/material/Button"
import LoadingButton from "@mui/lab/LoadingButton"
import { useGetClientMetadatas } from "../../../../hooks/clients/clientHooks"
import MenuItem from "@mui/material/MenuItem"
import { useForm } from "react-hook-form"
import TextField from "@mui/material/TextField"
import Grid from "@mui/material/Grid"
import Collapse from "@mui/material/Collapse"
import Stack from "@mui/material/Stack"
import Divider from "@mui/material/Divider"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Link from "@mui/material/Link"
import { ExpandMore } from "../../components/ExpandMore"
import { useEffect, useState } from "react"
import { useAddOutgoingProgram } from "../../../../hooks/programs/addOutgoingProgramHook"
import { auth } from "../../../../config/firebase"

type Props = {
  program?: Program,
  open: boolean,
  onClose: () => void,
}

type FormData = {
  clientId: string,
  programName: string,
  programDescription: string,
}

const CopyToClientDialog = (props: Props) => {
  const { data: clientMetadatas, isLoading: clientsLoading } = useGetClientMetadatas();
  const { register, handleSubmit, watch, formState: { errors } } = useForm<FormData>();
  const clientId = watch("clientId");
  const {addOutgoingProgram, isLoading: addOutgoingLoading, error: addOutgoingError, isSuccess: addOutgoingSuccess} = useAddOutgoingProgram();

  const onSubmit = async (data: FormData) => {
    const coachId = auth.currentUser?.uid;
    if (!props.program || !coachId) {
      return;
    }
    const clientId = data.clientId;
    const programId = props.program.id;
    const programName = data.programName.length > 0 ? data.programName : props.program.name;
    const programDescription = data.programDescription;
    addOutgoingProgram({
      coachId,
      clientId,
      programId,
      programName,
      programDescription,
    });
  }

  const [optionalFieldsExpanded, setOptionalFieldsExpanded] = useState(false);

  useEffect(
    () => {
      if (addOutgoingSuccess) {
        props.onClose();
      }
    },
    [addOutgoingSuccess, props],
  )

  return (
    <Dialog
      maxWidth="md"
      open={props.open}
      onClose={props.onClose}
    >
      <DialogTitle>
        Copy program to a client
      </DialogTitle>
      <DialogContent>
        <Box minWidth="300px" pt={2}>
          {!!addOutgoingError &&
            <Typography variant="body2" color="error" mb={2}>
              There was a problem copying the program.
            </Typography>
          }
          <Typography>
            Sending a copy of <Box component="span" fontWeight='fontWeightMedium'>{props.program?.name}</Box> to a client.
          </Typography>

          <Box mt={3} component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Stack>
                {!clientsLoading && clientMetadatas &&
                  <TextField
                    select
                    fullWidth
                    label="Client"
                    defaultValue=""
                    inputProps={register("clientId", { required: true })}
                    error={!!errors.clientId}
                    helperText={errors.clientId?.message}
                  >
                    {clientMetadatas?.map((client) => (
                      <MenuItem key={client.id} value={client.id}>
                        {client.firstName} {client.lastName}
                      </MenuItem>
                    ))}
                  </TextField>
                }

                <Collapse in={!!clientId}>
                <Divider sx={{ my: 3 }} />
                  <Box display="flex" alignItems="center">
                    <ExpandMore
                      expand={optionalFieldsExpanded}
                      onClick={() => setOptionalFieldsExpanded(!optionalFieldsExpanded)}
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                    <Link component="button" onClick={
                      (event) => {
                        event.preventDefault();
                        setOptionalFieldsExpanded(!optionalFieldsExpanded)
                      }
                    }>
                      (Optional) Customize details
                    </Link>
                  </Box>
                  <Collapse in={optionalFieldsExpanded}>
                    <Grid gap={1}>
                      <TextField
                        fullWidth
                        label="Program Name"
                        margin="normal"
                        defaultValue={props.program?.name}
                        {...register("programName", {
                          required: true,
                          maxLength: 200,
                        })}
                        error={!!errors.programName}
                        helperText={errors.programName?.message}
                      />
                      <TextField
                        fullWidth
                        multiline
                        label="Program Description"
                        rows={2}
                        margin="normal"
                        defaultValue={props.program?.description}
                        {...register("programDescription", {
                          maxLength: 300,
                        })}
                        error={!!errors.programDescription}
                        helperText={errors.programDescription?.message}
                      />
                    </Grid>
                  </Collapse>
                </Collapse>
              </Stack>
            </Grid>

            <Box mt={2} display="flex" gap={1} justifyContent="flex-end">
              <Button
                disabled={addOutgoingLoading}
                onClick={props.onClose}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={addOutgoingLoading}
                variant="contained"
                type="submit"
                disabled={!clientId}
              >
                Copy
              </LoadingButton>
            </Box>

          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default CopyToClientDialog