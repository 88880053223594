import { reauthenticateWithCredential, updatePassword, EmailAuthProvider, User } from "firebase/auth";
import { useCallback, useState } from "react";

export const useUpdatePassword = (user: User | null | undefined) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);

  const updatePasswordCallback = useCallback(
    async (currentPassword: string, newPassword: string) => {
      setIsSuccess(false);
      setError(undefined);
      setIsLoading(true);

      if (!user || !user.email) {
        setError("Couldn't authenticate current user");
        setIsLoading(false);
        return;
      }

      try {
        const credential = EmailAuthProvider.credential(
          user.email,
          currentPassword
        );
        await reauthenticateWithCredential(user, credential);
      }
      catch (error: any) {
        console.error(error.message);
        setError("Provided password did not match existing password.");
        setIsSuccess(false);
        setIsLoading(false);
        return;
      }

      try {
        await updatePassword(user, newPassword);
        await new Promise(r => setTimeout(r, 1000));
      }
      catch (error: any) {
        console.error(error.message);
        setError("Couldn't update password");
        setIsSuccess(false);
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
      setIsSuccess(true);
    },
    [user]
  );

  return {
    updatePasword: updatePasswordCallback,
    isLoading,
    isSuccess,
    error,
  };
}