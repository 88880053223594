import Typography from "@mui/material/Typography";
import { useGetWorkoutQuery } from "../../../../../../model/slices/programsSlice";
import WorkoutBuilder, { WorkoutBuilderProps } from "../../../../components/ProgramBuilder/WorkoutBuilder"
import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { theme } from "../../../../../../theme";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { ClientData } from "../../../../../../model/types";
import ExercisesSummary from "./ExercisesSummary";
import LoadingSkeleton from "../../../../../../components/LoadingSkeleton";
import Skeleton from "@mui/material/Skeleton";
import TableLoading from "../../../../components/TableLoading";

type Props = WorkoutBuilderProps & {
  client: ClientData,
}

enum WorkoutTab {
  Performed,
  Programmed,
}

const ClientWorkoutDialogContents = (props: Props) => {
  const {data: workout, error: getWorkoutError} = useGetWorkoutQuery(props.workoutId);
  const [tab, setTab] = useState(WorkoutTab.Performed);

  // If the workout is still loading, the WorkoutBuilder will also show the skeleton.
  if (!workout?.finished) {
    return <WorkoutBuilder
      workoutId={props.workoutId}
      program={props.program}
      workoutRepository={props.workoutRepository}
      onWorkoutSaved={props.onWorkoutSaved}
    />
  }

  return <React.Fragment>
    <Stack mb={1}>
      <Tabs
        value={tab}
        onChange={(_, newValue) => {
          setTab(newValue);
        }}
        TabIndicatorProps={{
          style: {
            backgroundColor: theme.palette.primary.main,
          }
        }}
      >
        <Tab value={WorkoutTab.Performed} label={`${props.client.firstName} ${props.client.lastName}'s Workout`} />
        <Tab value={WorkoutTab.Programmed} label="Original" />
      </Tabs>
      <Divider />
    </Stack>

    <Stack p={2} gap={1}>
      <Typography variant="h5">{workout.name}</Typography>

      {workout.description && <Typography>{workout.description}</Typography>}

      <Box my={1}>
        {workout.performedExercises && tab === WorkoutTab.Performed && <ExercisesSummary exercises={workout.performedExercises} />}
        {workout.baseExercises && tab === WorkoutTab.Programmed && <ExercisesSummary exercises={workout.baseExercises} />}
      </Box>
    </Stack>
  </React.Fragment>
}

export default ClientWorkoutDialogContents