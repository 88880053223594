import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import { WorkoutMetadata } from "../../../../../model/types";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import Stack from "@mui/material/Stack";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TimeagoTooltip from "../../TimeagoTooltip";

type Props = {
  workoutMetadata: WorkoutMetadata,
  onClick?: () => void,
  onDelete?: () => void,
  editable: boolean,
}

const WorkoutListItem = (props: Props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: props.workoutMetadata.id});

  const [anchorElOption, setAnchorElOption] = useState<null | HTMLElement>(null);

  const handleOpenOptionMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElOption(event.currentTarget);
  }

  const handleCloseOptionMenu = () => {
    setAnchorElOption(null);
  }

  return (
    <Card
      ref={setNodeRef}
      {...attributes}
      sx={{
        transform: CSS.Translate.toString(transform),
        transition,
        p: 1,
        pr: 2,
        my: 1,
        // border: !props.editable && props.workoutMetadata.finished ? "1px solid" : undefined,
        // borderColor: !props.editable && props.workoutMetadata.finished ? (theme) => theme.palette.success.light : undefined,
        // bgcolor: !props.editable && props.workoutMetadata.finished ? "#e4efda" : undefined,
      }}
    >
      <Box display="flex" gap={1} alignItems="center">
        {props.editable &&
          <DragIndicatorIcon
              {...listeners}
              sx={{
                cursor: "grab",
                ":active": {
                  cursor: "grabbing",
                },
                color: (theme) => theme.palette.grey[400],
              }}
            />
        }

        <Stack gap={1} flex="1" p={2}>
          <Box display="inline-flex">
            <Link component="button" onClick={() => props.onClick?.()}>
              <Typography fontWeight="bold">
              {props.workoutMetadata.name}
              </Typography>
            </Link>
          </Box>
          {props.workoutMetadata.description &&
            <Typography>
              {props.workoutMetadata.description}
            </Typography>
          }
          {props.workoutMetadata.exercises &&
            <Typography
              variant="body2"
              color="grey"
            >
              {props.workoutMetadata.exercises.join(', ')}
            </Typography>
          }
        </Stack>

        {!props.editable && props.workoutMetadata.finished &&
          <Box p={3}>
            <TimeagoTooltip prefix="Completed" date={props.workoutMetadata.finished}>
              <Typography variant="h4" component="p">
              <CheckCircleIcon color="success" fontSize="inherit" />
              </Typography>
            </TimeagoTooltip>
          </Box>
        }

        {props.editable &&
          <IconButton onClick={(event) => handleOpenOptionMenu(event)}>
            <MoreVertIcon />
          </IconButton>
        }

        <Menu
          sx={{ mt: "25px" }}
          anchorEl={anchorElOption}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={!!anchorElOption}
          onClose={handleCloseOptionMenu}
        >
          <MenuItem onClick={() => props.onClick?.()}>
            <Typography textAlign="center">Edit</Typography>
          </MenuItem>
          <MenuItem onClick={() => props.onDelete?.()}>
            <Typography textAlign="center">Delete</Typography>
          </MenuItem>
        </Menu>

      </Box>
    </Card>
  )
}

export default WorkoutListItem