import InputAdornment from "@mui/material/InputAdornment"
import ValidateTextField from "../../../../../../components/ValidateTextField"
import { WeightUnits } from "../../../../../../model/types"
import { isInteger, isNumber, maximumNumberValue, minimumNumberValue } from "./fieldValidators"

type Props = {
  weight: number | null | undefined,
  weightUnits?: WeightUnits,
  onWeightChange: (weight: number | null) => void,
}

const WeightField = (props: Props) => {
  return (
    <ValidateTextField
      defaultValue={props.weight === null ? "" : props.weight}
      type="number"
      label="Weight"
      size="small"
      validators={[
        isNumber, minimumNumberValue(-1e3), isInteger, maximumNumberValue(1e6),
      ]}
      onValidatedChange={(value) => props.onWeightChange(value !== "" ? Number(value) : null)}
      InputProps={{
        endAdornment: <InputAdornment position="end">{props.weightUnits}</InputAdornment>,
      }}
    />
  )
}

export default WeightField