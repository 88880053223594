import Hero from "./Hero"
import InfoCards from "./InfoCards"
import FlexContainer from "../../components/FlexContainer"
import HomeNavbar from "../../components/HomeNavbar"
import Footer from "../../components/Footer"
import { useRef } from "react"
import FrequentlyAskedQuestions from "./FrequentlyAskedQuestions"

const LandingPage = () => {
  const ref = useRef<null | HTMLDivElement>(null);

  return (
    <FlexContainer>
      <HomeNavbar />
      <Hero onFindOutMoreClicked={() => ref.current?.scrollIntoView({behavior: "smooth"})} />
      <div ref={ref} style={{ scrollMarginTop: "40px" }}>
        <InfoCards />
      </div>
      <FrequentlyAskedQuestions />
      <Footer />
    </FlexContainer>
  )
}

export default LandingPage