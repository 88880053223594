import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box"
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Copyright from "./Copyright";
import SafeRouterLink, { RoutePath } from "./SafeRouterLink";

const Footer = () => {
  const theme = useTheme();

  return (
    <Box mt="auto">
      <Box p={4} mt={4} sx={{
        backgroundColor: theme.palette.primary.dark,
      }}>
        <Container sx={{ color: "white", display: { md: "flex" }, justifyContent: "space-between" }}>

          <Copyright />

          <Stack direction="row" spacing={2}>
            <Typography variant="body2">
              <Link component={SafeRouterLink} color="inherit" variant="inherit" to={RoutePath.Terms}>Terms</Link>
            </Typography>
            <Typography variant="body2">
              <Link component={SafeRouterLink} color="inherit" variant="inherit" to={RoutePath.Privacy}>Privacy Policy</Link>
            </Typography>
            <Typography variant="body2">
              <Link component={SafeRouterLink} color="inherit" variant="inherit" to={RoutePath.Contact}>Contact</Link>
            </Typography>
          </Stack>

        </Container>
      </Box>
    </Box>
  )
}

export default Footer