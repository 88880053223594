import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import barbell from "./images/barbell.jpg";
import devicesPreview from "./images/devices_preview.png";
import SafeRouterLink, { RoutePath } from "../../../components/SafeRouterLink";

type Props = {
  onFindOutMoreClicked?: () => void,
}

const Hero = (props: Props) => {
  return (
    <Box display="flex" mb={2} alignItems="stretch" boxShadow={2} minHeight="66vh" width="100%" sx={{
      backgroundImage: `linear-gradient(180deg, rgba(66, 66, 74, 0.5), rgba(25, 25, 25, 0.8)), url(${barbell})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    }}>
      <Container disableGutters sx={{ color: "white", display: "flex", justifyItems: "stretch" }}>
        <Grid container>
          <Grid display="flex" alignItems="center" item xs={12} md={5.5}>
            <Stack spacing={5} sx={{
              p: { xs: 4 }
            }}>
              <Typography component="h1" variant="h2">
                Elevate your coaching journey
              </Typography>
              <Typography>
                Welcome to Spotter, the free online strength coaching platform, bridging the gap between coaches and clients for a tailored workout experience.
              </Typography>
              <Typography>
                Our streamlined app empowers coaches to craft personalized programs and effortlessly deliver them to clients, who can access and complete workouts at their convenience.
              </Typography>
              <Stack spacing={2} direction="row">
                <Button
                  color="secondary"
                  variant="contained"
                  id="joinWaitlistButton"
                  component={SafeRouterLink}
                  to={RoutePath.Waitlist}
                >
                  Join the waitlist
                </Button>
                <Button color="secondary" onClick={props.onFindOutMoreClicked} id="findOutMoreButton">
                  Find out more
                </Button>
              </Stack>
            </Stack>
          </Grid>

          <Grid item md={6.5} sx={{
            display: { xs: "none", md: "flex" },
            backgroundImage: `url(${devicesPreview})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}>
          </Grid>

          <Grid item xs={12} sx={{
            display: { xs: "flex", md: "none" },
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}>
            <img alt="Spotter Preview on Devices" src={devicesPreview} width="100%" style={{ objectFit: "cover", }} />
          </Grid>

        </Grid>
      </Container>
    </Box>
  )
}

export default Hero