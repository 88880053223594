import { developmentMode } from "../config/developmentMode";
import { emulatorFunctionsUrl } from "../config/firebase";
import axios from "axios";

type SaveMessageArgType = {
  name: string,
  email: string,
  message: string,
  recaptchaToken: string,
}

type SaveMessageReturnType = {
  success: boolean,
  error?: string,
}

const url = developmentMode ? `${emulatorFunctionsUrl}/sendContactFormMessage` : 'https://sendcontactformmessage-uawjeb4cva-ew.a.run.app';

export const saveMessage = async (data: SaveMessageArgType): Promise<SaveMessageReturnType> => {
  try {
    const response = await axios.post(
      url,
      {
        data,
      },
    );
    if (response.status === 200) {
      return {
        success: true,
      }
    }
    else {
      console.error(response.data);
      return {
        success: false,
        error: "There was an error sending your message, please try again."
      }
    }
  }
  catch (error: any) {
    console.error(error.message);
    return {
      success: false,
      error: "There was an error sending your message, please try again."
    }
  }
}