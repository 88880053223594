import Box from "@mui/material/Box"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import LoadingButton from "@mui/lab/LoadingButton"
import { ClientData, Program } from "../../../../../../model/types"
import { useSendProgram } from "../../../../../../hooks/programs/sendProgramHook"

type Props = {
  client: ClientData,
  program: Program,
  open: boolean,
  onClose: () => void,
}

const SendProgramDialog = (props: Props) => {
  const {
    sendProgram,
    isLoading: createLoading,
    error: createError
  } = useSendProgram();

  const handleSubmit = async () => {
    const program = await sendProgram(props.program.id);
    if (program) {
      props.onClose();
    }
  }

  return (
    <Dialog
      maxWidth="sm"
      open={props.open}
      onClose={props.onClose}
    >
      <DialogTitle>
        Send Program
      </DialogTitle>
      <DialogContent>
        <Box minWidth="300px">
          {!!createError &&
            <Typography variant="body2" color="error" mb={2}>
              There was a problem sending the program.
            </Typography>
          }
          <Typography mb={2}>
            Sending <Box component="span" fontWeight='fontWeightMedium'>{props.program.name}</Box>.
          </Typography>
          <Typography mb={2}>
            <Box component="span" fontWeight='fontWeightMedium'>{props.client.firstName} {props.client.lastName}</Box> will be notified and able to start the program.
          </Typography>
          <Box mt={3} display="flex" gap={1} justifyContent="flex-end">
            <Button onClick={() => props.onClose()} disabled={createLoading}>
              Cancel
            </Button>
            <LoadingButton
              loading={createLoading}
              variant="contained"
              type="submit"
              onClick={handleSubmit}
            >
              Send
            </LoadingButton>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default SendProgramDialog
