import { useCallback, useEffect, useState } from "react";
import { Program } from "../../model/types";
import { useCreateTemplateFromProgramMutation } from "../../model/slices/templatesSlice";

export const useCreateTemplateFromProgram = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean | undefined>(undefined);
  const [createTemplateFromProgramMutation, { error }] = useCreateTemplateFromProgramMutation();
  const [errorString, setErrorString] = useState<string>();

  const createTemplateFromProgram = useCallback(
    async ({
      programId,
      newTemplateName,
      newTemplateDescription,
    }: {
      programId: string,
      newTemplateName: string,
      newTemplateDescription?: string,
  }): Promise<Program | undefined> => {
      setIsSuccess(undefined);
      setIsLoading(true);
      const result = await createTemplateFromProgramMutation({programId, newTemplateName, newTemplateDescription});
      setIsLoading(false);
      if ('data' in result) {
        setIsSuccess(true);
        return result.data;
      }
      else {
        setIsSuccess(false);
        return undefined;
      }
    },
    [createTemplateFromProgramMutation],
  );

  useEffect(
    () => {
      if (error) {
        console.error(error);
        if (typeof error === 'string') {
          setErrorString(error as string);
        }
        else {
          setErrorString("An error occurred");
        }
      }
      else {
        setErrorString(undefined);
      }
    },
    [error],
  );

  return {
    createTemplateFromProgram,
    isLoading,
    isSuccess,
    error: errorString,
  };
}