import { useEffect, useReducer, useState } from "react";
import { ExerciseSet, ExerciseWithSets, Program, Workout } from "../../model/types";
import { programReducer, ActionType } from "../../model/reducers/programReducer";
import { useDebouncedCallback } from "use-debounce";
import { WorkoutRepository } from "../../model/repositories/workoutRepository";
import { ProgramRepository } from "../../model/repositories/programRepository";
var deepEqual = require('deep-equal');

type Props = {
  programId: string,
  programRepository: ProgramRepository,
  workoutRepository: WorkoutRepository,
};

const useProgramBuilder = (props: Props) => {
  const [program, dispatch] = useReducer(programReducer, undefined);

  // Represents the current state of the program in the remote repository.
  // Loaded when first mounted by using the programId and then it is
  // updated after each save.
  const [loadedProgram, setLoadedProgram] = useState<Program>();

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Set the loadedProgram on first render or when there's a change of IDs.
  useEffect(
    () => {
      if (!loadedProgram || loadedProgram.id !== props.programId) {
        setIsLoading(true);
        const loadProgramFromRemote = async () => {
          const response = await props.programRepository.getProgram(props.programId);
          if (typeof response === 'string') {
            setError(response);
            setIsLoading(false);
          }
          else {
            setLoadedProgram(response);
          }
        }
        loadProgramFromRemote();
      }
    },
    [loadedProgram, props.programId, props.programRepository],
  );

  // When the program is loaded, set the program in the reducer.
  useEffect(
    () => {
      if (loadedProgram && !program) {
        dispatch({type: ActionType.LoadProgram, program: loadedProgram});
      }
    },
    [loadedProgram, program],
  );

  // When the program has been set in the reducer, set to loaded.
  useEffect(
    () => {
      if (program && isLoading) {
        setIsLoading(false);
      }
    },
    [program, isLoading],
  );

  const saveProgramToRemote = async (program: Program) => {
    setIsSaving(true);
    const error = await props.programRepository.saveProgram(program);
    if (error && loadedProgram) {
      setError(error);
      dispatch({type: ActionType.LoadProgram, program: loadedProgram});
    }
    else {
      setError(null);
      setLoadedProgram(program);
    }
    setIsSaving(false);
  };

  const debouncedSaveProgram = useDebouncedCallback(saveProgramToRemote, 1000);

  useEffect(
    () => {
      // Only save when there is a change in the program.
      if (deepEqual(loadedProgram, program) || !program) {
        return;
      }
      debouncedSaveProgram(program);
    },
    [program, loadedProgram, debouncedSaveProgram],
  );

  const editName = (name: string) => {
    if (name === "") {
      setError("Name cannot be empty");
      return;
    }
    dispatch({type: ActionType.EditName, name: name});
  };

  const editDescription = (description: string | null) => dispatch({type: ActionType.EditDescription, description});

  const moveWorkout = (fromIndex: number, toIndex: number) => {
    if (!program?.workouts) {
      return;
    }
    if (fromIndex < 0 || fromIndex >= program.workouts.length || toIndex < 0 || toIndex >= program.workouts.length) {
      setError("Could not move workout");
      return;
    }
    dispatch({type: ActionType.MoveWorkout, fromIndex, toIndex});
  }

  const editWorkoutMetadata = (workoutId: string, workout: Workout<ExerciseWithSets<ExerciseSet>>) => dispatch({type: ActionType.EditWorkoutMetadata, workoutId, workout});

  const setWorkoutMetadata = (workoutId: string, workout: Workout<ExerciseWithSets<ExerciseSet>>, addIfNotExists: boolean = false) => {
    const index = program?.workouts?.findIndex((w) => w.id === workoutId) ?? -1;
    if (index >= 0) {
      editWorkoutMetadata(workoutId, workout);
    }
    else if (addIfNotExists) {
      dispatch({type: ActionType.AddWorkoutMetadata, workout});
    }
    else {
      setError(`workout with id ${workoutId} does not exist`);
    }
  }

  const deleteWorkout = (workoutId: string) => {
    if (!program?.workouts) {
      return;
    }
    const index = program.workouts.findIndex((w) => w.id === workoutId);
    if (index < 0) {
      setError("Could not delete workout");
      return;
    }
    dispatch({type: ActionType.DeleteWorkoutMetadata, workoutId});
    props.workoutRepository.deleteWorkout(program.workouts[index].id);
  }

  return {
    program,

    editName,
    editDescription,

    setWorkoutMetadata,
    moveWorkout,
    deleteWorkout,

    isLoading,
    isSaving,
    error,
  }
};

export type UseProgramBuilderReturnType = ReturnType<typeof useProgramBuilder>;

export default useProgramBuilder;