import AppBar from "@mui/material/AppBar"
import Grid from "@mui/material/Grid"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import React from "react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import { RoutePath, SafeRoutePath } from "../../../components/SafeRouterLink"
import { Link, useLocation } from "react-router-dom"
import LoadingSkeleton from "../../../components/LoadingSkeleton"

type NavLink = {
  title: string,
  path: RoutePath | SafeRoutePath,
}

type Props = {
  title: string | undefined,
  loading?: boolean,
  navigation?: NavLink[],
}

const DashboardAppBar = (props: Props) => {
  const location = useLocation();

  const tabIndex = props.navigation?.findIndex(navLink =>
    typeof navLink.path === "string"
      ? location.pathname.startsWith(navLink.path)
      : location.pathname.startsWith(navLink.path.path)
  );

  return (
    <React.Fragment>
      <AppBar
        component="div"
        color="primary"
        position="relative"
        elevation={0}
        sx={{ zIndex: 0 }}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <LoadingSkeleton width={300} height={60} loading={props.loading ?? false}>
                <Typography color="inherit" variant="h5" component="h1">
                  {props.title}
                </Typography>
              </LoadingSkeleton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {props.navigation &&
        <AppBar component="div" position="static" elevation={0} sx={{ zIndex: 0 }}>
          <LoadingSkeleton
            loading={props.loading ?? false}
            width="250px"
            height="50px"
            sx={{ ml: 2 }}
          >
            <Tabs
              value={tabIndex === -1 ? 0 : tabIndex}
              textColor="inherit"
              sx={{ ml: 1 }}
            >
              {props.navigation.map(
                navLink =>
                  <Tab
                    key={navLink.title}
                    label={navLink.title}
                    component={Link}
                    to={typeof navLink.path === "string" ? navLink.path : navLink.path.path}
                  />
              )}
            </Tabs>
          </LoadingSkeleton>
        </AppBar>
      }
    </React.Fragment>
  )
}

export default DashboardAppBar