import {useSortable} from '@dnd-kit/sortable';
import { useEffect, useReducer, useRef, useState } from "react";
import {CSS} from '@dnd-kit/utilities';
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { ExerciseField, WeightUnits, IdentifiableExerciseSet, IdentifiableExercise } from "../../../../../../model/types";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import AddIcon from '@mui/icons-material/Add';
import RepsField from "./RepsField";
import WeightField from "./WeightField";
import TimeField from "./TimeField";
import RpeField from "./RpeField";
import PercentageField from "./PercentageField";
import DeleteButton from "./DeleteButton";
import FieldSelector from "./FieldSelector";
import Stack from "@mui/material/Stack";
import EditableTypography from "../../../EditableTypography";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { ActionType, exerciseReducer } from "../../../../../../model/reducers/exerciseReducer";
import {v4 as uuidv4} from 'uuid';
var deepEqual = require('deep-equal');

type Props = {
  exercise: IdentifiableExercise<IdentifiableExerciseSet>,
  defaultExpanded?: boolean,
  onExerciseChange: (exercise: IdentifiableExercise<IdentifiableExerciseSet>) => void,
  onExerciseDelete: () => void,
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: expand ? 'rotate(0deg)' : 'rotate(-90deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    paddingTop: 10,
    paddingBottom: 10,
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
    paddingTop: 18,
    paddingBottom: 0,
  },
}));

const ExerciseListItem = (props: Props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: props.exercise.id});

  const [expanded, setExpanded] = useState(props.defaultExpanded ?? false);

  const [exercise, dispatch] = useReducer(exerciseReducer<IdentifiableExercise<IdentifiableExerciseSet>>, props.exercise);

  const prevPropsExercise = useRef(props.exercise);

  useEffect(
    () => {
      if (!deepEqual(props.exercise, prevPropsExercise.current)) {
        prevPropsExercise.current = props.exercise;
        dispatch({type: ActionType.LoadExercise, exercise: props.exercise});
        return;
      }

      if (exercise && !deepEqual(props.exercise, exercise)) {
        props.onExerciseChange(exercise);
      }
    },
    [exercise, props.onExerciseChange, props.exercise],
  );

  const handleAddSet = () => dispatch({type: ActionType.AddSet, additionalFields: {id: uuidv4()}});

  const handleSetChange = (setIndex: number, field: ExerciseField, value: any) => {
    dispatch({
      type: ActionType.EditSetFieldValue,
      index: setIndex,
      field,
      value,
    });
  };

  const handleDeleteSet = (index: number) => dispatch({type: ActionType.DeleteSet, index});

  const handleFieldCheck = (field: ExerciseField, checked: boolean) => dispatch({type: ActionType.EditField, field, include: checked});

  const [displayNotes, setDisplayNotes] = useState(props.exercise.notes);
  const handleCommitNotes = () => dispatch({type: ActionType.EditNotes, notes: displayNotes ?? null});

  const handleWeightUnitsChange = (weightUnits: WeightUnits) => dispatch({type: ActionType.EditWeightUnits, weightUnits});

  return (
    <Card
      ref={setNodeRef}
      {...attributes}
      sx={{
        transform: CSS.Translate.toString(transform),
        transition,
        p: 1,
        pr: 2,
        my: 1,
      }}
    >
      {/* Unexpanded section (i.e. name, notes, drag icon, etc.) */}
      <Box display="flex" alignItems="center" gap={1}>
        <DragIndicatorIcon
          {...listeners}
          sx={{
            cursor: "grab",
            ":active": {
              cursor: "grabbing",
            },
            color: (theme) => theme.palette.grey[500],
          }}
        />
        <Box display="flex" alignItems="center" flex="1">
          <ExpandMore
            expand={expanded}
            onClick={() => setExpanded(!expanded)}
          >
            <ExpandMoreIcon />
          </ExpandMore>
          <Stack flex="1" py={1}>
            <Typography pl={1}>
              {exercise?.name}
            </Typography>
            {props.exercise.notes && !expanded &&
              <Typography variant="body2" p={1} pb={0} sx={{ border: "1px solid transparent" }}>
                {exercise?.notes}
              </Typography>
            }
            {expanded &&
              <EditableTypography
                variant="body2"
                placeholder="Add notes"
                value={displayNotes}
                onValueChange={(value) => setDisplayNotes(value)}
                onCommit={handleCommitNotes}
              />
            }
          </Stack>
        </Box>
        <DeleteButton onDelete={props.onExerciseDelete} />
      </Box>

      {/* Table of sets */}
      <Collapse in={expanded}>
        <Box px={3} mt={1} display="flex" justifyContent="space-between">
            <Box>
              {exercise?.sets && <FieldSelector checked={exercise?.fields} onChange={handleFieldCheck} />}
            </Box>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={handleAddSet}
          >
            Add Set
          </Button>
        </Box>
        {exercise?.sets && <Box sx={{ py: 1, px: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                {exercise?.fields.has(ExerciseField.Reps) && <StyledTableCell>Reps</StyledTableCell>}
                {exercise?.fields.has(ExerciseField.Weight) &&
                  <StyledTableCell>
                    <Box display="flex" alignItems="center" gap={1}>
                    Weight
                    <ToggleButtonGroup
                      color="primary"
                      size="small"
                      value={exercise?.weightUnits}
                      exclusive
                      onChange={(_, value) => handleWeightUnitsChange(value)}
                    >
                      <ToggleButton value={WeightUnits.Kilograms}>kg</ToggleButton>
                      <ToggleButton value={WeightUnits.Pounds}>lbs</ToggleButton>
                    </ToggleButtonGroup>
                    </Box>
                  </StyledTableCell>
                }
                {exercise?.fields.has(ExerciseField.Time) && <StyledTableCell>Time</StyledTableCell>}
                {exercise?.fields.has(ExerciseField.RPE) && <StyledTableCell>RPE</StyledTableCell>}
                {exercise?.fields.has(ExerciseField.Percentage) && <StyledTableCell>Percentage</StyledTableCell>}
                <StyledTableCell>{/* Delete */}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {exercise?.sets?.map((set, index) => 
                <TableRow key={set.id}>
                  {exercise?.fields.has(ExerciseField.Reps) &&
                    <StyledTableCell>
                      <RepsField
                        reps={set.reps}
                        onRepsChange={(reps) => {
                          handleSetChange(index, ExerciseField.Reps, reps)}
                        }
                      />
                    </StyledTableCell>
                  }

                  {exercise?.fields.has(ExerciseField.Weight) &&
                    <StyledTableCell>
                      <WeightField
                        weight={set.weight}
                        weightUnits={exercise?.weightUnits}
                        onWeightChange={(weight) => handleSetChange(index, ExerciseField.Weight, weight)}
                      />
                    </StyledTableCell>
                  }

                  {exercise?.fields.has(ExerciseField.Time) &&
                    <StyledTableCell>
                      <TimeField time={set.time} onTimeChange={(time) => handleSetChange(index, ExerciseField.Time, time)} />
                    </StyledTableCell>
                  }

                  {exercise?.fields.has(ExerciseField.RPE) &&
                    <StyledTableCell>
                      <RpeField rpe={set.rpe} onRpeChange={(rpe) => handleSetChange(index, ExerciseField.RPE, rpe)} />
                    </StyledTableCell>
                  }

                  {exercise?.fields.has(ExerciseField.Percentage) &&
                    <StyledTableCell>
                      <PercentageField
                        percentage={set.percentage}
                        onPercentageChange={(percentage) => handleSetChange(index, ExerciseField.Percentage, percentage)}
                      />
                    </StyledTableCell>
                  }

                  <StyledTableCell align="right">
                    <DeleteButton onDelete={() => handleDeleteSet(index)} />
                  </StyledTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>}
      </Collapse>
    </Card>
  );
}

export default ExerciseListItem;