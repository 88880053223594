import Paper, { PaperProps } from "@mui/material/Paper"

const ContentPaper = (props: PaperProps) => {
  return (
    <Paper
      {...props}
      elevation={2}
      sx={{
        p: 2,
        ...props.sx
      }}
    >
      {props.children}
    </Paper>
  )
}

export default ContentPaper