import {
  Auth,
  AuthError,
  createUserWithEmailAndPassword as firebaseCreateUserWithEmailAndPassword,
  UserCredential,
} from 'firebase/auth';
import { useCallback, useState } from 'react';
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../config/firebase";

type AuthActionHook<M> = [
  M,
  UserCredential | undefined,
  boolean,
  AuthError | undefined
];

type EmailAndPasswordActionHook = AuthActionHook<
  (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
  ) => void
>;

export const useCreateUser = (auth: Auth): EmailAndPasswordActionHook => {
  const [error, setError] = useState<AuthError>();
  const [registeredUser, setRegisteredUser] = useState<UserCredential>();
  const [loading, setLoading] = useState<boolean>(false);

  const createUserAndDocument = useCallback(
    async (
      email: string,
      password: string,
      firstName: string,
      lastName: string
    ) => {
      setLoading(true);
      setError(undefined);

      try {
        const user = await firebaseCreateUserWithEmailAndPassword(
          auth,
          email,
          password
        );

        const docRef = doc(db, `users/${user.user.uid}`);

        await setDoc(docRef, {
          "firstName": firstName,
          "lastName": lastName,
          "role": "coach",
        });

        setRegisteredUser(user);

      } catch (error) {
        console.error((error as AuthError).message);
        setError(error as AuthError);
      } finally {
        setLoading(false);
      }
    },
    [auth]
  );

  return [createUserAndDocument, registeredUser, loading, error];
};