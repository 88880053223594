import { arrayMove } from "@dnd-kit/sortable";
import { ExerciseSet, ExerciseWithSets, Program, Workout, WorkoutMetadata } from "../types";

export enum ActionType {
  LoadProgram,
  EditName,
  EditDescription,
  AddWorkoutMetadata,
  EditWorkoutMetadata,
  DeleteWorkoutMetadata,
  MoveWorkout,
}

interface LoadProgramAction {
  type: ActionType.LoadProgram,
  program: Program,
}

interface EditNameAction {
  type: ActionType.EditName,
  name: string,
}

interface EditDescriptionAction {
  type: ActionType.EditDescription,
  description: string | null,
}

interface EditWorkoutMetadataAction {
  type: ActionType.EditWorkoutMetadata,
  workoutId: string,
  workout: Workout<ExerciseWithSets<ExerciseSet>>,
}

interface AddWorkoutMetadataAction {
  type: ActionType.AddWorkoutMetadata,
  workout: Workout<ExerciseWithSets<ExerciseSet>>,
}

interface MoveWorkoutAction {
  type: ActionType.MoveWorkout,
  fromIndex: number,
  toIndex: number,
}

interface DeleteWorkoutMetadataAction {
  type: ActionType.DeleteWorkoutMetadata,
  workoutId: string,
}

export type Action = LoadProgramAction
  | EditNameAction
  | EditDescriptionAction
  | EditWorkoutMetadataAction
  | AddWorkoutMetadataAction
  | MoveWorkoutAction
  | DeleteWorkoutMetadataAction

export const programReducer = (state: Program | undefined, action: Action): Program | undefined => {
  if (action.type === ActionType.LoadProgram) {
    return {...action.program};
  }

  if (!state) {
    return state;
  }

  switch (action.type) {
    case ActionType.EditName:
      return {
        ...state,
        name: action.name,
      };
    case ActionType.EditDescription: {
      const { description, ...program } = state;
      return {
        ...program,
        ...action.description && { description: action.description },
      };
    }
    case ActionType.EditWorkoutMetadata: {
      return editWorkoutMetadata(state, action.workoutId, action.workout);
    }
    case ActionType.AddWorkoutMetadata: {
      return addWorkoutMetadata(state, action.workout);
    }
    case ActionType.MoveWorkout: {
      return moveWorkout(state, action.fromIndex, action.toIndex);
    }
    case ActionType.DeleteWorkoutMetadata: {
      return deleteWorkoutMetadata(state, action.workoutId);
    }
    default:
      return state;
  }
}

export type ProgramReducerReturnType = ReturnType<typeof programReducer>;

const workoutMetadataFromWorkout = (workout: Workout<ExerciseWithSets<ExerciseSet>>): WorkoutMetadata => {
  const exercises = workout.baseExercises?.map((exercise) => exercise.name);
  return {
    id: workout.id,
    name: workout.name,
    ...(workout.description && { description: workout.description }),
    ...(exercises && {exercises}),
    ...(workout.started && { started: workout.started }),
    ...(workout.modified && { modified: workout.modified }),
    ...(workout.finished && { finished: workout.finished }),
  };
}

const editWorkoutMetadata = (state: Program, workoutId: string, workout: Workout<ExerciseWithSets<ExerciseSet>>): Program => {
  if (!state.workouts) {
    return state;
  }

  var workouts = state.workouts?.slice() ?? [];
  const index = workouts.findIndex((workoutMetadata) => workoutMetadata.id === workoutId);
  if (index < 0) {
    return state;
  }
  workouts[index] = workoutMetadataFromWorkout(workout);

  return {
    ...state,
    workouts,
  }
}

const addWorkoutMetadata = (state: Program, workout: Workout<ExerciseWithSets<ExerciseSet>>): Program => {
  var workouts = state.workouts?.slice() ?? [];
  workouts = [...workouts, workoutMetadataFromWorkout(workout)];
  return {
    ...state,
    workouts,
  }
}

const moveWorkout = (state: Program, fromIndex: number, toIndex: number): Program => {
  if (!state.workouts) {
    return state;
  }
  const workouts = arrayMove(state.workouts ?? [], fromIndex, toIndex);
  return {
    ...state,
    workouts,
  }
}

const deleteWorkoutMetadata = (state: Program, workoutId: string): Program => {
  if (!state.workouts) {
    return state;
  }
  var workouts = state.workouts.slice();
  const index = state.workouts.findIndex((workoutMeta) => workoutMeta.id === workoutId);
  if (index < 0) {
    return state;
  }
  workouts.splice(index, 1);
  return {
    ...state,
    workouts,
  }
}