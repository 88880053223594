import Tooltip from "@mui/material/Tooltip"
import Typography, { TypographyProps } from "@mui/material/Typography"
import ReactTimeago from "react-timeago"
import { format } from 'date-fns';

interface Props extends TypographyProps {
  timestamp: Date,
}

const Timestamp = (props: Props) => {
  return (
    <Tooltip title={format(props.timestamp, "EEE, LLL d, yyyy p")}>
      <Typography variant={props.variant ?? "body2"}>
        <ReactTimeago date={props.timestamp} minPeriod={60} />
      </Typography>
    </Tooltip>
  )
}

export default Timestamp