import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { User, updateEmail } from "firebase/auth";
import { db } from "../../config/firebase";
import { UserData } from "../types";

type UpdateUserArg = {
  user: User,
  userData: UserData,
}

export const userApi = createApi({
  reducerPath: "user",
  baseQuery: fakeBaseQuery(),
  tagTypes: ["user"],
  endpoints: (builder) => ({
    fetchCurrentUser: builder.query<UserData | undefined, User | undefined | null>({
      async queryFn(user) {
        return await currentUser(user);
      },
      providesTags: ["user"],
    }),
    updateUser: builder.mutation<UserData, UpdateUserArg>({
      async queryFn(userData) {
        return await updateUser(userData);
      },
      invalidatesTags: ["user"],
    }),
  }),
});

const currentUser = async (user: User | undefined | null): Promise<{ data: UserData | undefined } | { error: any }> => {
  if (!user) {
    return {
      data: undefined
    }
  }

  try {
    const userDoc = await getDoc(doc(db, `users/${user.uid}`));
    const data = userDoc.data();
    if (!data) {
      return {
        error: `No user document for user with id=${user.uid}`
      }
    }
    const userData: UserData = {
      uid: user.uid,
      firstName: data.firstName,
      lastName: data.lastName,
      email: user.email!,
    }
    return {
      data: userData
    }
  }
  catch (error: any) {
    console.error(error.message);
    return {
      error: error.message
    }
  }
}

const updateUser = async ({user, userData}: UpdateUserArg): Promise<{ data: UserData } | { error: any }> => {
  await new Promise(r => setTimeout(r, 2000));

  // Update email if necessary
  if (user.email !== userData.email) {
    try {
      await updateEmail(user, userData.email);
    }
    catch (error: any) {
      console.error(error.message);
      return {
        error: error.message,
      }
    }
  }

  // Update user doc
  try {
    await updateDoc(doc(db, `users/${user.uid}`), {
      "firstName": userData.firstName,
      "lastName": userData.lastName,
    });
  }
  catch (error: any) {
    console.error(error.message);
    return {
      error: error.message,
    }
  }

  return {
    data: userData,
  }
}

export const {
  useFetchCurrentUserQuery,
  useUpdateUserMutation,
} = userApi;