import { useCallback, useEffect, useState } from "react";
import { Program } from "../../model/types";
import { useSendProgramMutation } from "../../model/slices/programsSlice";

export const useSendProgram = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean | undefined>(undefined);
  const [sendProgramMutation, { error }] = useSendProgramMutation();
  const [errorString, setErrorString] = useState<string>();

  const sendProgram = useCallback(
    async (programId: string): Promise<Program | undefined> => {
      setIsSuccess(undefined);
      setIsLoading(true);
      const result = await sendProgramMutation(programId);
      setIsLoading(false);
      if ('data' in result) {
        setIsSuccess(true);
        return result.data;
      }
      else {
        setIsSuccess(false);
        return undefined;
      }
    },
    [sendProgramMutation],
  );

  useEffect(
    () => {
      if (error) {
        console.error(error);
        if (typeof error === 'string') {
          setErrorString(error as string);
        }
        else {
          setErrorString("An error occurred");
        }
      }
      else {
        setErrorString(undefined);
      }
    },
    [error],
  );

  return {
    sendProgram,
    isLoading,
    isSuccess,
    error: errorString,
  };
}