import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../config/firebase";
import { ExerciseSet, ExerciseWithSets, Program, Workout } from "../types";

export type GetClientProgramsArg = {
  coachId: string | undefined,
  clientId: string | undefined,
  draft: boolean,
}
type GetClientProgramsReturnType = { data: Program[] } | { error: any };
export const getClientPrograms = async (arg: GetClientProgramsArg): Promise<GetClientProgramsReturnType> => {
  if (!arg.clientId || !arg.coachId) {
    return {
      error: "Invalid coach/client",
    }
  }

  await new Promise(r => setTimeout(r, 1000));

  try {
    const programs: Program[] = [];
    const collectionRef = collection(db, 'programs');
    var programsQuery = query(collectionRef, where('coachId', '==', arg.coachId));
    programsQuery = query(programsQuery, where('clientId', '==', arg.clientId));
    if (arg.draft) {
      programsQuery = query(programsQuery, where('sent', '==', null));
    }
    else {
      programsQuery = query(programsQuery, where('sent', '!=', null));
      programsQuery = query(programsQuery, orderBy('sent', 'desc'));
    }
    programsQuery = query(programsQuery, orderBy('modified', 'desc'));
    const snapshot = await getDocs(programsQuery);
    for (const doc of snapshot.docs) {
      const data = doc.data();
      programs.push({
        id: doc.id,
        name: data.name,
        description: data.description,
        ...(data.created && {created: new Date(Date.parse(data.created))}),
        ...(data.sent && {sent: new Date(Date.parse(data.sent))}),
        ...(data.started && {started: new Date(Date.parse(data.started))}),
        ...(data.modified && {modified: new Date(Date.parse(data.modified))}),
        ...(data.finished && {finished: new Date(Date.parse(data.finished))}),
        workouts: data.workouts,
      });
    }
    return {
      data: programs,
    }
  }
  catch (error: any) {
    console.error(error.message);
    return {
      error: error.message,
    }
  }
}

// Converts Program type into database ready format (strips the ID, formats dates as strings (or sets to null)).
export const programToRawData = (program: Program, coachId?: string) => {
  return {
    name: program.name,
    ...program.description && {description: program.description},
    ...program.workouts && {workouts: program.workouts},
    coachId: coachId ?? null,
    clientId: program.clientId ?? null,
    maxWorkoutStarted: program.maxWorkoutStarted?.toISOString() ?? null,
    created: program.created?.toISOString() ?? null,
    sent: program.sent?.toISOString() ?? null,
    started: program.started?.toISOString() ?? null,
    modified: program.modified?.toISOString() ?? null,
    finished: program.finished?.toISOString() ?? null,
  }
};

export const workoutToRawData = (workout: Workout<ExerciseWithSets<ExerciseSet>>, coachId?: string) => {
  const baseExercises = workout.baseExercises?.map((e) => exerciseToRawData(e));
  const performedExercises = workout.performedExercises?.map((e) => exerciseToRawData(e));

  return {
    name: workout.name,
    ...workout.description && {description: workout.description},
    ...workout.program && {program: workout.program},
    ...baseExercises && {baseExercises},
    ...performedExercises && {performedExercises},
    coachId: coachId ?? null,
    clientId: workout.clientId ?? null,
    started: workout.started?.toISOString() ?? null,
    modified: workout.modified?.toISOString() ?? null,
    finished: workout.finished?.toISOString() ?? null,
  }
}

export const exerciseToRawData = (exercise: ExerciseWithSets<ExerciseSet>) => {
  return {
    name: exercise.name,
    fields: Array.from(exercise.fields).map((f) => f as string),
    ...exercise.notes && {notes: exercise.notes},
    ...exercise.sets && {sets: exercise.sets},
    ...exercise.weightUnits && {weightUnits: exercise.weightUnits as string},
  }
}

export const exercisesWithSetsToAny = (exercisesWithSets: ExerciseWithSets<ExerciseSet>[]): any[] => {
  const exercises: any[] = [];
  for (const exercise of exercisesWithSets) {
    const sets: any[] = [];

    if (exercise.sets) {
      for (const set of exercise.sets) {
        sets.push({
          ...(set.reps !== undefined && { reps: set.reps }),
          ...(set.weight !== undefined && { weight: set.weight }),
          ...(set.rpe !== undefined && { rpe: set.rpe }),
          ...(set.percentage !== undefined && { percentage: set.percentage }),
          ...(set.time !== undefined && { time: set.time }),
        });
      }
    }

    exercises.push({
      name: exercise.name,
      ...(exercise.notes && { notes: exercise.notes }),
      ...(sets.length > 0 && { sets: sets }),
      ...(exercise.weightUnits && { weightUnits: exercise.weightUnits }),
      fields: Array.from(exercise.fields),
    });
  }
  return exercises;
}
