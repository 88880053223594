import { arrayMove } from "@dnd-kit/sortable";
import { ExerciseSet, ExerciseWithSets, ExerciseWithSetsAlias, Workout } from "../types";
const _ = require('lodash');

export enum ActionType {
  LoadWorkout,
  EditName,
  EditDescription,
  AddExercise,
  MoveExercise,
  EditExercise,
  DeleteExercise,
};

interface LoadWorkoutAction<
  ExerciseType extends ExerciseWithSets<SetType>,
  SetType extends ExerciseSet = ExerciseWithSetsAlias<ExerciseType>
> {
  type: ActionType.LoadWorkout,
  workout: Workout<ExerciseType, SetType>,
};

interface EditNameAction {
  type: ActionType.EditName,
  name: string,
};

interface EditDescriptionAction {
  type: ActionType.EditDescription,
  description: string | null,
};

interface AddExerciseAction<
  ExerciseType extends ExerciseWithSets<SetType>,
  SetType extends ExerciseSet = ExerciseWithSetsAlias<ExerciseType>
> {
  type: ActionType.AddExercise,
  exercise: ExerciseType,
};

interface MoveExerciseAction {
  type: ActionType.MoveExercise,
  fromIndex: number,
  toIndex: number,
};

interface EditExerciseAction<
  ExerciseType extends ExerciseWithSets<SetType>,
  SetType extends ExerciseSet = ExerciseWithSetsAlias<ExerciseType>
> {
  type: ActionType.EditExercise,
  index: number,
  exercise: ExerciseType,
};

interface DeleteExerciseAction {
  type: ActionType.DeleteExercise,
  index: number,
};

export type Action<
  ExerciseType extends ExerciseWithSets<SetType>,
  SetType extends ExerciseSet = ExerciseWithSetsAlias<ExerciseType>
> = LoadWorkoutAction<ExerciseType, SetType>
  | EditNameAction
  | EditDescriptionAction
  | AddExerciseAction<ExerciseType, SetType>
  | MoveExerciseAction
  | EditExerciseAction<ExerciseType, SetType>
  | DeleteExerciseAction

export const workoutReducer = <
  ExerciseType extends ExerciseWithSets<SetType>,
  SetType extends ExerciseSet = ExerciseWithSetsAlias<ExerciseType>
>(state: Workout<ExerciseType, SetType> | undefined, action: Action<ExerciseType, SetType>): Workout<ExerciseType, SetType> | undefined => {
  if (action.type === ActionType.LoadWorkout) {
    return {...action.workout};
  }

  state = _.cloneDeep(state);

  if (!state) {
    return state;
  }

  switch (action.type) {
    case ActionType.EditName:
      return {
        ...state,
        name: action.name,
      }
    case ActionType.EditDescription: {
      const { description, ...workout } = state;
      return {
        ...workout,
        ...action.description && { description: action.description },
      };
    }
    case ActionType.AddExercise:
      return addExercise(state, action.exercise);
    case ActionType.MoveExercise:
      return moveExercise(state, action.fromIndex, action.toIndex);
    case ActionType.EditExercise:
      return editExercise(state, action.index, action.exercise);
    case ActionType.DeleteExercise:
      return deleteExercise(state, action.index);
    default:
      return state;
  }
}

const addExercise = <
  ExerciseType extends ExerciseWithSets<SetType>,
  SetType extends ExerciseSet = ExerciseWithSetsAlias<ExerciseType>
>(state: Workout<ExerciseType, SetType>, exercise: ExerciseType): Workout<ExerciseType, SetType> => {
  return {
    ...state,
    baseExercises: [
      ...state.baseExercises ?? [],
      exercise,
    ],
  }
}

const moveExercise = <
  ExerciseType extends ExerciseWithSets<SetType>,
  SetType extends ExerciseSet = ExerciseWithSetsAlias<ExerciseType>
>(state: Workout<ExerciseType, SetType>, fromIndex: number, toIndex: number): Workout<ExerciseType, SetType> => {
  if (!state.baseExercises) {
    return state;
  }
  const baseExercises = arrayMove(state.baseExercises ?? [], fromIndex, toIndex);
  return {
    ...state,
    baseExercises,
  }
}

const editExercise = <
  ExerciseType extends ExerciseWithSets<SetType>,
  SetType extends ExerciseSet = ExerciseWithSetsAlias<ExerciseType>
>(state: Workout<ExerciseType, SetType>, index: number, exercise: ExerciseType): Workout<ExerciseType, SetType> => {
  if (!state.baseExercises) {
    return state;
  }
  const baseExercises = state.baseExercises.slice();
  if (index < 0) {
    return state;
  }
  baseExercises[index] = exercise;
  return({
    ...state,
    baseExercises,
  });
}

const deleteExercise = <
  ExerciseType extends ExerciseWithSets<SetType>,
  SetType extends ExerciseSet = ExerciseWithSetsAlias<ExerciseType>
>(state: Workout<ExerciseType, SetType>, index: number): Workout<ExerciseType, SetType> => {
  if (!state.baseExercises) {
    return state;
  }
  const baseExercises = state.baseExercises.slice();
  if (index < 0) {
    return state;
  }
  baseExercises.splice(index, 1);
  return({
    ...state,
    baseExercises,
  });
}