import { useCallback, useEffect, useState } from "react";
import { Program } from "../../model/types";
import { useCreateBlankClientProgramMutation } from "../../model/slices/programsSlice";

export const useCreateBlankClientProgram = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean | undefined>(undefined);
  const [createBlankClientProgramMutation, { error }] = useCreateBlankClientProgramMutation();
  const [errorString, setErrorString] = useState<string>();

  const createBlankClientProgram = useCallback(
    async ({clientId, name, description}: {clientId: string, name: string, description?: string}): Promise<Program | undefined> => {
      setIsSuccess(undefined);
      setIsLoading(true);
      const result = await createBlankClientProgramMutation({clientId, name, description});
      setIsLoading(false);
      if ("data" in result) {
        setIsSuccess(true);
        return result.data;
      }
      else {
        setIsSuccess(false);
        return undefined;
      }
    },
    [createBlankClientProgramMutation]
  );

  useEffect(
    () => {
      if (error) {
        setErrorString(error as string);
      }
      else {
        setErrorString(undefined);
      }
    },
    [error]
  )

  return {
    createBlankClientProgram,
    isLoading,
    isSuccess,
    error: errorString,
  };
}