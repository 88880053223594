import { configureStore } from "@reduxjs/toolkit";
import { userApi } from "./slices/userSlice";
import { clientsApi } from "./slices/clientsSlice";
import { templatesApi } from "./slices/templatesSlice";
import { programsApi } from "./slices/programsSlice";

export const store = configureStore({
    reducer: {
        // key here must match reducerPath
        [userApi.reducerPath]: userApi.reducer,
        [clientsApi.reducerPath]: clientsApi.reducer,
        [templatesApi.reducerPath]: templatesApi.reducer,
        [programsApi.reducerPath]: programsApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }).concat(
        userApi.middleware,
        clientsApi.middleware,
        templatesApi.middleware,
        programsApi.middleware,
    ),
});

export type RootDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>