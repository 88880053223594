import { ReactNode } from 'react'
import FlexContainer from "./FlexContainer"
import HomeNavbar from "./HomeNavbar"
import Footer from "./Footer"

const Page = ({children, showDashboardButton = true}: {children: ReactNode, showDashboardButton?: boolean}) => {
  return (
    <FlexContainer>
      <HomeNavbar showDashboardButton={showDashboardButton} />
      {children}
      <Footer />
    </FlexContainer>
  )
}

export default Page