import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

type Props = {
  time: number | null | undefined,
  onTimeChange: (seconds: number | null) => void,
}

const secondsToDate = (totalSeconds: number) => {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return new Date(`1994-03-15 00:${minutes}:${seconds}`);
}

const TimeField = (props: Props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopTimePicker
        defaultValue={
          props.time !== null && props.time !== undefined
            ? secondsToDate(props.time)
            : undefined
        }
        views={["minutes", "seconds"]}
        timeSteps={{ minutes: 1, seconds: 5 }}
        onChange={(value: any) => {
          const seconds = Number(value !== null)
            ? value.getMinutes() * 60 + value.getSeconds()
            : null;
          props.onTimeChange(seconds);
        }}
        slotProps={{ textField: { size: 'small' } }}
      />
    </LocalizationProvider>
  )
}

export default TimeField