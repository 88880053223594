import Box from "@mui/material/Box"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import useMediaQuery from "@mui/material/useMediaQuery"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import TextField from "@mui/material/TextField"
import { FieldError, useForm } from "react-hook-form"
import LoadingButton from "@mui/lab/LoadingButton"
import Button from "@mui/material/Button"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { theme } from "../../../../../../theme"
import { SafeRoutePath } from "../../../../../../components/SafeRouterLink"
import { useCreateBlankClientProgram } from "../../../../../../hooks/programs/createBlankProgramHook"

type Props = {
  clientId: string,
  open: boolean,
  onClose: (() => void) | undefined
}

type FormData = {
  name: string,
  description?: string,
}

const BlankProgramDialog = (props: Props) => {
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>();
  
  const {
    createBlankClientProgram,
    isLoading: createLoading,
    error: createError
  } = useCreateBlankClientProgram();

  const [newProgramId, setNewProgramId] = useState<string>();

  const onSubmit = async (data: FormData) => {
    const program = await createBlankClientProgram({
      clientId: props.clientId,
      name: data.name,
      description: data.description,
    });
    setNewProgramId(program?.id);
  }

  const nameHelperText = (error: FieldError | undefined) => {
    if (!error) {
      return "";
    }
    switch (error.type) {
      case "required":
        return "Program name is required";
      case "maxLength":
        return "Program name exceeds maximum length";
      default:
        return "Program name is invalid";
    }
  }

  const descriptionHelperText = (error: FieldError | undefined) => {
    if (!error) {
      return "";
    }
    switch (error.type) {
      case "maxLength":
        return "Description exceeds maximum length";
      default:
        return "Description is invalid";
    }
  }

  const navigate = useNavigate();
  useEffect(
    () => {
      if (newProgramId) {
        props.onClose?.();
        navigate(SafeRoutePath.ResolvedClientProgram(props.clientId, newProgramId).path)
      }
    },
    [newProgramId, props, navigate]
  );

  return (
    <Dialog
      fullWidth={matches}
      open={props.open}
      onClose={props.onClose}
      maxWidth="md"
      disableRestoreFocus
    >
      <DialogTitle>Create Blank Program</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          {!!createError &&
            <Typography mb={2} variant="body2" color="error">
              {createError}
            </Typography>
          }

          <Typography mb={1}>
            Start creating a new program by setting a name and description. Click create to save the program and begin adding workouts.
          </Typography>

          <Typography mb={1}>
            The program will remain in draft until you decide to send it.
          </Typography>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="normal"
                fullWidth
                id="name"
                label="Program Name"
                {...register("name", {
                  required: true,
                  maxLength: 200,
                })}
                error={!!errors.name}
                helperText={nameHelperText(errors.name)}
                disabled={createLoading}
              />

              <TextField
                multiline
                rows={3}
                margin="normal"
                fullWidth
                id="description"
                label="Description (optional)"
                {...register("description", {
                  maxLength: 300,
                })}
                error={!!errors.description}
                helperText={descriptionHelperText(errors.description)}
                disabled={createLoading}
              />
            </Grid>

            <Grid item xs={12} mt={1}>
              <Box display="flex" gap={1} justifyContent="flex-end">
                <Button
                  disabled={createLoading}
                  onClick={props.onClose}
                >
                  Cancel
                </Button>
                <LoadingButton
                  loading={createLoading}
                  variant="contained"
                  type="submit"
                >
                  Create
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>

        </Box>
      </DialogContent>
    </Dialog >
  )
}

export default BlankProgramDialog