import Paper from "@mui/material/Paper"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"

import laptopIcon from "./images/laptopIcon.png"
import phoneIcon from "./images/phoneIcon.png"
import reviewIcon from "./images/reviewIcon.png"

const cardContents = [
  {
    image: laptopIcon,
    title: "Program Builder",
    contents: [
      "Coaches craft personalized workout plans tailored to individual needs using our intuitive Program Builder.",
      "They choose exercises from our extensive library or add their own to create customized programs.",
      "Coaches manage all their clients in one place and send programs directly to them with ease, ensuring a smooth coaching experience.",
    ],
  },
  {
    image: phoneIcon,
    title: "Spotter App",
    contents: [
      "Clients access their personalized workout plans instantly on the Spotter app for Android or iOS.",
      "They begin their workouts anytime, leveraging a comprehensive range of tools to track progress, log notes, attach videos to sets, and seamlessly communicate with their coach.",
      "Clients achieve their fitness goals through an all-in-one, user-friendly workout experience."
    ],
  },
  {
    image: reviewIcon,
    title: "Progress Review & Refinement",
    contents: [
      "Coaches effortlessly review detailed workout data, including set notes and video footage for precise form checks.",
      "This insightful overview empowers effortless adjustments in future programs, ensuring a tailored coaching experience that propels client progress and success toward their fitness goals.",
      "Continue your coaching journey, serving unlimited clients — all at no direct cost as a coach.",
    ],
  },
];

const InfoCard = ({image, title, contents}: {image: string, title: string, contents: string[]}) => {
  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Box display="flex" justifyContent="center" pt={2}>
          <img src={image} height="75" alt={title} />
        </Box>
        <Typography pt={1} variant="h5" component="h2">
          {title}
        </Typography>
        <Divider />
        {
          contents.map((content, index) => {
            return <Typography key={index}>{content}</Typography>
          })
        }
      </Stack>
    </Paper>
  )
}

const InfoCards = () => {
  return (
    <Container>
      <Typography variant="h4" my={4}>
        How It Works
      </Typography>
      <Grid container spacing={4}>
        {cardContents.map((cardContent, index) => {
          return <Grid item md={4} key={index.toString()}>
            <InfoCard image={cardContent.image} title={cardContent.title} contents={cardContent.contents} />
          </Grid>
        })}
      </Grid>
    </Container>
  )
}

export default InfoCards