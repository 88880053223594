import React from 'react'
import ScrollToTopOnMount from "../components/ScrollToTopOnMount"
import Page from "../components/Page"
import Container from "@mui/material/Container"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"

const Privacy = () => {
  return (
    <React.Fragment>
      <ScrollToTopOnMount />
      <Page>
        <Container>
          <Paper sx={{
            p: 3,
            mx: { md: 3 },
            my: 3,
          }}>
            <Typography variant="h4" mb={3}>
              Privacy Policy
            </Typography>

            <Typography variant="body2" pb={3}>
              Last updated November 20, 2023
            </Typography>

            <Typography py={1}>This privacy notice for Spotter Technologies ("we," "us," or "our"), describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services"), such as when you:
</Typography>
            <ul>
              <li>Visit our website at spotter.app, or any website of ours that links to this privacy notice</li>
              <li>Download and use our mobile application (Spotter), or any other application of ours that links to this privacy notice</li>
              <li>Engage with us in other related ways, including any sales, marketing, or events
</li>
            </ul>

            <Typography py={1}>Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at legal@spotter.app.</Typography>

            <Typography variant="h5" my={2}>
              Summary of Key Points
            </Typography>

            <Typography py={1} fontWeight="bold">This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for.
</Typography>
            <Typography py={1}><b>What personal information do we process?</b> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about personal information you disclose to us.</Typography>
            <Typography py={1}><b>Do we process any sensitive personal information?</b> We may process sensitive personal information when necessary with your consent or as otherwise permitted by applicable law. Learn more about sensitive information we process.</Typography>
            <Typography py={1}><b>Do we receive any information from third parties?</b> We do not receive any information from third parties.</Typography>
            <Typography py={1}><b>How do we process your information?</b> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about how we process your information.</Typography>
            <Typography py={1}><b>In what situations and with which parties do we share personal information?</b> We may share information in specific situations and with specific third parties. Learn more about when and with whom we share your personal information.</Typography>
            <Typography py={1}><b>How do we keep your information safe?</b> We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about how we keep your information safe.</Typography>
            <Typography py={1}><b>What are your rights?</b> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about your privacy rights.</Typography>
            <Typography py={1}><b>How do you exercise your rights?</b> The easiest way to exercise your rights is by submitting a data subject access request, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</Typography>
            <Typography py={1}>Want to learn more about what we do with any information we collect? Review the privacy notice in full.</Typography>

            <Typography variant="h5" my={2}>Table Of Contents</Typography>

            <ol>
              <li><Typography py={0.5}>What Information Do We Collect?</Typography></li>
              <li><Typography py={0.5}>How Do We Process Your Information?</Typography></li>
              <li><Typography py={0.5}>What Legal Bases Do We Rely On To Process Your Personal Information?</Typography></li>
              <li><Typography py={0.5}>When And With Whom Do We Share Your Personal Information?</Typography></li>
              <li><Typography py={0.5}>How Long Do We Keep Your Information?</Typography></li>
              <li><Typography py={0.5}>How Do We Keep Your Information Safe?</Typography></li>
              <li><Typography py={0.5}>Do We Collect Information From Minors?</Typography></li>
              <li><Typography py={0.5}>What Are Your Privacy Rights?</Typography></li>
              <li><Typography py={0.5}>Controls for Do-not-track Features</Typography></li>
              <li><Typography py={0.5}>Do We Make Updates To This Notice?</Typography></li>
              <li><Typography py={0.5}>How Can You Contact Us About This Notice?</Typography></li>
              <li><Typography py={0.5}>How Can You Review, Update, Or Delete The Data We Collect From You?</Typography></li>
            </ol>

            <Typography variant="h5" my={2}>1. What Information Do We Collect?</Typography>
            <Typography variant="h6" py={1} fontWeight="bold">Personal information you disclose to us</Typography>
            <Typography py={1}>In Short: We collect personal information that you provide to us.</Typography>
            <Typography py={1}>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</Typography>
            <Typography py={1}>Personal Information Provided by You. The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</Typography>
            <ul>
              <li>names</li>
              <li>phone numbers</li>
              <li>email addresses</li>
              <li>job titles</li>
              <li>usernames</li>
              <li>passwords</li>
              <li>contact preferences</li>
              <li>contact or authentication data</li>
              <li>billing addresses</li>
              <li>debit/credit card numbers</li>
            </ul>
            <Typography py={1}>Sensitive Information. When necessary, with your consent or as otherwise permitted by applicable law, we process the following categories of sensitive information:</Typography>
            <ul>
              <li>health data</li>
            </ul>
            <Typography py={1}>Payment Data. We may collect data necessary to process your payment if you make purchases, such as your payment instrument number, and the security code associated with your payment instrument.</Typography>
            <Typography py={1}>Application Data. If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:</Typography>
            <ul>
              <li>Geolocation Information. We may request access or permission to track location-based information from your mobile device, either continuously or while you are using our mobile application(s), to provide certain location-based services. If you wish to change our access or permissions, you may do so in your device's settings.</li>
              <li>Mobile Device Access. We may request access or permission to certain features from your mobile device, including your mobile device's camera, bluetooth, microphone, social media accounts, and other features. If you wish to change our access or permissions, you may do so in your device's settings.</li>
              <li>Push Notifications. We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device's settings.</li>
            </ul>
            <Typography py={1}>This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.</Typography>
            <Typography py={1}>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</Typography>
            
            <Typography variant="h6" py={1} fontWeight="bold">Information automatically collected</Typography>
            <Typography py={1}>In Short: Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.</Typography>
            <Typography py={1}>We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</Typography>
            <Typography py={1}>The information we collect includes:</Typography>
            <ul>
              <li>Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called "crash dumps"), and hardware settings).</li>
              <li>Device Data. We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.</li>
              <li>Location Data. We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.</li>
            </ul>

            <Typography variant="h5" my={2}>2. How Do We Process Your Information?</Typography>
            <Typography py={1}>In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</Typography>
            <Typography py={1}>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</Typography>
            <ul>
              <li>To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log in to your account, as well as keep your account in working order.</li>
              <li>To deliver and facilitate delivery of services to the user. We may process your information to provide you with the requested service.</li>
              <li>To enable user-to-user communications. We may process your information if you choose to use any of our offerings that allow for communication with another user.</li>
              <li>To send you marketing and promotional communications. We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time. For more information, see "What Are Your Rights?" below.</li>
              <li>To save or protect an individual's vital interest. We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.</li>
            </ul>

            <Typography variant="h5" my={2}>3. What Legal Bases Do We Rely On To Process Your Personal Information?</Typography>
            <Typography py={1}>In Short: We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.</Typography>
            <Typography py={1} fontWeight="bold" fontStyle="italic">If you are located in the EU or UK, this section applies to you.</Typography>
            <Typography py={1}>The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:</Typography>
            <ul>
              <li>Consent. We may process your information if you have given us permission (i.e., consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Learn more about withdrawing your consent.</li>
              <li>Performance of a Contract. We may process your personal information when we believe it is necessary to fulfill our contractual obligations to you, including providing our Services or at your request prior to entering into a contract with you.</li>
              <li>
                Legitimate Interests. We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to:
                <ul>
                  <li>Send users information about special offers and discounts on our products and services</li>
                </ul>
              </li>
              <li>Legal Obligations. We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.</li>
              <li>Vital Interests. We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.</li>
            </ul>

            <Typography py={1} fontWeight="bold" fontStyle="italic">If you are located in Canada, this section applies to you.</Typography>
            <Typography py={1}>We may process your information if you have given us specific permission (i.e., express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e., implied consent). You can withdraw your consent at any time.</Typography>
            <Typography py={1}>In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:</Typography>
            <ul>
              <li>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</li>
              <li>For investigations and fraud detection and prevention</li>
              <li>For business transactions provided certain conditions are met</li>
              <li>If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim</li>
              <li>For identifying injured, ill, or deceased persons and communicating with next of kin</li>
              <li>If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse</li>
              <li>If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach </li>of an agreement or a contravention of the laws of Canada or a province
              <li>If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records</li>
              <li>If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced</li>
              <li>If the collection is solely for journalistic, artistic, or literary purposes</li>
              <li>If the information is publicly available and is specified by the regulations</li>
            </ul>

            <Typography variant="h5" my={2}>4. When And With Whom Do We Share Your Personal Information?</Typography>
            <Typography py={1}>In Short: We may share information in specific situations described in this section and/or with the following third parties.</Typography>
            <Typography py={1}>We may need to share your personal information in the following situations:</Typography>
            <ul>
              <li>Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
              <li>Other Users. When you share personal information (for example, by posting comments, contributions, or other content to the Services) or otherwise interact with public areas of the Services, such personal information may be viewed by all users and may be publicly made available outside the Services in perpetuity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our Services, and view your profile.</li>
            </ul>

            <Typography variant="h5" my={2}>5. How Long Do We Keep Your Information?</Typography>
            <Typography py={1}>In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</Typography>
            <Typography py={1}>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.</Typography>
            <Typography py={1}>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</Typography>

            <Typography variant="h5" my={2}>6. How Do We Keep Your Information Safe?</Typography>
            <Typography py={1}>In Short: We aim to protect your personal information through a system of organizational and technical security measures.</Typography>
            <Typography py={1}>We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</Typography>


            <Typography variant="h5" my={2}>7. Do We Collect Information From Minors?</Typography>
            <Typography py={1}>In Short: We do not knowingly collect data from or market to children under 18 years of age.</Typography>
            <Typography py={1}>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at legal@spotter.app.</Typography>


            <Typography variant="h5" my={2}>8. What Are Your Privacy Rights?</Typography>
            <Typography py={1}>In Short: In some regions, such as the European Economic Area (EEA), United Kingdom (UK), Switzerland, and Canada, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.</Typography>
            <Typography py={1}>In some regions (like the EEA, UK, Switzerland, and Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; (iv) if applicable, to data portability; and (v) not to be subject to automated decision- making. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section "How Can You Contact Us About This Notice?" below.</Typography>
            <Typography py={1}>We will consider and act upon any request in accordance with applicable data protection laws.</Typography>
            <Typography py={1}>If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your Member State data protection authority or UK data protection authority.</Typography>
            <Typography py={1}>If you are located in Switzerland, you may contact the Federal Data Protection and Information Commissioner.</Typography>
            <Typography py={1}>Withdrawing your consent: If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.</Typography>
            <Typography py={1}>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</Typography>
            <Typography py={1}>Opting out of marketing and promotional communications: You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, or by contacting us using the details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below. You will then be removed from the marketing lists. However, we may still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.</Typography>

            <Typography variant="h6" py={1} fontWeight="bold">Account Information</Typography>

            <Typography py={1}>If you would at any time like to review or change the information in your account or terminate your account, you can:</Typography>
            <ul>
              <li>Log in to your account settings and update your user account.</li>
            </ul>
            <Typography py={1}>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</Typography>
            <Typography py={1}>If you have questions or comments about your privacy rights, you may email us at legal@spotter.app.</Typography>

            <Typography variant="h5" my={2}>9. Controls for Do-not-track Features</Typography>
            <Typography py={1}>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</Typography>

            <Typography variant="h5" my={2}>11. Do We Make Updates To This Notice?</Typography>
            <Typography py={1}>In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.</Typography>
            <Typography py={1}>We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</Typography>


            <Typography variant="h5" my={2}>12. How Can You Contact Us About This Notice?</Typography>
            <Typography py={1}>If you have questions or comments about this notice, you may email us at legal@spotter.app</Typography>


            <Typography variant="h5" my={2}>13. How Can You Review, Update, Or Delete The Data We Collect From You?</Typography>
            <Typography py={1}>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please fill out and submit a data subject access request.</Typography>
          </Paper>
        </Container>
      </Page>
    </React.Fragment>
  )
}

export default Privacy