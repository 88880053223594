import Typography from "@mui/material/Typography"

const Copyright = () => {
  return (
    <Typography variant="body2" sx={{
      mb: { xs: 1 }
    }}>Copyright &copy; 2023 Spotter Technologies LTD</Typography>
  )
}

export default Copyright