import Box from "@mui/material/Box"
import { theme } from "../../../theme"
import { ReactNode } from "react"

type Props = {
  p?: number,
  children: ReactNode,
}

const Content = (props: Props) => {
  return (
    <Box component="main" sx={{ flex: 1, p: props.p ?? 4, bgcolor: theme.palette.paper }}>
      {props.children}
    </Box>
  )
}

export default Content